const MAJOR_NAV_LINKS = [
  {
    label: 'Agent Resources',
    requires: {},
    blocks: {},
    overrides: {},
    children: [
      {
        url: '/tools',
        label: 'Agent Tools',
        requires: {},
        blocks: {
          usertypes: ['limited-to-contracting-signature-agent'],
        },
        overrides: {
          usertypes: ['system-admin', 'internal-admin'],
        },
      },
      {
        label: 'Agent Website',
        icon: 'globe-americas',
        children: [
          {
            url: `/:userId`,
            label: 'My Website',
            requires: {
              usertypes: ['agent', 'system-admin', 'internal-admin'],
            },
            blocks: {
              usertypes: [
                'affiliate-group',
                'associate-group',
                'limited-to-contracting-signature-agent',
              ],
            },
          },
          {
            url: '/website-info',
            label: 'Website Info',
            requires: {
              usertypes: ['agent', 'system-admin'],
            },
            blocks: {
              usertypes: [
                'affiliate-group',
                'associate-group',
                'limited-to-contracting-signature-agent',
              ],
            },
          },
          {
            url: '/menu-links',
            label: 'Menu & Links',
            requires: {
              usertypes: ['agent', 'system-admin'],
            },
            blocks: {
              usertypes: [
                'affiliate-group',
                'associate-group',
                'limited-to-contracting-signature-agent',
              ],
            },
          },
          {
            url: '/maintain-products',
            label: 'Product Links',
            requires: {
              usertypes: ['agent', 'system-admin'],
            },
            blocks: {
              usertypes: [
                'affiliate-group',
                'associate-group',
                'limited-to-contracting-signature-agent',
              ],
            },
          },
          {
            url: '/website-contact-requests',
            label: 'Contact Requests',
            requires: {
              usertypes: ['agent', 'system-admin'],
            },
            blocks: {
              usertypes: [
                'affiliate-group',
                'associate-group',
                'limited-to-contracting-signature-agent',
              ],
            },
          },
          {
            url: '/website-quote-requests',
            label: 'Quote Requests',
            requires: {
              usertypes: ['agent', 'system-admin'],
            },
            blocks: {
              usertypes: [
                'affiliate-group',
                'associate-group',
                'limited-to-contracting-signature-agent',
              ],
            },
          },
        ],
      },
      {
        url: '/division-messages',
        label: 'Division Messages',
      },
      {
        url: '/eo-policy',
        label: 'E&O Policy',
        requires: {},
      },
      {
        url: '/email-signature',
        label: 'E-mail Signature',
        requires: {},
        blocks: {
          usertypes: ['affiliate-group', 'associate-group'],
        },
      },
      {
        url: `/kpi-goals`,
        label: 'KPI Goals',
        requires: {
          profile: ['has_onboarded'],
        },
        blocks: {
          usertypes: [
            'affiliate-group',
            'associate-group',
            'limited-to-contracting-signature-agent',
          ],
        },
      },
      {
        url: '/agent-resources',
        label: 'Library',
        requires: {},
        blocks: {
          usertypes: ['limited-to-contracting-signature-agent'],
        },
      },
      {
        label: 'My Account',
        icon: 'user',
        children: [
          {
            url: '/account-profile',
            urlRegex: /\/account-profile/g,
            label: 'Profile',
            requires: {
              usertypes: ['agent', 'non-agent'],
            },
          },
          {
            url: '/account-password',
            urlRegex: '/account-password',
            label: 'Password',
            requires: {
              usertypes: ['agent', 'non-agent'],
            },
          },
          {
            url: '/account-billinginfo',
            urlRegex: '/account-billinginfo',
            label: 'Billing & Benefits',
            requires: {
              usertypes: ['agent', 'non-agent'],
            },
          },
          {
            url: '/account-terms',
            label: 'Terms',
            requires: {
              usertypes: ['agent', 'non-agent'],
            },
          },
          {
            url: '/account-alerts',
            label: 'Alerts',
            requires: {
              usertypes: ['agent', 'non-agent'],
            },
          },
          {
            url: '/beast-mode-setup',
            label: 'Beast Mode 10x CRM',
            requires: {
              env: ['local'],
              usertypes: ['system-admin'],
            },
          },
          {
            url: '/accounts/history',
            label: 'User History',
            requires: {
              usertypes: ['system-admin', 'agency-owner', 'internal-admin'],
            },
            overrides: {
              usertypes: ['system-admin', 'internal-admin'],
            },
          },
        ],
      },
      {
        label: 'My Wallet',
        url: '/account-ledger',
        requires: {
          profile: ['has_onboarded'],
        },
        blocks: {
          usertypes: [
            'affiliate-group',
            'associate-group',
            'limited-to-contracting-signature-agent',
          ],
        },
        overrides: {
          usertypes: [
            'system-admin',
            'internal-admin',
            'internal-staff',
            'agency-owner',
            'divisional-leader',
            'regional-leader',
            'district-leader',
          ],
        },
      },
      {
        url: '/signature-agent-benefits',
        label: 'Signature Agent Benefits',
      },
      {
        url: '/state-licenses',
        label: 'State Licenses',
        requires: {},
      },
      {
        url: 'https://10x.bmagencybuilders.com/',
        label: 'Beast Mode 10x CRM Login',
        requires: {},
      },
    ],
  },
  {
    label: 'Submit Sales',
    url: '/submit-sales',
    requires: {},
    blocks: {},
  },
  {
    label: 'Leader Boards',
    url: '/leaderboards',
    requires: {},
    blocks: {},
  },
  {
    label: 'REFERRAL PROGRAM',
    url: '/referral-program',
    requires: {},
    blocks: {},
  },
  {
    label: 'Get Leads',
    requires: {},
    blocks: {
      usertypes: ['affiliate-group', 'limited-to-contracting-signature-agent'],
    },
    overrides: {
      usertypes: ['system-admin', 'internal-admin'],
    },
    children: [
      {
        label: 'Lead Credit Programs',
        url: '/lead-programs',
        requires: {},
        blocks: {
          usertypes: ['limited-to-contracting-signature-agent'],
        },
        overrides: {
          usertypes: ['system-admin', 'internal-admin'],
        },
      },
      {
        label: 'Order Leads',
        url: '/order-leads',
        requires: {},
        blocks: {
          usertypes: ['limited-to-contracting-signature-agent'],
        },
        overrides: {
          usertypes: ['system-admin', 'internal-admin'],
        },
      },
      {
        label: 'My USABG BUCK$',
        url: '/account-ledger',
        requires: {
          profile: ['has_onboarded'],
        },
        blocks: {
          usertypes: [
            'affiliate-group',
            'associate-group',
            'limited-to-contracting-signature-agent',
          ],
        },
        overrides: {
          usertypes: [
            'system-admin',
            'internal-admin',
            'internal-staff',
            'agency-owner',
            'divisional-leader',
            'regional-leader',
            'district-leader',
          ],
        },
      },
    ],
  },
  {
    label: 'Agent Training & Calendar',
    requires: {},
    children: [
      {
        url: 'https://www.webce.com/usabghome',
        label: 'Insurance Education',
        requires: {},
        blocks: {
          usertypes: ['affiliate-group', 'associate-agent'],
        },
        overrides: {
          usertypes: ['system-admin', 'internal-admin'],
        },
      },
      {
        url: '/trainings',
        label: 'Recorded Trainings',
        requires: {},
        blocks: {
          usertypes: ['limited-to-contracting-signature-agent'],
        },
        overrides: {
          usertypes: ['system-admin', 'internal-admin'],
        },
      },
      {
        label: 'Training Calendar',
        url: '/training-calendar',
        requires: {},
        blocks: {
          usertypes: ['limited-to-contracting-signature-agent'],
        },
        overrides: {
          usertypes: ['system-admin', 'internal-admin'],
        },
      },
    ],
  },
  {
    label: 'Carrier Contracting & Commissions',
    url: '/agent-contracting',
    requires: {
      profile: ['has_onboarded'],
    },
    overrides: {
      profile: ['has_onboarded'],
    },
  },
  {
    label: 'Contests',
    requires: {},
    blocks: {},
    children: [
      {
        label: 'Carrier Contests',
        url: '/carrier-contests',
        requires: {},
        blocks: {},
      },
      {
        label: 'USABG Hall of Fame',
        url: '/hall-of-fame',
        requires: {},
        blocks: {},
      },
      {
        label: 'USABG BUCK$ Program',
        url: '/usabg-bucks-program',
        requires: {},
        blocks: {},
      },
    ],
  },
  {
    label: 'Agency Builder',
    icon: 'tasks',
    children: [
      {
        url: '/:userId/enroll',
        label: 'Agent Enrollment',
        requires: {
          usertypes: ['system-admin', 'agency-owner', 'recruiter-group'],
        },
        locked: 'HAS_OUTSTANDING_LEADER_REQUIREMENTS',
      },
      {
        url: '/:userId/enroll',
        label: 'Agent Enrollment',
        requires: {
          profile: ['can_recruit'],
        },
      },
      {
        url: '/carrier-priorities',
        label: 'Carrier Priorities',
        requires: {
          usertypes: [
            'system-admin',
            'agency-owner',
            'recruiter-group',
            'internal-admin',
            'division-admin',
            'region-admin',
            'district-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      // {
      //   url: '/create-csr-agent',
      //   label: 'Create CSR/BA Agent',
      //   requires: {
      //     usertypes: [
      //       'system-admin',
      //       'agency-owner',
      //       'divisional-leader',
      //       'internal-admin',
      //       'division-admin',
      //     ],
      //   },
      // },
      // {
      //   url: '/create-division-admin',
      //   label: 'Create Division Admin',
      //   requires: {
      //     usertypes: [
      //       'system-admin',
      //       'agency-owner',
      //       'divisional-leader',
      //       'internal-admin',
      //       'division-admin',
      //     ],
      //   },
      // },
      // {
      //   url: '/create-region-admin',
      //   label: 'Create Region Admin',
      //   requires: {
      //     usertypes: [
      //       'system-admin',
      //       'agency-owner',
      //       'regional-leader',
      //       'internal-admin',
      //       'region-admin',
      //     ],
      //   },
      // },
      // {
      //   url: '/create-district-admin',
      //   label: 'Create District Admin',
      //   requires: {
      //     usertypes: [
      //       'system-admin',
      //       'agency-owner',
      //       'district-leader',
      //       'internal-admin',
      //       'district-admin',
      //     ],
      //   },
      // },
      {
        url: '/create-admin-or-csr-agent',
        label: 'Create Admin/BA/CSR Agent',
        requires: {
          usertypes: [
            'agency-owner',
            'internal-admin',
            'internal-stuff',
            'system-admin',
            'divisional-leader',
            'division-admin',
            'regional-leader',
            'region-admin',
            'district-leader',
            'district-admin',
          ],
        },
      },
      {
        url: '/email-distribution',
        label: 'Email Distribution Lists',
        block: {
          usertypes: ['affiliate-group', 'associate-agent'],
        },
        requires: {
          usertypes: [
            'system-admin',
            'agency-owner',
            'recruiter-group',
            'internal-admin',
            'division-admin',
            'region-admin',
            'district-admin',
          ],
        },
      },
      // {
      //   url: '/welcome',
      //   label: 'Getting Started',
      //   requires: {
      //     usertypes: [
      //       'system-admin',
      //       'agency-owner',
      //       'recruiter-group',
      //       'internal-admin',
      //       'division-admin',
      //       'region-admin',
      //       'district-admin',
      //     ],
      //   },
      // },
      {
        url: '/leader-guidelines',
        label: 'Guidelines',
        requires: {
          usertypes: [
            'system-admin',
            'agency-owner',
            'recruiter-group',
            'internal-admin',
            'division-admin',
            'region-admin',
            'district-admin',
          ],
        },
      },
      {
        url: '/leader-resources',
        label: 'Leader Resources',
        requires: {
          usertypes: [
            'system-admin',
            'agency-owner',
            'recruiter-group',
            'internal-admin',
            'division-admin',
            'region-admin',
            'district-admin',
          ],
          block: ['affiliate-leader', 'associate-leader'],
        },
      },
      {
        url: '/manage-agents',
        label: 'Team Growth Report',
        requires: {
          usertypes: [
            'system-admin',
            'agency-owner',
            'recruiter-group',
            'internal-admin',
            'division-admin',
            'region-admin',
            'district-admin',
          ],
        },
      },
      {
        url: '/prospects',
        label: 'My Prospects',
        requires: {
          usertypes: [
            'system-admin',
            'agency-owner',
            'recruiter-group',
            'division-admin',
            'region-admin',
            'district-admin',
          ],
        },
      },
    ],
  },
  {
    label: 'Reports',
    icon: 'chart-bar',
    children: [
      {
        url: '/kpi-tracking',
        label: 'KPI Tracking',
        requires: {
          profile: ['has_onboarded'],
        },
        blocks: {
          usertypes: ['limited-to-contracting-signature-agent'],
        },
      },
      {
        url: '/kpi-dashboard',
        label: 'KPI Dashboard',
        requires: {},
        blocks: {
          usertypes: [
            'affiliate-group',
            'associate-group',
            'limited-to-contracting-signature-agent',
          ],
        },
      },
      {
        url: '/reporting/my-wallet',
        label: 'Agent Wallet',
        requires: {},
        blocks: {
          usertypes: ['limited-to-contracting-signature-agent'],
        },
      },
      {
        url: '/reporting/kpi-report/kpi-performance',
        label: 'KPI Performance',
        requires: {},
        blocks: {
          usertypes: [
            'affiliate-group',
            'associate-group',
            'limited-to-contracting-signature-agent',
          ],
        },
      },
      // {
      //   url: '/leaderboards',
      //   label: 'Leader Boards',
      //   requires: {},
      // },
      // {
      //   url: '/reporting/agent-ledgers',
      //   label: 'USABG BUCK$',
      //   requires: {},
      //   blocks: {
      //     usertypes: ['limited-to-contracting-signature-agent'],
      //   },
      // },
      {
        url: '/reporting/my-production',
        label: 'Production Reports',
        requires: {},
        blocks: {
          usertypes: ['limited-to-contracting-signature-agent'],
        },
      },
      // {
      //   url: '/reporting/downline-production',
      //   label: 'Downline Production',
      //   requires: {
      //     profile: ['has_onboarded'],
      //   },
      //   blocks: {
      //     usertypes: ['affiliate-agent', 'associate-agent'],
      //   },
      // },
      // {
      //   url: '/reporting/division-production',
      //   label: 'Division Production',
      //   requires: {
      //     profile: ['has_onboarded'],
      //     usertypes: [
      //       'agency-owner',
      //       'divisional-leader',
      //       'internal-admin',
      //       'system-admin',
      //       'division-admin',
      //     ],
      //   },
      // },
      // {
      //   url: '/reporting/agency-production',
      //   label: 'Agency Production',
      //   requires: {
      //     profile: ['has_onboarded'],
      //     usertypes: [
      //       'agency-owner',
      //       'divisional-leader',
      //       'internal-admin',
      //       'system-admin',
      //     ],
      //   },
      // },
      {
        url: '/reporting/agency-wallets',
        label: 'Agency Wallets',
        requires: {
          profile: ['has_onboarded'],
          usertypes: ['agency-owner', 'internal-admin', 'system-admin'],
        },
      },
      // {
      //   url: '/reporting/district-totals',
      //   label: 'District Totals',
      //   requires: {
      //     profile: ['has_onboarded'],
      //     usertypes: [
      //       'agency-owner',
      //       'system-admin',
      //       'internal-admin',
      //       'internal-staff',
      //       'district-leader',
      //       'regional-leader',
      //       'divisional-leader',
      //       'division-admin',
      //       'region-admin',
      //       'district-admin'
      //     ],
      //   },
      //   blocks: {
      //     usertypes: ['affiliate-group', 'associate-group'],
      //   },
      // },
      // {
      //   url: '/reporting/region-totals',
      //   label: 'Region Totals',
      //   requires: {
      //     profile: ['has_onboarded'],
      //     usertypes: [
      //       'agency-owner',
      //       'system-admin',
      //       'internal-admin',
      //       'internal-staff',
      //       'regional-leader',
      //       'divisional-leader',
      //       'division-admin',
      //       'region-admin',
      //     ],
      //   },
      //   blocks: {
      //     usertypes: ['affiliate-group', 'associate-group'],
      //   },
      // },
      // {
      //   url: '/reporting/division-totals',
      //   label: 'Division Totals',
      //   requires: {
      //     profile: ['has_onboarded'],
      //     usertypes: [
      //       'agency-owner',
      //       'system-admin',
      //       'internal-admin',
      //       'internal-staff',
      //       'divisional-leader',
      //       'division-admin',
      //     ],
      //   },
      //   blocks: {
      //     usertypes: ['affiliate-group', 'associate-group'],
      //   },
      // },
      // {
      //   url: '/reporting/leaders',
      //   label: 'Leader Reports',
      //   requires: {
      //     profile: ['has_onboarded'],
      //     usertypes: [
      //       'system-admin',
      //       'agency-owner',
      //       'recruiter-group',
      //       'division-admin',
      //       'region-admin',
      //       'district-admin',
      //       'career-agent',
      //     ],
      //   },
      //   overrides: {
      //     usertypes: ['system-admin', 'internal-admin', 'divisional-leader'],
      //   },
      //   blocks: {
      //     usertypes: ['affiliate-group', 'associate-group'],
      //   },
      // },
      {
        url: '/reporting/referrals',
        label: 'Referrals',
        requires: {
          usertypes: ['affiliate-group', 'associate-group'],
          profile: ['has_onboarded'],
        },
      },
      {
        url: '/reporting/referrals',
        label: 'Recruiting & Referrals',
        requires: {
          profile: ['has_onboarded'],
        },
        blocks: {
          usertypes: ['affiliate-group', 'associate-group'],
        },
      },
      {
        url: '/reporting/internal',
        label: 'NSM Report',
        requires: {
          usertypes: [
            'system-admin',
            'internal-admin',
            'internal-staff',
            'agency-owner',
          ],
        },
      },
      {
        url: '/reports/growth-report',
        label: 'Personal Growth Report',
        requires: {
          profile: ['has_onboarded'],
        },
        blocks: {
          usertypes: ['affiliate-group', 'associate-group'],
        },
      },
      {
        url: '/reports/leaders',
        label: 'Leader Report Card',
        requires: {
          profile: ['has_onboarded'],
          usertypes: [
            'internal-admin',
            'system-admin',
            'district-leader',
            'regional-leader',
            'divisional-leader',
            'agency-owner',
            'division-admin',
            'region-admin',
            'district-admin',
          ],
        },
        blocks: {
          usertypes: ['affiliate-group', 'associate-group'],
        },
      },
      {
        url: '/reports/subscriptions',
        label: 'Subscription Reports',
        requires: {
          usertypes: ['internal-admin', 'system-admin', 'agency-owner'],
        },
      },
      {
        url: '/manage-agents',
        label: 'Team Growth Report',
        requires: {
          usertypes: [
            'system-admin',
            'agency-owner',
            'recruiter-group',
            'internal-admin',
            'division-admin',
            'region-admin',
            'district-admin',
          ],
        },
      },
    ],
  },
]

const MINOR_NAV_LINKS = {
  admin_actions: {
    label: 'Admin Actions',
    icon: 'lock',
    children: [
      {
        label: 'Agency Info',
        url: '/admin-info',
        urlRegex: '/admin-info',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Agent Wallets',
        url: '/admin/ledgers',
        urlRegex: /\/admin\/ledgers/,
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'BA Teams',
        url: '/benefit-advisor-teams',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Calendars',
        url: '/admin-calendar',
        urlRegex: /\/admin-calendar/,
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Carriers',
        url: '/admin-carriers',
        urlRegex: /\/admin-carriers/,
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Commission Levels',
        url: '/admin-commissions',
        urlRegex: '/admin-commissions',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Contact Requests',
        url: '/contact-requests',
        urlRegex: '/contact-requests',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Content Pages',
        url: '/content-pages',
        urlRegex: '/content-pages',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Carrier Contests',
        url: '/admin-carrier-contests',
        urlRegex: '/admin-carrier-contests',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Coverages',
        url: '/admin-coverage',
        urlRegex: '/admin-coverage',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Create User',
        url: '/admin-create-user',
        urlRegex: '/admin-create-user',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Divisions',
        url: '/admin-divisions',
        urlRegex: '/admin-divisions',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'E-mail Notifications',
        url: '/admin-emails',
        urlRegex: '/admin-emails',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Internal Resources',
        url: '/internal-resources',
        urlRegex: '/internal-resources',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Knowledgebase',
        url: '/kb-support',
        urlRegex: '/kb-support',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Lead Programs',
        url: '/admin-lead-programs',
        urlRegex: '/admin-lead-programs',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Lead Sources',
        url: '/admin-leads',
        urlRegex: '/admin-leads',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Manage Messages',
        url: '/manage-messaging',
        urlRegex: '/manage-messaging',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Package & Features',
        url: '/admin-packages',
        urlRegex: '/admin-packages',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Products',
        url: '/admin-products',
        urlRegex: '/admin-products',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Promotion Codes',
        url: '/admin/promotion-codes',
        urlRegex: '/admin/promotion-codes',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Prospects',
        url: '/admin-prospects',
        urlRegex: '/admin-prospects',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Referrals',
        url: '/admin-referrals',
        urlRegex: '/admin-referrals',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Reports',
        url: '/admin-reports',
        urlRegex: '/admin-reports',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Resources',
        url: '/admin-resources',
        urlRegex: '/admin-resources',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Resource Types',
        url: '/admin-resourceType',
        urlRegex: '/admin-resourceType',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Terms',
        url: '/admin-terms',
        urlRegex: '/admin-terms',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Tools',
        url: '/admin-techtools',
        urlRegex: '/admin-techtools',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Training Quizzes',
        url: '/admin/training-quizes',
        urlRegex: '/admin/training-quizes',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Quote Requests',
        url: '/quote-requests',
        urlRegex: '/quote-requests',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
      {
        label: 'Update Points',
        url: '/admin-points',
        urlRegex: '/admin-points',
        requires: {
          usertypes: [
            'agency-owner',
            'system-admin',
            'internal-admin',
            'internal-staff',
          ],
        },
      },
    ],
  },
}

export { MAJOR_NAV_LINKS, MINOR_NAV_LINKS }
