import React from 'react'
import { convertToCurrency } from './../../../../shared/formatters/convertToCurrency.formatter'
import { convertToCurrencyWithoutSign } from './../../../../shared/formatters/convertToCurrencyWithoutSign.formatter'
import ReportsStore from './../../store/ReportsPage.store'
import { MDBIcon } from 'mdbreact'
import moment from 'moment'
import _ from 'lodash'

const goTo = (path) => (window.location = `${path}`)

const ucwords = (str) =>
  `${str}`
    .trim()
    .replace(/^([a-z])|[\s_-]+([a-z])/g, ($1) => $1.toUpperCase())
    .replace(/-/g, ' ')

const camelToSpace = (str) =>
  `${str}`
    .trim()
    .replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`)
    .trim()

const renderEditBtn = (path, callback) => (
  <a
    className={'btn edit-btn d-flex align-items-center justify-content-center'}
    onClick={(event) => typeof callback === 'function' && callback(path, event)}
    href={path}
  >
    <div className={'cursor-pointer d-inline-block'} role={'button'}>
      <MDBIcon icon="edit" className="p-1" />
    </div>
  </a>
)

const renderViewBtn = (config, callback) => (
  <a
    href="/"
    className={'btn edit-btn d-flex align-items-center justify-content-center'}
    onClick={(event) => {
      event.preventDefault()
      typeof callback === 'function' && callback(config, event)
    }}
  >
    <div className={'cursor-pointer d-inline-block'} role={'button'}>
      <MDBIcon icon="eye" className="p-1" />
    </div>
  </a>
)

const extractDateString = (date) =>
  date && typeof date === 'string' ? date.split('T').shift() : date

const renderDateColumn = (date) => {
  if (date && typeof date === 'string')
    return (
      <span
        searchvalue={
          date ? moment(date, 'YYYY-MM-DD').format('YYYY/MM/DD') : ''
        }
      >
        {date ? moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'N/A'}
      </span>
    )

  return (
    <span searchvalue={date ? moment(date).format('YYYY/MM/DD') : ''}>
      {date ? moment(date).format('MM/DD/YYYY') : 'N/A'}
    </span>
  )
}

/*
 * --------------------------------------------------------------------------------
 * `My Production` Row Formatters
 *
 */
const row__individualdetails = (rows, columns, totals) => {
  rows = rows.filter(Boolean).map((row) => {
    const {
      av_user,
      av_upline,
      av_date,
      av_eff_date,
      av_client,
      av_state,
      av_carrier,
      av_appid,
      av_premium,
      av_term_length,
      av_coverage,
      av_id,
      av_sub_agent,
      referral_partner,
      av_comments,
      av_points,
      lives,
      av_status,
      av_product,
      av_leadsource,
    } = row

    return {
      agent: av_user ? av_user : '',
      upline: av_upline ? av_upline : '',
      submission_date: renderDateColumn(extractDateString(av_date)),
      effective_date: renderDateColumn(extractDateString(av_eff_date)),
      client: av_client ? av_client : '',
      state: av_state ? av_state : '',
      carrier: av_carrier ? av_carrier : '',
      app: av_appid ? av_appid : '',
      status: av_status ? av_status : '',
      prem: convertToCurrency(av_premium),
      av: convertToCurrency(av_term_length * av_premium),
      av_points: av_points ? av_points : '',
      lives: lives ? lives : '',
      cov: av_coverage ? av_coverage : '',
      comments: av_comments ? av_comments : '',
      csr: av_sub_agent ? av_sub_agent : '',
      product: av_product ? av_product : '',
      lead_source: av_leadsource ? av_leadsource : '',
      ref_partner: referral_partner ? referral_partner : '',
      edit: renderEditBtn(`/submit-sales/${av_id}/edit`, (path, event) => {
        event.preventDefault()
        goTo(path)
        return false
      }),
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        agent: 'Report Totals:',
        upline: '---',
        submission_date: '---',
        effective_date: '---',
        client: '---',
        state: '---',
        carrier: '---',
        app: '---',
        status: '---',
        prem: convertToCurrency(
          !isNaN(totals?.av_premium) ? totals.av_premium : 0
        ),
        av: convertToCurrency(
          !isNaN(totals?.av_premium_total) ? totals.av_premium_total : 0
        ),
        av_points: convertToCurrencyWithoutSign(
          !isNaN(totals?.av_points) ? totals.av_points : 0
        ),
        lives: convertToCurrencyWithoutSign(
          !isNaN(totals?.lives) ? totals.lives : 0
        ),
        cov: '---',
        comments: '---',
        csr: '---',
        product: '---',
        lead_source: '---',
        ref_partner: '---',
        edit: '---',
      },
    }
  }

  return { rows, columns, totals }
}

const row__individualday = (rows, columns, totals) => {
  rows = rows.filter(Boolean).map((row) => {
    const {
      av_user,
      av_upline,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
      total,
    } = row

    return {
      agent: av_user,
      upline: av_upline,
      mon: convertToCurrency(monday),
      tues: convertToCurrency(tuesday),
      wed: convertToCurrency(wednesday),
      thurs: convertToCurrency(thursday),
      fri: convertToCurrency(friday),
      sat: convertToCurrency(saturday),
      sun: convertToCurrency(sunday),
      total: convertToCurrency(total),
    }
  })

  if (totals) {
    totals.total = 0
    ;[
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ].forEach((col) => {
      totals.total += isNaN(totals[col]) ? 0 : parseFloat(totals[col])
    })

    return {
      rows,
      columns,
      totals: {
        agent: 'Report Totals:',
        upline: '---',
        mon: convertToCurrency(!isNaN(totals?.monday) ? totals.monday : 0),
        tues: convertToCurrency(!isNaN(totals?.tuesday) ? totals.tuesday : 0),
        wed: convertToCurrency(
          !isNaN(totals?.wednesday) ? totals.wednesday : 0
        ),
        thurs: convertToCurrency(
          !isNaN(totals?.thursday) ? totals.thursday : 0
        ),
        fri: convertToCurrency(!isNaN(totals?.friday) ? totals.friday : 0),
        sat: convertToCurrency(!isNaN(totals?.saturday) ? totals.saturday : 0),
        sun: convertToCurrency(!isNaN(totals?.sunday) ? totals.sunday : 0),
        total: convertToCurrency(!isNaN(totals?.total) ? totals.total : 0),
      },
    }
  }

  return { rows, columns, totals }
}

/*
 * --------------------------------------------------------------------------------
 * `Downline Production` Row Formatters
 *
 */
const row__downlinedetails = (rows, columns, totals) => {
  rows = rows.map((row, i) => {
    const {
      av_user,
      av_upline,
      av_date,
      av_eff_date,
      av_client,
      av_state,
      av_carrier,
      av_appid,
      av_premium,
      av_term_length,
      av_coverage,
      av_id,
      av_sub_agent,
      referral_partner,
      lives,
      av_points,
      av_status,
      av_comments,
      av_product,
      av_leadsource,
    } = row

    return {
      agent: av_user ? av_user : '',
      upline: av_upline ? av_upline : '',
      submission_date: renderDateColumn(extractDateString(av_date)),
      effective_date: renderDateColumn(extractDateString(av_eff_date)),
      client: av_client ? av_client : '',
      state: av_state ? av_state : '',
      carrier: av_carrier ? av_carrier : '',
      app: av_appid ? av_appid : '',
      prem: convertToCurrency(av_premium),
      status: av_status ? av_status : '',
      av: convertToCurrency(av_term_length * av_premium),
      points: av_points ? av_points : '',
      lives: lives ? lives : '',
      cov: av_coverage ? av_coverage : '',
      comments: av_comments ? av_comments : '',
      csr: av_sub_agent ? av_sub_agent : '',
      product: av_product ? av_product : '',
      lead_source: av_leadsource ? av_leadsource : '',
      ref_partner: referral_partner ? referral_partner : '',
      edit: renderEditBtn(`/submit-sales/${av_id}/edit`, (path, event) => {
        event.preventDefault()
        goTo(path)
        return false
      }),
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        agent: 'Report Totals:',
        upline: '---',
        submission_date: '---',
        effective_date: '---',
        client: '---',
        state: '---',
        carrier: '---',
        app: '---',
        prem: convertToCurrency(
          isNaN(totals?.av_premium) ? 0 : totals.av_premium
        ),
        status: '---',
        av: convertToCurrency(
          isNaN(totals?.av_premium_total) ? 0 : totals.av_premium_total
        ),
        points: convertToCurrencyWithoutSign(
          isNaN(totals?.av_points) ? 0 : totals.av_points
        ),
        lives: convertToCurrencyWithoutSign(
          isNaN(totals?.lives) ? 0 : totals.lives
        ),
        cov: '---',
        comments: '---',
        csr: '---',
        product: '---',
        lead_source: '---',
        ref_partner: '---',
        edit: '---',
      },
    }
  }

  return { rows, columns, totals }
}

const row__downlineday = (rows, columns, totals) => {
  rows = rows.filter(Boolean).map((row) => {
    const {
      av_user,
      av_upline,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
      total,
    } = row

    return {
      agent: av_user,
      upline: av_upline,
      mon: convertToCurrency(monday),
      tues: convertToCurrency(tuesday),
      wed: convertToCurrency(wednesday),
      thurs: convertToCurrency(thursday),
      fri: convertToCurrency(friday),
      sat: convertToCurrency(saturday),
      sun: convertToCurrency(sunday),
      total: convertToCurrency(total),
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        agent: 'Report Totals:',
        upline: '---',
        mon: convertToCurrency(isNaN(totals?.monday) ? 0 : totals.monday),
        tues: convertToCurrency(isNaN(totals?.tuesday) ? 0 : totals.tuesday),
        wed: convertToCurrency(isNaN(totals?.wednesday) ? 0 : totals.wednesday),
        thurs: convertToCurrency(isNaN(totals?.thursday) ? 0 : totals.thursday),
        fri: convertToCurrency(isNaN(totals?.friday) ? 0 : totals.friday),
        sat: convertToCurrency(isNaN(totals?.saturday) ? 0 : totals.saturday),
        sun: convertToCurrency(isNaN(totals?.sunday) ? 0 : totals.sunday),
        total: convertToCurrency(isNaN(totals?.total) ? 0 : totals.total),
      },
    }
  }

  return { rows, columns, totals }
}

/*
 * --------------------------------------------------------------------------------
 * `Division Production` Row Formatters
 *
 */
const row__divisionproductiondetails = (rows, columns, totals) => {
  rows = rows.map((row, i) => {
    const {
      av_user,
      av_upline,
      av_date,
      av_eff_date,
      av_client,
      av_state,
      av_carrier,
      av_appid,
      av_premium,
      av_term_length,
      av_coverage,
      av_points,
      lives,
      av_id,
      av_sub_agent,
      referral_partner,
      av_comments,
    } = row

    return {
      agent: av_user ? av_user : '',
      upline: av_upline ? av_upline : '',
      submission_date: renderDateColumn(extractDateString(av_date)),
      effective_date: renderDateColumn(extractDateString(av_eff_date)),
      client: av_client ? av_client : '',
      state: av_state ? av_state : '',
      carrier: av_carrier ? av_carrier : '',
      app: av_appid ? av_appid : '',
      prem: convertToCurrency(av_premium),
      av: convertToCurrency(av_term_length * av_premium),
      points: av_points ? av_points : '',
      lives: lives ? lives : '',
      cov: av_coverage ? av_coverage : '',
      comments: av_comments ? av_comments : '',
      csr: av_sub_agent ? av_sub_agent : '',
      ref_partner: referral_partner ? referral_partner : '',
      edit: renderEditBtn(`/submit-sales/${av_id}/edit`, (path, event) => {
        event.preventDefault()
        goTo(path)
        return false
      }),
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        agent: 'Report Totals:',
        upline: '---',
        submission_date: '---',
        effective_date: '---',
        client: '---',
        state: '---',
        carrier: '---',
        app: '---',
        prem: convertToCurrency(
          !isNaN(totals?.av_premium) ? totals.av_premium : 0
        ),
        av: convertToCurrency(
          !isNaN(totals?.av_premium_total) ? totals.av_premium_total : 0
        ),
        points: convertToCurrencyWithoutSign(
          !isNaN(totals?.av_points) ? totals.av_points : 0
        ),
        lives: convertToCurrencyWithoutSign(
          !isNaN(totals?.lives) ? totals.lives : 0
        ),
        cov: '---',
        comments: '---',
        csr: '---',
        ref_partner: '---',
        edit: '---',
      },
    }
  }

  return { rows, columns, totals }
}

const row__divisionproductionday = (rows, columns, totals) => {
  rows = rows.map((row) => {
    const {
      av_user,
      av_upline,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
      total,
    } = row

    return {
      agent: av_user,
      upline: av_upline,
      mon: convertToCurrency(monday),
      tues: convertToCurrency(tuesday),
      wed: convertToCurrency(wednesday),
      thurs: convertToCurrency(thursday),
      fri: convertToCurrency(friday),
      sat: convertToCurrency(saturday),
      sun: convertToCurrency(sunday),
      total: convertToCurrency(total),
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        agent: 'Report Totals:',
        upline: '---',
        mon: convertToCurrency(isNaN(totals?.monday) ? 0 : totals.monday),
        tues: convertToCurrency(isNaN(totals?.tuesday) ? 0 : totals.tuesday),
        wed: convertToCurrency(isNaN(totals?.wednesday) ? 0 : totals.wednesday),
        thurs: convertToCurrency(isNaN(totals?.thursday) ? 0 : totals.thursday),
        fri: convertToCurrency(isNaN(totals?.friday) ? 0 : totals.friday),
        sat: convertToCurrency(isNaN(totals?.saturday) ? 0 : totals.saturday),
        sun: convertToCurrency(isNaN(totals?.sunday) ? 0 : totals.sunday),
        total: convertToCurrency(isNaN(totals?.total) ? 0 : totals.total),
      },
    }
  }

  return { rows, columns, totals }
}

/*
 * --------------------------------------------------------------------------------
 * GENERIC Row Formatters
 * Used in `My Production`, `Downline Production` & `Division Production`
 *
 */
const sanitizeAgencyRowProps = (row, remove) => {
  for (let [key, value] of Object.entries(row)) {
    if (remove.indexOf(`${key}`.toLowerCase()) > -1) delete row[key]
    else if (/(\(AV\)|(Total AV))$/.test(key))
      row[key] = typeof value == 'number' ? convertToCurrency(value) : value
    else row[key] = typeof value == 'number' ? parseInt(value) : value
  }
  return row
}

const prepAgencyColumns = (columns, row, hide) => {
  const isLastColTotals = () => {
    let t = columns.pop(),
      isTotal = t && t?.field && t?.field.toLowerCase().indexOf('total') > -1
    columns.push(t)
    return isTotal
  }

  let total = isLastColTotals() ? columns.pop() : false
  Object.keys(row).forEach((item, i) => {
    if (hide.indexOf(`${item}`.toLowerCase()) < 0)
      columns.push({
        label: item.replace(/_/g, ' '),
        field: item,
      })
  })
  if (total) columns.push(total)
  return columns
}

const agencyFormatter = (rows, columns, totals, remove, hide) => {
  let row

  // format number of row values
  // & hide un-wanted table row properties.
  rows = rows.map((row) => sanitizeAgencyRowProps(row, remove))

  // setup columns for agency leadsource and/or carrier tables.
  if ((row = rows.filter(Boolean).shift()))
    prepAgencyColumns(columns, row, hide)

  // append sortable columns for dynamic columns.
  columns = columns.map((col) => {
    col.sort_col = col?.sort_col ? col?.sort_col : col.field
    return col
  })

  if (totals) {
    Object.keys(totals).forEach((prop) => {
      totals[prop] = /(\(AV\)|(Total AV))$/.test(prop)
        ? convertToCurrency(totals[prop])
        : convertToCurrencyWithoutSign(totals[prop])
    })

    return {
      rows,
      columns,
      totals:
        columns.filter((col) => col.field === 'av_upline').length > 0
          ? {
              av_user: 'Report Totals:',
              av_upline: '---',
              ...totals,
            }
          : {
              av_user: 'Report Totals:',
              ...totals,
            },
    }
  }

  return { rows, columns, totals }
}

const row__bycarrier = (rows, columns, totals) => {
  let remove = ['user_id', 'carrier_id'],
    hide = ['user_id', 'carrier_id', 'av_user', 'av_upline', 'total']
  return agencyFormatter(rows, columns, totals, remove, hide)
}

const row__byagencyleadsource = (rows, columns, totals) => {
  let remove = ['user_id', 'leadsource_id', 'usertype'],
    hide = [
      'user_id',
      'leadsource_id',
      'usertype',
      'av_user',
      'av_upline',
      'total',
    ]
  return agencyFormatter(rows, columns, totals, remove, hide)
}

const row__byagencyleadsourcecarrier = (rows, columns, totals) => {
  let remove = ['user_id', 'leadsource', 'leadsource_id', 'usertype'],
    hide = [
      'user_id',
      'leadsource',
      'av_leadsource',
      'leadsource_id',
      'usertype',
      'av_user',
      'av_upline',
      'total',
    ]
  return agencyFormatter(rows, columns, totals, remove, hide)
}

const row__byagencyleadsourceaggregate = (rows, columns, totals) => {
  rows = rows.filter(Boolean).map((row) => {
    const { av_leadsource, med_adv, lives, av_points, av_premium_total } = row

    return {
      av_leadsource,
      med_adv: convertToCurrencyWithoutSign(
        isNaN(med_adv) ? 0 : parseFloat(med_adv)
      ),
      lives: convertToCurrencyWithoutSign(isNaN(lives) ? 0 : parseFloat(lives)),
      av_points: convertToCurrencyWithoutSign(
        isNaN(av_points) ? 0 : parseFloat(av_points)
      ),
      av_premium_total: convertToCurrency(
        isNaN(av_premium_total) ? 0 : parseFloat(av_premium_total)
      ),
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        av_leadsource: 'Report Totals:',
        med_adv: convertToCurrencyWithoutSign(
          totals?.med_adv === null || isNaN(totals?.med_adv)
            ? 0
            : parseFloat(totals?.med_adv)
        ),
        lives: convertToCurrencyWithoutSign(
          totals?.lives === null || isNaN(totals?.lives)
            ? 0
            : parseFloat(totals?.lives)
        ),
        av_points: convertToCurrencyWithoutSign(
          totals?.av_points === null || isNaN(totals?.av_points)
            ? 0
            : parseFloat(totals?.av_points)
        ),
        av_premium_total: convertToCurrency(
          totals?.av_premium_total === null || isNaN(totals?.av_premium_total)
            ? 0
            : parseFloat(totals?.av_premium_total)
        ),
      },
    }
  }

  return { rows, columns, totals }
}

const row__agentstatistics = (rows, columns, totals) => {
  rows = rows.map((row) => {
    const {
      av_user,
      total_apps,
      issued_count,
      declined_count,
      withdrawn_count,
      cancelled_count,
      transferred_count,
      apps_per_client,
      issued_apps_percent,
      av_per_app,
      av_per_client,
      av_total,
      total_clients,
      lives_per_app,
      lives_per_client,
      lives_total,
      points_per_app,
      points_per_client,
      points_total,
    } = row

    return {
      agent: av_user,
      clients: convertToCurrencyWithoutSign(
        isNaN(total_clients) ? 0 : total_clients
      ),
      apps: convertToCurrencyWithoutSign(isNaN(total_apps) ? 0 : total_apps),
      issued: convertToCurrencyWithoutSign(
        isNaN(issued_count) ? 0 : issued_count
      ),
      transferred: convertToCurrencyWithoutSign(
        isNaN(transferred_count) ? 0 : transferred_count
      ),
      declined: convertToCurrencyWithoutSign(
        isNaN(declined_count) ? 0 : declined_count
      ),
      withdrawn: convertToCurrencyWithoutSign(
        isNaN(withdrawn_count) ? 0 : withdrawn_count
      ),
      canceled: convertToCurrencyWithoutSign(
        isNaN(cancelled_count) ? 0 : cancelled_count
      ),
      apps_per_client: convertToCurrencyWithoutSign(
        isNaN(apps_per_client) ? 0 : apps_per_client
      ),
      issued_apps: convertToCurrencyWithoutSign(
        isNaN(issued_apps_percent) ? 0 : issued_apps_percent
      ),
      av_per_app: convertToCurrency(isNaN(av_per_app) ? 0 : av_per_app),
      av_per_client: convertToCurrency(
        isNaN(av_per_client) ? 0 : av_per_client
      ),
      total_av: convertToCurrency(isNaN(av_total) ? 0 : av_total),
      lives_per_app: convertToCurrencyWithoutSign(
        isNaN(lives_per_app) ? 0 : lives_per_app
      ),
      lives_per_client: convertToCurrencyWithoutSign(
        isNaN(lives_per_client) ? 0 : lives_per_client
      ),
      total_lives: convertToCurrencyWithoutSign(
        isNaN(lives_total) ? 0 : lives_total
      ),
      points_per_app: convertToCurrencyWithoutSign(
        isNaN(points_per_app) ? 0 : points_per_app
      ),
      points_per_client: convertToCurrencyWithoutSign(
        isNaN(points_per_client) ? 0 : points_per_client
      ),
      total_points: convertToCurrencyWithoutSign(
        isNaN(points_total) ? 0 : points_total
      ),
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        agent: 'Report Totals:',
        clients: convertToCurrencyWithoutSign(
          isNaN(totals?.total_clients) ? 0 : totals.total_clients
        ),
        apps: convertToCurrencyWithoutSign(
          isNaN(totals?.total_apps) ? 0 : totals.total_apps
        ),
        issued: convertToCurrencyWithoutSign(
          isNaN(totals?.issued_count) ? 0 : totals.issued_count
        ),
        transferred: convertToCurrencyWithoutSign(
          isNaN(totals?.transferred_count) ? 0 : totals.transferred_count
        ),
        declined: convertToCurrencyWithoutSign(
          isNaN(totals?.declined_count) ? 0 : totals.declined_count
        ),
        withdrawn: convertToCurrencyWithoutSign(
          isNaN(totals?.withdrawn_count) ? 0 : totals.withdrawn_count
        ),
        canceled: convertToCurrencyWithoutSign(
          isNaN(totals?.cancelled_count) ? 0 : totals.cancelled_count
        ),
        apps_per_client: convertToCurrencyWithoutSign(
          isNaN(totals?.apps_per_client) ? 0 : totals.apps_per_client
        ),
        issued_apps: convertToCurrencyWithoutSign(
          isNaN(totals?.issued_apps_percent) ? 0 : totals.issued_apps_percent
        ),
        av_per_app: convertToCurrency(
          isNaN(totals?.av_per_app) ? 0 : totals.av_per_app
        ),
        av_per_client: convertToCurrency(
          isNaN(totals?.av_per_client) ? 0 : totals.av_per_client
        ),
        total_av: convertToCurrency(
          isNaN(totals?.av_total) ? 0 : totals.av_total
        ),
        lives_per_app: convertToCurrencyWithoutSign(
          isNaN(totals?.lives_per_app) ? 0 : totals.lives_per_app
        ),
        lives_per_client: convertToCurrencyWithoutSign(
          isNaN(totals?.lives_per_client) ? 0 : totals.lives_per_client
        ),
        total_lives: convertToCurrencyWithoutSign(
          isNaN(totals?.lives_total) ? 0 : totals.lives_total
        ),
        points_per_app: convertToCurrencyWithoutSign(
          isNaN(totals?.points_per_app) ? 0 : totals.points_per_app
        ),
        points_per_client: convertToCurrencyWithoutSign(
          isNaN(totals?.points_per_client) ? 0 : totals.points_per_client
        ),
        total_points: convertToCurrencyWithoutSign(
          isNaN(totals?.points_total) ? 0 : totals.points_total
        ),
      },
    }
  }

  return { rows, columns, totals }
}

const row__managerstatistics = (rows, columns, totals) => {
  rows = rows.map((row) => {
    if (row?.mgr_id) delete row.mgr_id
    if (row?.mgr_name) delete row.mgr_name
    if (row?.user_id) delete row.user_id

    return {
      agent: row.av_user,
      agents_with_av: convertToCurrencyWithoutSign(
        isNaN(row?.agents_with_av) ? 0 : row?.agents_with_av
      ),
      new_agents: convertToCurrencyWithoutSign(
        isNaN(row?.new_agents) ? 0 : row?.new_agents
      ),
      agent_conversions: convertToCurrencyWithoutSign(
        isNaN(row?.agent_conversions) ? 0 : row?.agent_conversions
      ),
      conversion_ratio: convertToCurrencyWithoutSign(
        isNaN(row?.conversion_ratio) ? 0 : row?.conversion_ratio
      ),
      completed_agents: convertToCurrencyWithoutSign(
        isNaN(row?.completed_agents) ? 0 : row?.completed_agents
      ),
      total_agents: convertToCurrencyWithoutSign(
        isNaN(row?.total_agents) ? 0 : row?.total_agents
      ),
      total_apps: convertToCurrencyWithoutSign(
        isNaN(row?.total_apps) ? 0 : row?.total_apps
      ),
      total_clients: convertToCurrencyWithoutSign(
        isNaN(row?.total_clients) ? 0 : row?.total_clients
      ),
      apps_per_agent: convertToCurrencyWithoutSign(
        isNaN(row?.apps_per_agent) ? 0 : row?.apps_per_agent
      ),
      apps_per_client: convertToCurrencyWithoutSign(
        isNaN(row?.apps_per_client) ? 0 : row?.apps_per_client
      ),
      submitted_count: convertToCurrencyWithoutSign(
        isNaN(row?.submitted_count) ? 0 : row?.submitted_count
      ),
      issued_count: convertToCurrencyWithoutSign(
        isNaN(row?.issued_count) ? 0 : row?.issued_count
      ),
      transferred_count: convertToCurrencyWithoutSign(
        isNaN(row?.transferred_count) ? 0 : row?.transferred_count
      ),
      declined_count: convertToCurrencyWithoutSign(
        isNaN(row?.declined_count) ? 0 : row?.declined_count
      ),
      withdrawn_count: convertToCurrencyWithoutSign(
        isNaN(row?.withdrawn_count) ? 0 : row?.withdrawn_count
      ),
      cancelled_count: convertToCurrencyWithoutSign(
        isNaN(row?.cancelled_count) ? 0 : row?.cancelled_count
      ),
      av_per_agent: convertToCurrency(
        isNaN(row?.av_per_agent) ? 0 : row?.av_per_agent
      ),
      av_per_client: convertToCurrency(
        isNaN(row?.av_per_client) ? 0 : row?.av_per_client
      ),
      av_per_app: convertToCurrency(
        isNaN(row?.av_per_app) ? 0 : row?.av_per_app
      ),
      av_total: convertToCurrency(isNaN(row?.av_total) ? 0 : row?.av_total),
      points_per_agent: convertToCurrencyWithoutSign(
        isNaN(row?.points_per_agent) ? 0 : row?.points_per_agent
      ),
      points_per_client: convertToCurrencyWithoutSign(
        isNaN(row?.points_per_client) ? 0 : row?.points_per_client
      ),
      points_per_app: convertToCurrencyWithoutSign(
        isNaN(row?.points_per_app) ? 0 : row?.points_per_app
      ),
      points_total: convertToCurrencyWithoutSign(
        isNaN(row?.points_total) ? 0 : row?.points_total
      ),
      lives_per_agent: convertToCurrencyWithoutSign(
        isNaN(row?.lives_per_agent) ? 0 : row?.lives_per_agent
      ),
      lives_per_client: convertToCurrencyWithoutSign(
        isNaN(row?.lives_per_client) ? 0 : row?.lives_per_client
      ),
      lives_per_app: convertToCurrencyWithoutSign(
        isNaN(row?.lives_per_app) ? 0 : row?.lives_per_app
      ),
      lives_total: convertToCurrencyWithoutSign(
        isNaN(row?.lives_total) ? 0 : row?.lives_total
      ),
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        agent: 'Report Totals:',
        agents_with_av: convertToCurrencyWithoutSign(
          isNaN(totals?.agents_with_av) ? 0 : totals.agents_with_av
        ),
        new_agents: convertToCurrencyWithoutSign(
          isNaN(totals?.new_agents) ? 0 : totals.new_agents
        ),
        agent_conversions: convertToCurrencyWithoutSign(
          isNaN(totals?.agent_conversions) ? 0 : totals.agent_conversions
        ),
        conversion_ratio: convertToCurrencyWithoutSign(
          isNaN(totals?.conversion_ratio) ? 0 : totals.conversion_ratio
        ),
        completed_agents: convertToCurrencyWithoutSign(
          isNaN(totals?.completed_agents) ? 0 : totals.completed_agents
        ),
        total_agents: convertToCurrencyWithoutSign(
          isNaN(totals?.total_agents) ? 0 : totals.total_agents
        ),
        total_apps: convertToCurrencyWithoutSign(
          isNaN(totals?.total_apps) ? 0 : totals.total_apps
        ),
        total_clients: convertToCurrencyWithoutSign(
          isNaN(totals?.total_clients) ? 0 : totals.total_clients
        ),
        apps_per_agent: convertToCurrencyWithoutSign(
          isNaN(totals?.apps_per_agent) ? 0 : totals.apps_per_agent
        ),
        apps_per_client: convertToCurrencyWithoutSign(
          isNaN(totals?.apps_per_client) ? 0 : totals.apps_per_client
        ),
        submitted_count: convertToCurrencyWithoutSign(
          isNaN(totals?.submitted_count) ? 0 : totals.submitted_count
        ),
        issued_count: convertToCurrencyWithoutSign(
          isNaN(totals?.issued_count) ? 0 : totals.issued_count
        ),
        transferred_count: convertToCurrencyWithoutSign(
          isNaN(totals?.transferred_count) ? 0 : totals.transferred_count
        ),
        declined_count: convertToCurrencyWithoutSign(
          isNaN(totals?.declined_count) ? 0 : totals.declined_count
        ),
        withdrawn_count: convertToCurrencyWithoutSign(
          isNaN(totals?.withdrawn_count) ? 0 : totals.withdrawn_count
        ),
        cancelled_count: convertToCurrencyWithoutSign(
          isNaN(totals?.cancelled_count) ? 0 : totals.cancelled_count
        ),
        av_per_agent: convertToCurrency(
          isNaN(totals?.av_per_agent) ? 0 : totals.av_per_agent
        ),
        av_per_client: convertToCurrency(
          isNaN(totals?.av_per_client) ? 0 : totals.av_per_client
        ),
        av_per_app: convertToCurrency(
          isNaN(totals?.av_per_app) ? 0 : totals.av_per_app
        ),
        av_total: convertToCurrency(
          isNaN(totals?.av_total) ? 0 : totals.av_total
        ),
        points_per_agent: convertToCurrencyWithoutSign(
          isNaN(totals?.points_per_agent) ? 0 : totals.points_per_agent
        ),
        points_per_client: convertToCurrencyWithoutSign(
          isNaN(totals?.points_per_client) ? 0 : totals.points_per_client
        ),
        points_per_app: convertToCurrencyWithoutSign(
          isNaN(totals?.points_per_app) ? 0 : totals.points_per_app
        ),
        points_total: convertToCurrencyWithoutSign(
          isNaN(totals?.points_total) ? 0 : totals.points_total
        ),
        lives_per_agent: convertToCurrencyWithoutSign(
          isNaN(totals?.lives_per_agent) ? 0 : totals.lives_per_agent
        ),
        lives_per_client: convertToCurrencyWithoutSign(
          isNaN(totals?.lives_per_client) ? 0 : totals.lives_per_client
        ),
        lives_per_app: convertToCurrencyWithoutSign(
          isNaN(totals?.lives_per_app) ? 0 : totals.lives_per_app
        ),
        lives_total: convertToCurrencyWithoutSign(
          isNaN(totals?.lives_total) ? 0 : totals.lives_total
        ),
      },
    }
  }

  return { rows, columns, totals }
}

const row__marketingcommitments = (rows, columns, totals, callbacks) => {
  rows = rows.map((row) => {
    return {
      u_name: row.u_name,
      entry_date: renderDateColumn(extractDateString(row.entry_date)),
      invested: (row.invested || 0).toFixed(2),
    }
  })
  if (totals) {
    return {
      rows,
      columns,
      totals: {
        u_name: 'Total',
        entry_date: '',
        invested: (totals.invested || 0).toFixed(2),
      },
    }
  }
  return { rows, columns }
}

const row__careertotals = (rows, columns, totals, callbacks) => {
  const isAcaReport = !!(ReportsStore.fetchParams.coverages || []).includes(
      'ACA'
    ),
    isMedAdvReport = !!(ReportsStore.fetchParams.coverages || []).includes(
      'MED_ADV'
    )

  // Hide growth columns, per rick.
  columns = columns.filter(
    (column) =>
      ![
        'total_av_points_growth',
        'total_av_premium_growth',
        'total_av_lives_growth',
        'total_med_adv_growth',
      ].includes(column.field)
  )
  // Hide past columns, per rick.
  columns = columns.filter((column) => column.field.indexOf('_prev') < 0)
  if (isAcaReport)
    columns = columns.filter(
      (column) =>
        ![
          'total_av_premium_prev',
          'total_av_premium_curr',
          'total_av_premium_growth',
          'total_med_adv_prev',
          'total_med_adv_curr',
          'total_med_adv_growth',
        ].includes(column.field)
    )
  else if (isMedAdvReport)
    columns = columns.filter(
      (column) =>
        ![
          'total_av_premium_prev',
          'total_av_premium_curr',
          'total_av_premium_growth',
          'total_av_lives_prev',
          'total_av_lives_curr',
          'total_av_lives_growth',
        ].includes(column.field)
    )
  else
    columns = columns.filter(
      (column) =>
        ![
          'total_med_adv_prev',
          'total_med_adv_curr',
          'total_med_adv_growth',
          'total_av_lives_prev',
          'total_av_lives_curr',
          'total_av_lives_growth',
        ].includes(column.field)
    )

  rows = rows.map((row) => {
    let userId
    if (row.hasOwnProperty('d_logo')) delete row.d_logo
    if (row.hasOwnProperty('division_id')) delete row.division_id
    if (row.hasOwnProperty('user_id')) userId = row.user_id

    if (isNaN(row.total_av_points))
      row.total_av_points = parseFloat(
        `${row.total_av_points}`.trim().replace(/\$|,/g, '')
      )
    if (isNaN(row.total_av_premium))
      row.total_av_premium = parseFloat(
        `${row.total_av_premium}`.trim().replace(/\$|,/g, '')
      )

    if (!isNaN(row.total_av_premium))
      row.total_av_premium = convertToCurrency(row.total_av_premium)

    if (!row?.view) {
      row.view = renderViewBtn(
        {
          modal_name: 'agent-career-points',
          name: row.name,
          user_id: userId,
        },
        callbacks && callbacks?.view ? callbacks.view : () => {}
      )
    }

    if (row?.user_id) delete row.user_id

    if (isAcaReport) {
      return {
        name: row.name,
        // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_prev) ? 0 : row.total_av_points_prev),
        total_av_points_curr: convertToCurrencyWithoutSign(
          isNaN(row?.total_av_points_curr) ? 0 : row.total_av_points_curr
        ),
        // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_growth) ? 0 : row.total_av_points_growth)+'%',
        // total_av_lives_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_lives_prev) ? 0 : row.total_av_lives_prev),
        total_av_lives_curr: parseInt(
          isNaN(row?.total_av_lives_curr) ? 0 : row.total_av_lives_curr
        ),
        // total_av_lives_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_lives_growth) ? 0 : row.total_av_lives_growth)+'%',
        view: row.view,
      }
    } else if (isMedAdvReport) {
      return {
        name: row.name,
        // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_prev) ? 0 : row.total_av_points_prev),
        total_av_points_curr: convertToCurrencyWithoutSign(
          isNaN(row?.total_av_points_curr) ? 0 : row.total_av_points_curr
        ),
        // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_growth) ? 0 : row.total_av_points_growth)+'%',
        // total_av_lives_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_lives_prev) ? 0 : row.total_av_lives_prev),
        total_med_adv_curr: parseInt(
          isNaN(row?.total_med_adv_curr) ? 0 : row.total_med_adv_curr
        ),
        // total_av_lives_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_lives_growth) ? 0 : row.total_av_lives_growth)+'%',
        view: row.view,
      }
    } else {
      return {
        name: row.name,
        // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_prev) ? 0 : row.total_av_points_prev),
        total_av_points_curr: convertToCurrencyWithoutSign(
          isNaN(row?.total_av_points_curr) ? 0 : row.total_av_points_curr
        ),
        // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_growth) ? 0 : row.total_av_points_growth)+'%',
        // total_av_premium_prev: convertToCurrency(isNaN(row?.total_av_premium_prev) ? 0 : row.total_av_premium_prev),
        total_av_premium_curr: convertToCurrency(
          isNaN(row?.total_av_premium_curr) ? 0 : row.total_av_premium_curr
        ),
        // total_av_premium_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_premium_growth) ? 0 : row.total_av_premium_growth)+'%',
        view: row.view,
      }
    }
  })

  if (totals) {
    if (isAcaReport) {
      return {
        rows,
        columns,
        totals: {
          name: 'Report Totals:',
          // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_prev) ? 0 : totals.total_av_points_prev),
          total_av_points_curr: convertToCurrencyWithoutSign(
            isNaN(totals?.total_av_points_curr)
              ? 0
              : totals.total_av_points_curr
          ),
          // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_growth) ? 0 : totals.total_av_points_growth)+'%',
          // total_av_lives_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_lives_prev) ? 0 : totals.total_av_lives_prev),
          total_av_lives_curr: parseInt(
            isNaN(totals?.total_av_lives_curr) ? 0 : totals.total_av_lives_curr
          ),
          // total_av_lives_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_lives_growth) ? 0 : totals.total_av_lives_growth)+'%',
          view: '---',
        },
      }
    } else if (isMedAdvReport) {
      return {
        rows,
        columns,
        totals: {
          name: 'Report Totals:',
          // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_prev) ? 0 : totals.total_av_points_prev),
          total_av_points_curr: convertToCurrencyWithoutSign(
            isNaN(totals?.total_av_points_curr)
              ? 0
              : totals.total_av_points_curr
          ),
          // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_growth) ? 0 : totals.total_av_points_growth)+'%',
          // total_av_lives_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_lives_prev) ? 0 : totals.total_av_lives_prev),
          total_med_adv_curr: parseInt(
            isNaN(totals?.total_med_adv_curr) ? 0 : totals.total_med_adv_curr
          ),
          // total_av_lives_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_lives_growth) ? 0 : totals.total_av_lives_growth)+'%',
          view: '---',
        },
      }
    } else {
      return {
        rows,
        columns,
        totals: {
          name: 'Report Totals:',
          // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_prev) ? 0 : totals.total_av_points_prev),
          total_av_points_curr: convertToCurrencyWithoutSign(
            isNaN(totals?.total_av_points_curr)
              ? 0
              : totals.total_av_points_curr
          ),
          // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_growth) ? 0 : totals.total_av_points_growth)+'%',
          // total_av_premium_prev: convertToCurrency(isNaN(totals?.total_av_premium_prev) ? 0 : totals.total_av_premium_prev),
          total_av_premium_curr: convertToCurrency(
            isNaN(totals?.total_av_premium_curr)
              ? 0
              : totals.total_av_premium_curr
          ),
          // total_av_premium_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_premium_growth) ? 0 : totals.total_av_premium_growth)+'%',
          view: '---',
        },
      }
    }
  }

  return { rows, columns, totals }
}

const row__districttotals = (rows, columns, totals, callbacks) => {
  const isAcaReport = !!(ReportsStore.fetchParams.coverages || []).includes(
      'ACA'
    ),
    isMedAdvReport = !!(ReportsStore.fetchParams.coverages || []).includes(
      'MED_ADV'
    )

  // Hide growth columns, per rick.
  columns = columns.filter(
    (column) =>
      ![
        'total_av_points_growth',
        'total_av_premium_growth',
        'total_av_lives_growth',
        'total_med_adv_growth',
      ].includes(column.field)
  )
  // Hide past columns, per rick.
  columns = columns.filter((column) => column.field.indexOf('_prev') < 0)
  if (isAcaReport)
    columns = columns.filter(
      (column) =>
        ![
          'total_av_premium_prev',
          'total_av_premium_curr',
          'total_av_premium_growth',
          'total_med_adv_prev',
          'total_med_adv_curr',
          'total_med_adv_growth',
        ].includes(column.field)
    )
  else if (isMedAdvReport)
    columns = columns.filter(
      (column) =>
        ![
          'total_av_premium_prev',
          'total_av_premium_curr',
          'total_av_premium_growth',
          'total_av_lives_prev',
          'total_av_lives_curr',
          'total_av_lives_growth',
        ].includes(column.field)
    )
  else
    columns = columns.filter(
      (column) =>
        ![
          'total_med_adv_prev',
          'total_med_adv_curr',
          'total_med_adv_growth',
          'total_av_lives_prev',
          'total_av_lives_curr',
          'total_av_lives_growth',
        ].includes(column.field)
    )

  rows = rows.map((row) => {
    let userId
    if (row.hasOwnProperty('d_logo')) delete row.d_logo
    if (row.hasOwnProperty('division_id')) delete row.division_id
    if (row.hasOwnProperty('user_id')) userId = row.user_id

    if (isNaN(row.total_av_points))
      row.total_av_points = parseFloat(
        `${row.total_av_points}`.trim().replace(/\$|,/g, '')
      )
    if (isNaN(row.total_av_premium))
      row.total_av_premium = parseFloat(
        `${row.total_av_premium}`.trim().replace(/\$|,/g, '')
      )

    if (!isNaN(row.total_av_premium))
      row.total_av_premium = convertToCurrency(row.total_av_premium)

    if (!row?.view) {
      row.view = renderViewBtn(
        {
          modal_name: 'agent-district-points',
          name: row.name,
          user_id: userId,
        },
        callbacks && callbacks?.view ? callbacks.view : () => {}
      )
    }

    if (row?.user_id) delete row.user_id

    if (isAcaReport) {
      return {
        name: row.name,
        // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_prev) ? 0 : row.total_av_points_prev),
        total_av_points_curr: convertToCurrencyWithoutSign(
          isNaN(row?.total_av_points_curr) ? 0 : row.total_av_points_curr
        ),
        // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_growth) ? 0 : row.total_av_points_growth)+'%',
        // total_av_lives_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_lives_prev) ? 0 : row.total_av_lives_prev),
        total_av_lives_curr: parseInt(
          isNaN(row?.total_av_lives_curr) ? 0 : row.total_av_lives_curr
        ),
        // total_av_lives_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_lives_growth) ? 0 : row.total_av_lives_growth)+'%',
        view: row.view,
      }
    } else if (isMedAdvReport) {
      return {
        name: row.name,
        // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_prev) ? 0 : row.total_av_points_prev),
        total_av_points_curr: convertToCurrencyWithoutSign(
          isNaN(row?.total_av_points_curr) ? 0 : row.total_av_points_curr
        ),
        // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_growth) ? 0 : row.total_av_points_growth)+'%',
        // total_av_lives_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_lives_prev) ? 0 : row.total_av_lives_prev),
        total_med_adv_curr: parseInt(
          isNaN(row?.total_med_adv_curr) ? 0 : row.total_med_adv_curr
        ),
        // total_av_lives_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_lives_growth) ? 0 : row.total_av_lives_growth)+'%',
        view: row.view,
      }
    } else {
      return {
        name: row.name,
        // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_prev) ? 0 : row.total_av_points_prev),
        total_av_points_curr: convertToCurrencyWithoutSign(
          isNaN(row?.total_av_points_curr) ? 0 : row.total_av_points_curr
        ),
        // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_growth) ? 0 : row.total_av_points_growth)+'%',
        // total_av_premium_prev: convertToCurrency(isNaN(row?.total_av_premium_prev) ? 0 : row.total_av_premium_prev),
        total_av_premium_curr: convertToCurrency(
          isNaN(row?.total_av_premium_curr) ? 0 : row.total_av_premium_curr
        ),
        // total_av_premium_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_premium_growth) ? 0 : row.total_av_premium_growth)+'%',
        view: row.view,
      }
    }
  })

  if (totals) {
    if (isAcaReport) {
      return {
        rows,
        columns,
        totals: {
          name: 'Report Totals:',
          // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_prev) ? 0 : totals.total_av_points_prev),
          total_av_points_curr: convertToCurrencyWithoutSign(
            isNaN(totals?.total_av_points_curr)
              ? 0
              : totals.total_av_points_curr
          ),
          // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_growth) ? 0 : totals.total_av_points_growth)+'%',
          // total_av_lives_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_lives_prev) ? 0 : totals.total_av_lives_prev),
          total_av_lives_curr: parseInt(
            isNaN(totals?.total_av_lives_curr) ? 0 : totals.total_av_lives_curr
          ),
          // total_av_lives_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_lives_growth) ? 0 : totals.total_av_lives_growth)+'%',
          view: '---',
        },
      }
    } else if (isMedAdvReport) {
      return {
        rows,
        columns,
        totals: {
          name: 'Report Totals:',
          // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_prev) ? 0 : totals.total_av_points_prev),
          total_av_points_curr: convertToCurrencyWithoutSign(
            isNaN(totals?.total_av_points_curr)
              ? 0
              : totals.total_av_points_curr
          ),
          // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_growth) ? 0 : totals.total_av_points_growth)+'%',
          // total_av_lives_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_lives_prev) ? 0 : totals.total_av_lives_prev),
          total_med_adv_curr: parseInt(
            isNaN(totals?.total_med_adv_curr) ? 0 : totals.total_med_adv_curr
          ),
          // total_av_lives_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_lives_growth) ? 0 : totals.total_av_lives_growth)+'%',
          view: '---',
        },
      }
    } else {
      return {
        rows,
        columns,
        totals: {
          name: 'Report Totals:',
          // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_prev) ? 0 : totals.total_av_points_prev),
          total_av_points_curr: convertToCurrencyWithoutSign(
            isNaN(totals?.total_av_points_curr)
              ? 0
              : totals.total_av_points_curr
          ),
          // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_growth) ? 0 : totals.total_av_points_growth)+'%',
          // total_av_premium_prev: convertToCurrency(isNaN(totals?.total_av_premium_prev) ? 0 : totals.total_av_premium_prev),
          total_av_premium_curr: convertToCurrency(
            isNaN(totals?.total_av_premium_curr)
              ? 0
              : totals.total_av_premium_curr
          ),
          // total_av_premium_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_premium_growth) ? 0 : totals.total_av_premium_growth)+'%',
          view: '---',
        },
      }
    }
  }

  return { rows, columns, totals }
}

const row__regiontotals = (rows, columns, totals, callbacks) => {
  const isAcaReport = !!(ReportsStore.fetchParams.coverages || []).includes(
      'ACA'
    ),
    isMedAdvReport = !!(ReportsStore.fetchParams.coverages || []).includes(
      'MED_ADV'
    )

  // Hide growth columns, per rick.
  columns = columns.filter(
    (column) =>
      ![
        'total_av_points_growth',
        'total_av_premium_growth',
        'total_av_lives_growth',
        'total_med_adv_growth',
      ].includes(column.field)
  )
  // Hide past columns, per rick.
  columns = columns.filter((column) => column.field.indexOf('_prev') < 0)
  if (isAcaReport)
    columns = columns.filter(
      (column) =>
        ![
          'total_av_premium_prev',
          'total_av_premium_curr',
          'total_av_premium_growth',
          'total_med_adv_prev',
          'total_med_adv_curr',
          'total_med_adv_growth',
        ].includes(column.field)
    )
  else if (isMedAdvReport)
    columns = columns.filter(
      (column) =>
        ![
          'total_av_premium_prev',
          'total_av_premium_curr',
          'total_av_premium_growth',
          'total_av_lives_prev',
          'total_av_lives_curr',
          'total_av_lives_growth',
        ].includes(column.field)
    )
  else
    columns = columns.filter(
      (column) =>
        ![
          'total_med_adv_prev',
          'total_med_adv_curr',
          'total_med_adv_growth',
          'total_av_lives_prev',
          'total_av_lives_curr',
          'total_av_lives_growth',
        ].includes(column.field)
    )

  rows = rows.map((row) => {
    let userId
    if (row.hasOwnProperty('d_logo')) delete row.d_logo
    if (row.hasOwnProperty('division_id')) delete row.division_id
    if (row.hasOwnProperty('user_id')) userId = row.user_id

    if (isNaN(row.total_av_points))
      row.total_av_points = parseFloat(
        `${row.total_av_points}`.trim().replace(/\$|,/g, '')
      )
    if (isNaN(row.total_av_premium))
      row.total_av_premium = parseFloat(
        `${row.total_av_premium}`.trim().replace(/\$|,/g, '')
      )

    if (!isNaN(row.total_av_premium))
      row.total_av_premium = convertToCurrency(row.total_av_premium)

    if (!row?.view) {
      row.view = renderViewBtn(
        { modal_name: 'agent-region-points', name: row.name, user_id: userId },
        callbacks && callbacks?.view ? callbacks.view : () => {}
      )
    }

    if (row?.user_id) delete row.user_id

    if (isAcaReport) {
      return {
        name: row.name,
        // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_prev) ? 0 : row.total_av_points_prev),
        total_av_points_curr: convertToCurrencyWithoutSign(
          isNaN(row?.total_av_points_curr) ? 0 : row.total_av_points_curr
        ),
        // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_growth) ? 0 : row.total_av_points_growth)+'%',
        // total_av_lives_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_lives_prev) ? 0 : row.total_av_lives_prev),
        total_av_lives_curr: parseInt(
          isNaN(row?.total_av_lives_curr) ? 0 : row.total_av_lives_curr
        ),
        // total_av_lives_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_lives_growth) ? 0 : row.total_av_lives_growth)+'%',
        view: row.view,
      }
    } else if (isMedAdvReport) {
      return {
        name: row.name,
        // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_prev) ? 0 : row.total_av_points_prev),
        total_av_points_curr: convertToCurrencyWithoutSign(
          isNaN(row?.total_av_points_curr) ? 0 : row.total_av_points_curr
        ),
        // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_growth) ? 0 : row.total_av_points_growth)+'%',
        // total_av_lives_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_lives_prev) ? 0 : row.total_av_lives_prev),
        total_med_adv_curr: parseInt(
          isNaN(row?.total_med_adv_curr) ? 0 : row.total_med_adv_curr
        ),
        // total_av_lives_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_lives_growth) ? 0 : row.total_av_lives_growth)+'%',
        view: row.view,
      }
    } else {
      return {
        name: row.name,
        // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_prev) ? 0 : row.total_av_points_prev),
        total_av_points_curr: convertToCurrencyWithoutSign(
          isNaN(row?.total_av_points_curr) ? 0 : row.total_av_points_curr
        ),
        // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_growth) ? 0 : row.total_av_points_growth)+'%',
        // total_av_premium_prev: convertToCurrency(isNaN(row?.total_av_premium_prev) ? 0 : row.total_av_premium_prev),
        total_av_premium_curr: convertToCurrency(
          isNaN(row?.total_av_premium_curr) ? 0 : row.total_av_premium_curr
        ),
        // total_av_premium_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_premium_growth) ? 0 : row.total_av_premium_growth)+'%',
        view: row.view,
      }
    }
  })

  if (totals) {
    if (isAcaReport) {
      return {
        rows,
        columns,
        totals: {
          name: 'Report Totals:',
          // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_prev) ? 0 : totals.total_av_points_prev),
          total_av_points_curr: convertToCurrencyWithoutSign(
            isNaN(totals?.total_av_points_curr)
              ? 0
              : totals.total_av_points_curr
          ),
          // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_growth) ? 0 : totals.total_av_points_growth)+'%',
          // total_av_lives_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_lives_prev) ? 0 : totals.total_av_lives_prev),
          total_av_lives_curr: parseInt(
            isNaN(totals?.total_av_lives_curr) ? 0 : totals.total_av_lives_curr
          ),
          // total_av_lives_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_lives_growth) ? 0 : totals.total_av_lives_growth)+'%',
          view: '---',
        },
      }
    } else if (isMedAdvReport) {
      return {
        rows,
        columns,
        totals: {
          name: 'Report Totals:',
          // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_prev) ? 0 : totals.total_av_points_prev),
          total_av_points_curr: convertToCurrencyWithoutSign(
            isNaN(totals?.total_av_points_curr)
              ? 0
              : totals.total_av_points_curr
          ),
          // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_growth) ? 0 : totals.total_av_points_growth)+'%',
          // total_av_lives_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_lives_prev) ? 0 : totals.total_av_lives_prev),
          total_med_adv_curr: parseInt(
            isNaN(totals?.total_med_adv_curr) ? 0 : totals.total_med_adv_curr
          ),
          // total_av_lives_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_lives_growth) ? 0 : totals.total_av_lives_growth)+'%',
          view: '---',
        },
      }
    } else {
      return {
        rows,
        columns,
        totals: {
          name: 'Report Totals:',
          // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_prev) ? 0 : totals.total_av_points_prev),
          total_av_points_curr: convertToCurrencyWithoutSign(
            isNaN(totals?.total_av_points_curr)
              ? 0
              : totals.total_av_points_curr
          ),
          // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_growth) ? 0 : totals.total_av_points_growth)+'%',
          // total_av_premium_prev: convertToCurrency(isNaN(totals?.total_av_premium_prev) ? 0 : totals.total_av_premium_prev),
          total_av_premium_curr: convertToCurrency(
            isNaN(totals?.total_av_premium_curr)
              ? 0
              : totals.total_av_premium_curr
          ),
          // total_av_premium_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_premium_growth) ? 0 : totals.total_av_premium_growth)+'%',
          view: '---',
        },
      }
    }
  }

  return { rows, columns, totals }
}

const row__divisiontotals = (rows, columns, totals, callbacks) => {
  const isAcaReport = !!(ReportsStore.fetchParams.coverages || []).includes(
      'ACA'
    ),
    isMedAdvReport = !!(ReportsStore.fetchParams.coverages || []).includes(
      'MED_ADV'
    )

  // Hide growth columns, per rick.
  columns = columns.filter(
    (column) =>
      ![
        'total_av_points_growth',
        'total_av_premium_growth',
        'total_av_lives_growth',
        'total_med_adv_growth',
      ].includes(column.field)
  )
  // Hide past columns, per rick.
  columns = columns.filter((column) => column.field.indexOf('_prev') < 0)
  if (isAcaReport)
    columns = columns.filter(
      (column) =>
        ![
          'total_av_premium_prev',
          'total_av_premium_curr',
          'total_av_premium_growth',
          'total_med_adv_prev',
          'total_med_adv_curr',
          'total_med_adv_growth',
        ].includes(column.field)
    )
  else if (isMedAdvReport)
    columns = columns.filter(
      (column) =>
        ![
          'total_av_premium_prev',
          'total_av_premium_curr',
          'total_av_premium_growth',
          'total_av_lives_prev',
          'total_av_lives_curr',
          'total_av_lives_growth',
        ].includes(column.field)
    )
  else
    columns = columns.filter(
      (column) =>
        ![
          'total_med_adv_prev',
          'total_med_adv_curr',
          'total_med_adv_growth',
          'total_av_lives_prev',
          'total_av_lives_curr',
          'total_av_lives_growth',
        ].includes(column.field)
    )

  rows = rows.map((row) => {
    if (row.hasOwnProperty('id')) delete row.id
    if (row.hasOwnProperty('usertype_id')) delete row.usertype_id

    if (isNaN(row.total_av_points))
      row.total_av_points = parseFloat(
        `${row.total_av_points}`.trim().replace(/\$|,/g, '')
      )
    if (isNaN(row.total_av_premium))
      row.total_av_premium = parseFloat(
        `${row.total_av_premium}`.trim().replace(/\$|,/g, '')
      )

    if (!isNaN(row.total_av_premium))
      row.total_av_premium = convertToCurrency(row.total_av_premium)

    if (!row?.view) {
      row.view = renderViewBtn(
        {
          modal_name: 'agent-division-points',
          name: row.name,
          user_id: row.user_id,
        },
        callbacks && callbacks?.view ? callbacks.view : () => {}
      )
    }

    if (row?.division_id) delete row.user_id
    if (row?.division_id) delete row.division_id

    if (isAcaReport) {
      return {
        d_name: row.d_name,
        // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_prev) ? 0 : row.total_av_points_prev),
        total_av_points_curr: convertToCurrencyWithoutSign(
          isNaN(row?.total_av_points_curr) ? 0 : row.total_av_points_curr
        ),
        // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_growth) ? 0 : row.total_av_points_growth)+'%',
        // total_av_lives_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_lives_prev) ? 0 : row.total_av_lives_prev),
        total_av_lives_curr: parseInt(
          isNaN(row?.total_av_lives_curr) ? 0 : row.total_av_lives_curr
        ),
        // total_av_lives_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_lives_growth) ? 0 : row.total_av_lives_growth)+'%',
        view: row.view,
      }
    } else if (isMedAdvReport) {
      return {
        d_name: row.d_name,
        // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_prev) ? 0 : row.total_av_points_prev),
        total_av_points_curr: convertToCurrencyWithoutSign(
          isNaN(row?.total_av_points_curr) ? 0 : row.total_av_points_curr
        ),
        // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_growth) ? 0 : row.total_av_points_growth)+'%',
        // total_av_lives_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_lives_prev) ? 0 : row.total_av_lives_prev),
        total_med_adv_curr: parseInt(
          isNaN(row?.total_med_adv_curr) ? 0 : row.total_med_adv_curr
        ),
        // total_av_lives_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_lives_growth) ? 0 : row.total_av_lives_growth)+'%',
        view: row.view,
      }
    } else {
      return {
        d_name: row.d_name,
        // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_prev) ? 0 : row.total_av_points_prev),
        total_av_points_curr: convertToCurrencyWithoutSign(
          isNaN(row?.total_av_points_curr) ? 0 : row.total_av_points_curr
        ),
        // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_points_growth) ? 0 : row.total_av_points_growth)+'%',
        // total_av_premium_prev: convertToCurrency(isNaN(row?.total_av_premium_prev) ? 0 : row.total_av_premium_prev),
        total_av_premium_curr: convertToCurrency(
          isNaN(row?.total_av_premium_curr) ? 0 : row.total_av_premium_curr
        ),
        // total_av_premium_growth: convertToCurrencyWithoutSign(isNaN(row?.total_av_premium_growth) ? 0 : row.total_av_premium_growth)+'%',
        view: row.view,
      }
    }
  })

  if (totals) {
    if (isAcaReport) {
      return {
        rows,
        columns,
        totals: {
          name: 'Report Totals:',
          // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_prev) ? 0 : totals.total_av_points_prev),
          total_av_points_curr: convertToCurrencyWithoutSign(
            isNaN(totals?.total_av_points_curr)
              ? 0
              : totals.total_av_points_curr
          ),
          // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_growth) ? 0 : totals.total_av_points_growth)+'%',
          // total_av_lives_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_lives_prev) ? 0 : totals.total_av_lives_prev),
          total_av_lives_curr: parseInt(
            isNaN(totals?.total_av_lives_curr) ? 0 : totals.total_av_lives_curr
          ),
          // total_av_lives_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_lives_growth) ? 0 : totals.total_av_lives_growth)+'%',
          view: '---',
        },
      }
    } else if (isMedAdvReport) {
      return {
        rows,
        columns,
        totals: {
          name: 'Report Totals:',
          // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_prev) ? 0 : totals.total_av_points_prev),
          total_av_points_curr: convertToCurrencyWithoutSign(
            isNaN(totals?.total_av_points_curr)
              ? 0
              : totals.total_av_points_curr
          ),
          // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_growth) ? 0 : totals.total_av_points_growth)+'%',
          // total_av_lives_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_lives_prev) ? 0 : totals.total_av_lives_prev),
          total_med_adv_curr: parseInt(
            isNaN(totals?.total_med_adv_curr) ? 0 : totals.total_med_adv_curr
          ),
          // total_av_lives_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_lives_growth) ? 0 : totals.total_av_lives_growth)+'%',
          view: '---',
        },
      }
    } else {
      return {
        rows,
        columns,
        totals: {
          name: 'Report Totals:',
          // total_av_points_prev: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_prev) ? 0 : totals.total_av_points_prev),
          total_av_points_curr: convertToCurrencyWithoutSign(
            isNaN(totals?.total_av_points_curr)
              ? 0
              : totals.total_av_points_curr
          ),
          // total_av_points_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_points_growth) ? 0 : totals.total_av_points_growth)+'%',
          // total_av_premium_prev: convertToCurrency(isNaN(totals?.total_av_premium_prev) ? 0 : totals.total_av_premium_prev),
          total_av_premium_curr: convertToCurrency(
            isNaN(totals?.total_av_premium_curr)
              ? 0
              : totals.total_av_premium_curr
          ),
          // total_av_premium_growth: convertToCurrencyWithoutSign(isNaN(totals?.total_av_premium_growth) ? 0 : totals.total_av_premium_growth)+'%',
          view: '---',
        },
      }
    }
  }

  return { rows, columns, totals }
}

const row__downlinecontracting = (rows, columns, callbacks) => {
  let totals = {
    total_av: 0,
  }

  rows = rows.filter((row) => {
    row.view = renderViewBtn(
      { modal_name: 'agent-downline-contracting', name: row.name },
      callbacks && callbacks?.view ? callbacks.view : () => {}
    )
    return row
  })

  return { rows, columns, totals }
}

const row__agentkpiperformance = (rows, columns, callbacks) => {
  let totals = {}

  let newRows = _.cloneDeep(rows)

  newRows = newRows.map((row) => {
    if (row.length < 2) {
      return {
        day_of_week: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        calls: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        events_attended: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        spoken_to: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        fact_finders: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        appts_set: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        presentations_offered: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        total_health_av: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        total_lives_av: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        total_points: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        referrals_requested: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        life_quotes: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        total_clients: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        total_apps: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        total_lives: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        total_hours_works: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        calls_to_events_attended: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        calls_to_spoken_to: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        calls_to_fact_finders: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        calls_to_appts_set: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        calls_to_presentations_offered: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        calls_to_total_clients: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        calls_to_total_apps: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        invested: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
      }
    }

    row = {
      day_of_week: row.shift(),
      calls: row.shift(),
      events_attended: row.shift(),
      spoken_to: row.shift(),
      fact_finders: row.shift(),
      appts_set: row.shift(),
      presentations_offered: row.shift(),
      total_health_av: row.shift(),
      total_lives_av: row.shift(),
      total_points: row.shift(),
      referrals_requested: row.shift(),
      life_quotes: row.shift(),
      total_clients: row.shift(),
      total_apps: row.shift(),
      total_lives: row.shift(),
      total_hours_works: row.shift(),
      calls_to_events_attended: row.shift(),
      calls_to_spoken_to: row.shift(),
      calls_to_fact_finders: row.shift(),
      calls_to_appts_set: row.shift(),
      calls_to_presentations_offered: row.shift(),
      calls_to_total_clients: row.shift(),
      calls_to_total_apps: row.shift(),
      invested: row.shift(),
    }

    if (row.day_of_week && /(day|Totals|(Week \d+))$/.test(row.day_of_week)) {
      row.total_health_av =
        !isNaN(row.total_health_av) && row.total_health_av > 0
          ? convertToCurrency(parseFloat(row.total_health_av))
          : row.total_health_av
      row.total_lives_av =
        !isNaN(row.total_lives_av) && row.total_lives_av > 0
          ? convertToCurrency(parseFloat(row.total_lives_av))
          : row.total_lives_av
      row.total_points =
        !isNaN(row.total_points) && row.total_points > 0
          ? convertToCurrencyWithoutSign(parseFloat(row.total_points))
          : row.total_points
      if (row.day_of_week && /(day)$/.test(row.day_of_week))
        [
          'calls',
          'events_attended',
          'spoken_to',
          'fact_finders',
          'appts_set',
          'presentations_offered',
          'referrals_requested',
          'life_quotes',
          'total_clients',
          'total_apps',
          'total_lives',
          'total_hours_works',
        ].forEach(
          (fld) =>
            (row[fld] = convertToCurrencyWithoutSign(
              isNaN(row[fld]) || !row[fld] || row[fld] === null ? 0 : row[fld]
            ))
        )
    }

    ;['invested'].forEach((fld) => (row[fld] = Number(row[fld]).toFixed(2)))

    return row
  })

  return { rows: newRows, columns, totals }
}

const row__leaderkpiperformance = (rows, columns, callbacks) => {
  let totals = {}

  let newRows = _.cloneDeep(rows)

  newRows = newRows.map((row) => {
    if (row.length < 2) {
      return {
        agent_name: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        calls: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        events_attended: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        spoken_to: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        fact_finders: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        appts_set: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        presentations_offered: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        total_health_av: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        total_lives_av: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        total_points: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        referrals_requested: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        life_quotes: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        total_clients: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        total_apps: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        total_lives: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        total_hours_works: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        invested: (
          <div
            style={{ height: '2px', width: '100%', backgroundColor: '#333' }}
          >
            &nbsp;
          </div>
        ),
        // calls_to_fact_finders: <div style={{height:'2px',width:'100%',backgroundColor:'#333'}}>&nbsp;</div>,
        // calls_to_appts_set: <div style={{height:'2px',width:'100%',backgroundColor:'#333'}}>&nbsp;</div>,
        // calls_to_presentations_offered: <div style={{height:'2px',width:'100%',backgroundColor:'#333'}}>&nbsp;</div>,
        // calls_to_total_clients: <div style={{height:'2px',width:'100%',backgroundColor:'#333'}}>&nbsp;</div>,
        // calls_to_total_apps: <div style={{height:'2px',width:'100%',backgroundColor:'#333'}}>&nbsp;</div>,
      }
    }

    row = {
      agent_name: row.shift(),
      calls: row.shift(),
      events_attended: row.shift(),
      spoken_to: row.shift(),
      fact_finders: row.shift(),
      appts_set: row.shift(),
      presentations_offered: row.shift(),
      total_health_av: row.shift(),
      total_lives_av: row.shift(),
      total_points: row.shift(),
      referrals_requested: row.shift(),
      life_quotes: row.shift(),
      total_clients: row.shift(),
      total_apps: row.shift(),
      total_lives: row.shift(),
      total_hours_works: row.shift(),
      invested: row.shift(),
      // calls_to_fact_finders: row.shift(),
      // calls_to_appts_set: row.shift(),
      // calls_to_presentations_offered: row.shift(),
      // calls_to_total_clients: row.shift(),
      // calls_to_total_apps: row.shift(),
    }

    row.total_health_av =
      !isNaN(row.total_health_av) && row.total_health_av > 0
        ? convertToCurrency(parseFloat(row.total_health_av))
        : row.total_health_av
    row.total_lives_av =
      !isNaN(row.total_lives_av) && row.total_lives_av > 0
        ? convertToCurrency(parseFloat(row.total_lives_av))
        : row.total_lives_av
    row.total_points =
      !isNaN(row.total_points) && row.total_points > 0
        ? convertToCurrencyWithoutSign(parseFloat(row.total_points))
        : row.total_points

    if (row && row?.agent_name) {
      ;[
        'calls',
        'events_attended',
        'spoken_to',
        'fact_finders',
        'appts_set',
        'presentations_offered',
        'referrals_requested',
        'life_quotes',
        'total_clients',
        'total_apps',
        'total_lives',
        'total_hours_works',
      ].forEach(
        (fld) =>
          (row[fld] = convertToCurrencyWithoutSign(
            isNaN(row[fld]) || !row[fld] || row[fld] === null ? 0 : row[fld]
          ))
      )
    }

    ;['invested'].forEach((fld) => (row[fld] = Number(row[fld]).toFixed(2)))

    return row
  })

  return { rows: newRows, columns, totals }
}

const row__getreferralpayments = (rows, columns, totals) => {
  const makeNotes = (notes) => {
    try {
      notes = notes && JSON.parse(notes)
    } catch (ex) {
      return ''
    }

    if (Array.isArray(notes)) {
      return notes.map((note, idx) => {
        return (
          <div
            className="fs--075rem"
            key={`note-${idx}-${note.noted_at.replace(/[^A-Za-z0-9-]/g, '')}`}
          >
            <div style={{ display: 'block' }}>{note.note}</div>
            <span>
              {note.noted_by}
              {note.noted_at
                ? ` @ ${(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/.test(
                    note.noted_at
                  )
                    ? moment.utc(note.noted_at).format('h:mm a on MM/DD/YYYY')
                    : ''
                  ).toLowerCase()}`
                : ''}
            </span>
          </div>
        )
      })
    }

    return
  }

  rows = rows.filter(Boolean).map((row) => {
    const {
      av_client,
      av_date,
      paid_at,
      referral_partner,
      amt_balance,
      amt_paid,
      amt_total,
      description,
      notes,
      av_id,
    } = row

    return {
      referral_partner,
      client: av_client ? av_client : '',
      av_date: renderDateColumn(extractDateString(av_date)),
      paid_date: paid_at ? renderDateColumn(extractDateString(paid_at)) : '---',
      amt_total: isNaN(amt_total)
        ? amt_total
        : convertToCurrency(parseFloat(amt_total)),
      amt_paid:
        !isNaN(amt_paid) && amt_paid !== null
          ? convertToCurrency(parseFloat(amt_paid))
          : '---',
      amt_balance:
        !isNaN(amt_balance) && amt_balance !== null
          ? convertToCurrency(parseFloat(amt_balance))
          : '---',
      description: `${description}`.split('\n').map((d, i) => (
        <div
          className="fs--075rem"
          key={`descriptor-${i}-${d.replace(/[^A-Za-z0-9-]/g, '')}`}
        >
          {d}
        </div>
      )),
      notes: makeNotes(notes),
      edit: renderEditBtn(`/submit-sales/${av_id}/edit`, (path, event) => {
        event.preventDefault()
        goTo(path)
        return false
      }),
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        referral_partner: 'Report Totals:',
        client: '---',
        av_date: '---',
        paid_date: '---',
        amt_total: convertToCurrency(
          isNaN(totals?.amt_total) ? 0 : totals.amt_total
        ),
        amt_paid: convertToCurrency(
          isNaN(totals?.amt_paid) ? 0 : totals.amt_paid
        ),
        amt_balance: convertToCurrency(
          isNaN(totals?.amt_balance) ? 0 : totals.amt_balance
        ),
        description: '---',
        notes: '---',
        edit: '---',
      },
    }
  }

  return { rows, columns, totals }
}

const row__getreferralproductiondetail = (rows, columns, totals) => {
  rows = rows.filter(Boolean).map((row) => {
    const {
      referral_partner,
      av_date,
      av_client,
      av_state,
      av_carrier,
      av_appid,
      av_status,
      av_premium,
      av_premium_total,
      av_points,
      lives,
      av_coverage,
      av_comments,
      av_id,
      av_sub_agent,
    } = row

    return {
      referral_partner,
      date: renderDateColumn(extractDateString(av_date)),
      client: av_client ? av_client : '',
      state: av_state ? av_state : '',
      carrier: av_carrier ? av_carrier : '',
      app: av_appid ? av_appid : '',
      status: av_status ? av_status : '',
      prem: convertToCurrency(av_premium),
      av: convertToCurrency(av_premium_total),
      points: av_points ? av_points : '',
      lives: lives ? lives : '',
      cov: av_coverage ? av_coverage : '',
      comments: av_comments ? av_comments : '',
      av_sub_agent: av_sub_agent ? av_sub_agent : '',
      edit: renderEditBtn(`/submit-sales/${av_id}/edit`, (path, event) => {
        event.preventDefault()
        goTo(path)
        return false
      }),
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        referral_partner: 'Report Totals:',
        date: '---',
        client: '---',
        state: '---',
        carrier: '---',
        app: '---',
        status: '---',
        prem: convertToCurrency(
          isNaN(totals?.av_premium) ? 0 : totals.av_premium
        ),
        av: convertToCurrency(
          isNaN(totals?.av_premium_total) ? 0 : totals.av_premium_total
        ),
        points: convertToCurrencyWithoutSign(
          isNaN(totals?.av_points) ? 0 : totals.av_points
        ),
        lives: convertToCurrencyWithoutSign(
          isNaN(totals?.lives) ? 0 : totals.lives
        ),
        cov: '---',
        comments: '---',
        edit: '---',
      },
    }
  }

  return { rows, columns, totals }
}

const row__getreferralproductiontotal = (rows, columns, totals) => {
  if (totals) {
    totals.apps = 0
    totals.clients = 0
  }

  rows = rows.filter(Boolean).map((row) => {
    const {
      referral_partner,
      total_clients,
      total_apps,
      av_premium,
      av_points,
      products_sold,
      is_licensed,
      lives,
    } = row

    if (totals) {
      totals.apps += parseInt(isNaN(total_apps) ? 0 : total_apps)
      totals.clients += parseInt(isNaN(total_clients) ? 0 : total_clients)
    }

    return {
      referral_partner,
      apps: total_apps,
      clients: total_clients,
      av: convertToCurrency(isNaN(av_premium) ? 0 : av_premium),
      points: convertToCurrencyWithoutSign(isNaN(av_points) ? 0 : av_points),
      lives: convertToCurrencyWithoutSign(isNaN(lives) ? 0 : lives),
      is_licensed,
      products_sold,
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        referral_partner: 'Report Totals:',
        apps: convertToCurrencyWithoutSign(
          isNaN(totals?.apps) ? 0 : totals.apps
        ),
        clients: convertToCurrencyWithoutSign(
          isNaN(totals?.clients) ? 0 : totals.clients
        ),
        av: convertToCurrency(
          isNaN(totals?.av_premium_total) ? 0 : totals.av_premium_total
        ),
        points: convertToCurrencyWithoutSign(
          isNaN(totals?.av_points) ? 0 : totals.av_points
        ),
        lives: convertToCurrencyWithoutSign(
          isNaN(totals?.lives) ? 0 : totals.lives
        ),
        is_licensed: '---',
        products_sold: '---',
      },
    }
  }

  return { rows, columns, totals }
}

const row__getreferralpartnerw9 = (rows, columns, totals) => {
  rows = rows.filter(Boolean).map((row) => {
    const { referral_partner, recent_w9, amt_total, withholding } = row

    return {
      referral_partner,
      recent_w9: recent_w9 || '---',
      amt_total: convertToCurrency(parseFloat(amt_total)),
      withholding:
        withholding && `${withholding}`.toLowerCase() === 'yes' ? (
          <span style={{ color: 'red' }}>YES</span>
        ) : (
          'No'
        ),
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        referral_partner: 'Report Totals:',
        recent_w9: '---',
        amt_total: convertToCurrency(
          isNaN(totals?.amt_total) ? 0 : totals.amt_total
        ),
        withholding: convertToCurrency(
          isNaN(totals?.withholding) ? 0 : totals.withholding
        ),
      },
    }
  }

  return { rows, columns, totals }
}

const row__internalnsmweekly = (rows, columns, totals) => {
  rows = rows.map((row) => {
    if (row && row.hasOwnProperty('user_id')) delete row.user_id

    row.points = convertToCurrencyWithoutSign(
      parseFloat(!isNaN(row.points) && row.points !== null ? row.points : 0)
    )
    row.av = convertToCurrency(
      parseFloat(!isNaN(row.av) && row.av !== null ? row.av : 0)
    )
    row.paycheck = convertToCurrency(
      parseFloat(
        !isNaN(row.paycheck) && row.paycheck !== null ? row.paycheck : 0
      )
    )
    row.headshot = row?.headshot ? (
      <a
        href={`${row.headshot}`}
        style={{ maxHeight: '100px' }}
        target="_BLANK"
        rel="noopener noreferrer"
      >
        <img
          style={{ maxHeight: '100px' }}
          src={`${row.headshot}`}
          alt="Agent Headshot"
        />
      </a>
    ) : (
      ''
    )
    row.pip_image = row?.pip_image ? (
      <a
        href={`${row.pip_image}`}
        style={{ maxHeight: '100px' }}
        target="_BLANK"
        rel="noopener noreferrer"
      >
        <img
          style={{ maxHeight: '100px' }}
          src={`${row.pip_image}`}
          alt="PIP Milestone"
        />
      </a>
    ) : (
      ''
    )
    row.div_logo = row?.div_logo ? (
      <a
        href={`${row.div_logo}`}
        style={{ maxHeight: '100px' }}
        target="_BLANK"
        rel="noopener noreferrer"
      >
        <img
          style={{ maxHeight: '100px' }}
          src={`${row.div_logo}`}
          alt="Team Logo"
        />
      </a>
    ) : (
      ''
    )

    return {
      rank: row.rank,
      agent_name: row.agent_name,
      team: row.team,
      div_logo: row.div_logo,
      pip_level: row.pip_level,
      pip_image: row.pip_image,
      points: row.points,
      av: row.av,
      paycheck: row.paycheck,
      aca_lives: row.aca_lives,
      ma_lives: row.ma_lives,
      headshot: row.headshot,
    }
  })

  return { rows, columns, totals }
}

const row__agentledgerdetail = (rows, columns, totals) => {
  rows = rows.map((row) => {
    return {
      category: row?.category,
      type: row?.type,
      purpose: row?.purpose,
      trans_state: row?.trans_state,
      credit: row?.credit > 0 ? `$${row.credit}` : '---',
      debit:
        row?.debit > 0 ? (
          row?.trans_state === 'CANCELED' ? (
            `(${convertToCurrency(row.debit || 0)})`
          ) : (
            <span className="text-danger">{`(${convertToCurrency(
              row.debit || 0
            )})`}</span>
          )
        ) : (
          '---'
        ),
      transact_at: (row?.transact_at
        ? moment.utc(row.transact_at).format('h:mm a MM/DD/YYYY')
        : '---'
      ).toLowerCase(),
      transaction_id: row?.transaction_id,
      carrier_name: row?.carrier_name,
      fmo_name: row?.fmo_name,
      is_expire: parseInt(row?.is_expire) === 1 ? 'Yes' : 'No',
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        category: 'Report Totals:',
        type: '---',
        purpose: '---',
        trans_state: '---',
        credit: convertToCurrency(isNaN(totals?.credit) ? 0 : totals.credit),
        debit:
          totals?.debit && !isNaN(totals.debit) ? (
            <span className="text-danger">{`(${convertToCurrency(
              totals.debit
            )})`}</span>
          ) : (
            convertToCurrency(totals.debit)
          ),
        transact_at: '---',
        transaction_id: '---',
        carrier_name: '---',
        fmo_name: '---',
        is_expire: '---',
      },
    }
  }

  return { rows, columns, totals }
}

const row__individualtransactionhistory = (rows, columns, totals) => {
  rows = rows.map((row) => {
    return {
      transaction_id: row?.transaction_id,
      type: row?.type ? ucwords(camelToSpace(row.type)) : '',
      purpose: row?.purpose,
      credit: row?.credit > 0 ? `$${row.credit}` : '---',
      debit:
        row?.debit > 0 ? (
          row?.trans_state === 'CANCELED' ? (
            `(${convertToCurrency(row.debit || 0)})`
          ) : (
            <span className="text-danger">{`(${convertToCurrency(
              row.debit || 0
            )})`}</span>
          )
        ) : (
          '---'
        ),
      transact_at: (row?.transact_at
        ? moment.utc(row.transact_at).format('MM/DD/YYYY') // .format('h:mm a MM/DD/YYYY')
        : '---'
      ).toLowerCase(),
      trans_state: row?.trans_state,
      category: ucwords(row?.category ? row?.category : ''),
      carrier_name: row?.carrier_name || '---',
      fmo_name: row?.fmo_name || '---',
      doner_name: row?.doner_name || '---',
      vendor_name: row?.vendor_name || '---',
      is_expire: parseInt(row?.is_expire) === 1 ? 'Yes' : 'No',
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        transaction_id: 'Report Totals:',
        type: '---',
        purpose: '---',
        credit: convertToCurrency(isNaN(totals?.credit) ? 0 : totals.credit),
        debit:
          totals?.debit && !isNaN(totals.debit) ? (
            <span className="text-danger">{`(${convertToCurrency(
              totals.debit
            )})`}</span>
          ) : (
            convertToCurrency(totals.debit)
          ),
        transact_at: '---',
        trans_state: '---',
        category: '---',
        carrier_name: '---',
        fmo_name: '---',
        doner_name: '---',
        vendor_name: '---',
        is_expire: '---',
      },
    }
  }

  return { rows, columns, totals }
}

const row__downlinetransactionhistory = (rows, columns, totals) => {
  rows = rows.map((row) => {
    return {
      transaction_id: row?.transaction_id,
      agent_name: row?.agent_name,
      type: row?.type ? ucwords(camelToSpace(row.type)) : '',
      purpose: row?.purpose,
      credit: row?.credit > 0 ? `$${row.credit}` : '---',
      debit:
        row?.debit > 0 ? (
          row?.trans_state === 'CANCELED' ? (
            `(${convertToCurrency(row.debit || 0)})`
          ) : (
            <span className="text-danger">{`(${convertToCurrency(
              row.debit || 0
            )})`}</span>
          )
        ) : (
          '---'
        ),
      transact_at: (row?.transact_at
        ? moment.utc(row.transact_at).format('MM/DD/YYYY') // .format('h:mm a MM/DD/YYYY')
        : '---'
      ).toLowerCase(),
      trans_state: row?.trans_state,
      category: ucwords(row?.category ? row?.category : ''),
      carrier_name: row?.carrier_name || '---',
      fmo_name: row?.fmo_name || '---',
      doner_name: row?.doner_name || '---',
      vendor_name: row?.vendor_name || '---',
      is_expire: parseInt(row?.is_expire) === 1 ? 'Yes' : 'No',
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        transaction_id: 'Report Totals:',
        agent_name: '---',
        type: '---',
        purpose: '---',
        credit: convertToCurrency(isNaN(totals?.credit) ? 0 : totals.credit),
        debit:
          totals?.debit && !isNaN(totals.debit) ? (
            <span className="text-danger">{`(${convertToCurrency(
              totals.debit
            )})`}</span>
          ) : (
            convertToCurrency(totals.debit)
          ),
        transact_at: '---',
        trans_state: '---',
        category: '---',
        carrier_name: '---',
        fmo_name: '---',
        doner_name: '---',
        vendor_name: '---',
        is_expire: '---',
      },
    }
  }

  return { rows, columns, totals }
}

const row__agencytransactionhistory = (rows, columns, totals) => {
  rows = rows.map((row) => {
    return {
      agent_name: row?.agent_name,
      type_category: (row?.type ? ucwords(camelToSpace(row.type)) : '') + ' - ' + ucwords(row?.category ? row?.category : ''),
      // type: row?.type ? ucwords(camelToSpace(row.type)) : '',
      credit: row?.credit > 0 ? `$${row.credit}` : '---',
      debit:
        row?.debit > 0 ? (
          row?.trans_state === 'CANCELED' ? (
            `(${convertToCurrency(row.debit || 0)})`
          ) : (
            <span className="text-danger">{`(${convertToCurrency(
              row.debit || 0
            )})`}</span>
          )
        ) : (
          '---'
        ),
      transact_at: (row?.transact_at
        ? moment.utc(row.transact_at).format('MM/DD/YYYY') // .format('h:mm a MM/DD/YYYY')
        : '---'
      ).toLowerCase(),
      trans_state: row?.trans_state,
      // category: ucwords(row?.category ? row?.category : ''),
      // carrier_name: row?.carrier_name || '---',
      // fmo_name: row?.fmo_name || '---',
      // doner_name: row?.doner_name || '---',
      carrier_fmo_doner: row?.carrier_fmo_doner,
      vendor_name: row?.vendor_name || '---',
      expiration: row?.expiration,
      transaction_id: row?.transaction_id,
      purpose: row?.purpose,
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        agent_name: 'Report Totals:',
        type_category: '---',
        credit: convertToCurrency(isNaN(totals?.credit) ? 0 : totals.credit),
        debit:
          totals?.debit && !isNaN(totals.debit) ? (
            <span className="text-danger">{`(${convertToCurrency(
              totals.debit
            )})`}</span>
          ) : (
            convertToCurrency(totals.debit)
          ),
        transact_at: '---',
        trans_state: '---',
        // category: '---',
        // carrier_name: '---',
        // fmo_name: '---',
        // doner_name: '---',
        carrier_fmo_doner: '---',
        vendor_name: '---',
        is_expire: '---',
        transaction_id: '---',
        purpose: '---',
      },
    }
  }

  return { rows, columns, totals }
}

const row__agencyautoawardtransactionhistory = (rows, columns, totals) => {
  rows = rows.map((row) => {
    return {
      agent_name: row?.agent_name,
      transaction_id: row?.transaction_id,
      purpose: row?.purpose,
      credit: convertToCurrency(isNaN(row.credit) ? 0 : row.credit),
      transact_at: (row?.transact_at
        ? moment.utc(row.transact_at).format('MM/DD/YYYY') // .format('h:mm a MM/DD/YYYY')
        : '---'
      ).toLowerCase(),
      category: ucwords(row?.category ? row?.category : ''),
      event_log_id: row.event_log_id,
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        agent_name: 'Report Totals:',
        transaction_id: '---',
        purpose: '---',
        credit: convertToCurrency(isNaN(totals?.credit) ? 0 : totals.credit),
        transact_at: '---',
        category: '---',
        event_log_id: '---',
      },
    }
  }

  return { rows, columns, totals }
}

const row__avawardoutcome = (rows, columns, totals) => {
  rows = rows.map((row) => {
    return {
      agent_name: row?.agent_name,
      av_client: row?.av_client,
      outcome: row?.outcome,
      cumm_points: row?.cumm_points,
      award_level: row?.award_level,
      av_date: renderDateColumn(extractDateString(row?.av_date)),
      av_points: row?.av_points,
      av_appid: row?.av_appid,
      av_id: row?.av_id,
      csr_name: row?.csr_name,
      av_link: renderEditBtn(
        `/submit-sales/${row?.av_id}/edit`,
        (path, event) => {
          event.preventDefault()
          goTo(path)
          return false
        }
      ),
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        agent_name: 'Report Totals:',
        av_client: '---',
        outcome: '---',
        cumm_points: '---',
        award_level: '---',
        av_date: '---',
        av_points: '---',
        av_appid: '---',
        av_id: '---',
        csr_name: '---',
        av_link: '---',
      },
    }
  }

  return { rows, columns, totals }
}

const row__agencyledgerbalance = (rows, columns, totals) => {
  rows = rows.map((row) => {
    return {
      agent_name: row?.agent_name,
      balance:
        !isNaN(row?.balance) && row.balance < 0 ? (
          <span className="text-danger">{`(${convertToCurrency(
            row.balance || 0
          )})`}</span>
        ) : (
          `${convertToCurrency(row?.balance || 0)}`
        ),
      period_ending_balance:
        !isNaN(row?.period_ending_balance) && row.period_ending_balance < 0 ? (
          <span className="text-danger">{`(${convertToCurrency(
            row.period_ending_balance || 0
          )})`}</span>
        ) : (
          `${convertToCurrency(row?.period_ending_balance || 0)}`
        ),
      period_credits:
        !isNaN(row?.period_credits) && row.period_credits < 0 ? (
          <span className="text-danger">{`(${convertToCurrency(
            row.period_credits || 0
          )})`}</span>
        ) : (
          `${convertToCurrency(row?.period_credits || 0)}`
        ),
      period_debits:
        !isNaN(row?.period_debits) && row.period_debits < 0 ? (
          <span className="text-danger">{`(${convertToCurrency(
            row.period_debits || 0
          )})`}</span>
        ) : (
          `${convertToCurrency(row?.period_debits || 0)}`
        ),
      period_transactions: !isNaN(row?.period_transactions)
        ? `${convertToCurrencyWithoutSign(row?.period_transactions || 0)}`
        : '',
      ledger_id: row?.ledger_id,
      recent_transaction: row?.recent_transaction
        ? moment.utc(row.recent_transaction).format('MM/DD/YYYY')
        : '---',
      created_at: row?.created_at
        ? moment.utc(row.created_at).format('MM/DD/YYYY')
        : '---',
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        agent_name: 'Report Totals:',
        balance: convertToCurrency(
          totals?.balance !== null && !isNaN(totals?.balance)
            ? parseFloat(totals?.balance)
            : 0
        ),
        period_ending_balance: convertToCurrency(
          totals?.period_ending_balance !== null &&
            !isNaN(totals?.period_ending_balance)
            ? parseFloat(totals?.period_ending_balance)
            : 0
        ),
        period_credits: convertToCurrency(
          totals?.period_credits !== null && !isNaN(totals?.period_credits)
            ? parseFloat(totals?.period_credits)
            : 0
        ),
        period_debits: convertToCurrency(
          totals?.period_debits !== null && !isNaN(totals?.period_debits)
            ? parseFloat(totals?.period_debits)
            : 0
        ),
        period_transactions: convertToCurrencyWithoutSign(
          totals?.period_transactions !== null &&
            !isNaN(totals?.period_transactions)
            ? parseFloat(totals?.period_transactions)
            : 0
        ),
        ledger_id: '---',
        recent_transaction: '---',
        created_at: '---',
      },
    }
  }

  return { rows, columns, totals }
}

const row__recruitingleadsources = (rows, columns, totals) => {
  rows = rows.map((row) => {
    return {
      agent_name: row?.agent_name,
      u_enrolled: row?.u_enrolled,
      ls_name: row?.ls_name,
      upline_name: row?.upline_name,
      recruiter_name: row?.recruiter_name,
      d_name: row?.d_name,
      displayname: row?.displayname,
      has_onboarded: row?.has_onboarded,
      id: row?.id,
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        agent_name: 'Report Totals:',
        u_enrolled: '---',
        ls_name: '---',
        upline_name: '---',
        recruiter_name: '---',
        d_name: '---',
        displayname: '---',
        has_onboarded: '---',
        id: '---',
      },
    }
  }

  return { rows, columns, totals }
}

const row__individualrecruitingleadsources = (rows, columns, totals) => {
  rows = rows.map((row) => {
    return {
      agent_name: row?.agent_name,
      u_enrolled: row?.u_enrolled,
      ls_name: row?.ls_name,
      upline_name: row?.upline_name,
      d_name: row?.d_name,
      displayname: row?.displayname,
      has_onboarded: row?.has_onboarded,
      id: row?.id,
    }
  })

  if (totals) {
    return {
      rows,
      columns,
      totals: {
        agent_name: 'Report Totals:',
        u_enrolled: '---',
        ls_name: '---',
        upline_name: '---',
        d_name: '---',
        displayname: '---',
        has_onboarded: '---',
        id: '---',
      },
    }
  }

  return { rows, columns, totals }
}

const TableRowFormatter = (formatter, tableData) => {
  const { rows, columns, totals, callbacks } = tableData

  switch (formatter && `${formatter}`.trim().toLowerCase()) {
    case 'individualdetails':
      return row__individualdetails(rows, columns, totals)
    case 'individualday':
      return row__individualday(rows, columns, totals)
    case 'individualbycarrier':
      return row__bycarrier(rows, columns, totals)
    case 'individualbyagencyleadsource':
      return row__byagencyleadsource(rows, columns, totals)
    case 'individualbyagencyleadsourcecarrier':
      return row__byagencyleadsourcecarrier(rows, columns, totals)
    case 'individualproductionstatistics':
      return row__agentstatistics(rows, columns, totals)
    case 'individualproductionleadsourceaggregate':
      return row__byagencyleadsourceaggregate(rows, columns, totals)

    case 'downlinedetails':
      return row__downlinedetails(rows, columns, totals)
    case 'downlineday':
      return row__downlineday(rows, columns, totals)
    case 'downlinebycarrier':
      return row__bycarrier(rows, columns, totals)
    case 'downlinebyagencyleadsource':
      return row__byagencyleadsource(rows, columns, totals)
    case 'downlinebyagencyleadsourcecarrier':
      return row__byagencyleadsourcecarrier(rows, columns, totals)
    case 'downlineagentstatistics':
      return row__agentstatistics(rows, columns, totals)
    case 'downlinemanagerstatistics':
      return row__managerstatistics(rows, columns, totals)
    case 'downlinemarketingcommitments':
      return row__marketingcommitments(rows, columns, totals)

    case 'divisionproductiondetails':
      return row__divisionproductiondetails(rows, columns, totals)
    case 'divisionproductionday':
      return row__divisionproductionday(rows, columns, totals)
    case 'divisionproductionbycarrier':
      return row__bycarrier(rows, columns, totals, totals)
    case 'divisionproductionbyagencyleadsource':
      return row__byagencyleadsource(rows, columns, totals, totals)
    case 'divisionproductionbyagencyleadsourcecarrier':
      return row__byagencyleadsourcecarrier(rows, columns, totals, totals)
    case 'divisionagentstatistics':
      return row__agentstatistics(rows, columns, totals, totals)
    case 'divisionmanagerstatistics':
      return row__managerstatistics(rows, columns, totals, totals)
    case 'divisionproductionleadsourceaggregate':
      return row__byagencyleadsourceaggregate(rows, columns, totals)

    case 'careertotals':
      return row__careertotals(rows, columns, totals, callbacks)
    case 'districttotals':
      return row__districttotals(rows, columns, totals, callbacks)
    case 'regiontotals':
      return row__regiontotals(rows, columns, totals, callbacks)
    case 'divisiontotals':
      return row__divisiontotals(rows, columns, totals, callbacks)

    case 'agencyproductionleadsource':
      return row__byagencyleadsource(rows, columns, totals)
    case 'agencyproductionleadsourcecarrier':
      return row__byagencyleadsourcecarrier(rows, columns, totals)
    case 'agencyproductionbycarrier':
      return row__bycarrier(rows, columns, totals)
    case 'agencyproductionleadsourceaggregate':
      return row__byagencyleadsourceaggregate(rows, columns, totals)

    case 'downlinecontracting':
      return row__downlinecontracting(rows, columns, callbacks)

    case 'getreferralproductiondetail':
      return row__getreferralproductiondetail(rows, columns, totals)
    case 'getreferralproductiontotal':
      return row__getreferralproductiontotal(rows, columns, totals)
    case 'getreferralpayments':
      return row__getreferralpayments(rows, columns, totals)
    case 'getreferralpartnerw9':
      return row__getreferralpartnerw9(rows, columns, totals)

    case 'agentkpiperformance':
      return row__agentkpiperformance(rows, columns)
    case 'leaderkpiperformance':
      return row__leaderkpiperformance(rows, columns, callbacks)

    case 'internalnsmweekly':
      return row__internalnsmweekly(rows, columns)

    case 'agentledgerdetail':
      return row__agentledgerdetail(rows, columns, totals)
    case 'individualtransactionhistory':
      return row__individualtransactionhistory(rows, columns, totals)
    case 'downlinetransactionhistory':
      return row__downlinetransactionhistory(rows, columns, totals)
    case 'agencytransactionhistory':
      return row__agencytransactionhistory(rows, columns, totals)
    case 'agencytransactionhistoryalltime':
      return row__agencytransactionhistory(rows, columns, totals)
    case 'agencyledgerbalance':
      return row__agencyledgerbalance(rows, columns, totals)
    case 'agencyledgerbalancealltime':
      return row__agencyledgerbalance(rows, columns, totals)
    case 'agencyautoawardtransactionhistory':
      return row__agencyautoawardtransactionhistory(rows, columns, totals)

    case 'avawardoutcome':
      return row__avawardoutcome(rows, columns, totals)

    case 'recruitingleadsources':
      return row__recruitingleadsources(rows, columns, totals)
    case 'individualrecruitingleadsources':
      return row__individualrecruitingleadsources(rows, columns, totals)
    case 'agencyrecruitingleadsources':
      return row__recruitingleadsources(rows, columns, totals)

    default:
      break
  }
}

export default TableRowFormatter
