import React, { useEffect, useState } from 'react'
import { MDBIcon } from 'mdbreact'
import NumberFormat from 'react-number-format'
import { reportCardFields } from '../../variables'

class TableCellSplitter extends React.Component {
  render() {
    return <li className="lgr-cell cell-splitter"></li>
  }
}

class ColumnSplitter extends React.Component {
  render() {
    return <li className="lgr-cell cell-splitter"></li>
  }
}

class ColumnHeader extends React.Component {
  render() {
    return (
      <li
        className={`lgr-cell text--center ${
          isNaN(this.props?.index) ? '' : 'cell-idx-' + this.props.index
        } ${this.props?.isLast === true ? 'is-last' : ''
        } ${this.props.index === 0 ? 'sticky-element-first' : ''
        } ${this.props.index === 1 ? 'sticky-element-second' : ''}`}
      >
        {this.props?.label ? (
          <div className="fs--1125rem fw--500">
            {this.props.label.charAt(0).toUpperCase()}
            {this.props.label.substr(1)}
          </div>
        ) : (
          <></>
        )}
      </li>
    )
  }
}

class ColumnDescriptor extends React.Component {
  render() {
    return (
      <li
        className={`lgr-cell text--center ${
          isNaN(this.props?.index) ? '' : 'cell-idx-' + this.props.index
        } ${this.props?.isLast === true ? 'is-last' : ''
        } ${this.props.index === 0 ? 'sticky-element-first' : ''
        } ${this.props.index === 1 ? 'sticky-element-second' : ''}`}
      >
        {this.props?.descriptor ? (
          <div className="fs--075rem">
            {this.props.descriptor.charAt(0).toUpperCase()}
            {this.props.descriptor.substr(1)}
          </div>
        ) : (
          <></>
        )}
      </li>
    )
  }
}

class ColumnSorter {
  _column = null
  _direction = null

  callback = () => {}

  setColumn = (column) => (this._column = column)

  getColumn = () => this._column

  setDirection = (direction) => (this._direction = direction)

  getDirection = () => this._direction

  getClass = (column) => {
    if (column === this._column) {
      if (this._direction) return `sorted-${this._direction.toLowerCase()}`
    }

    return ''
  }

  onChange = (column, direction) => {
    direction = direction ? `${direction}`.trim().toUpperCase() : ''

    if (
      column &&
      direction &&
      column === this.getColumn() &&
      direction === this.getDirection()
    ) {
      this.setColumn(null)
      this.setDirection(null)
    } else {
      this.setColumn(column)
      this.setDirection(direction)
    }

    this.callback(this)
  }
}

const SortIndicator = ({ Sorter, column }) => {
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    if(Sorter && column ) setIsRendered(true);
  }, [Sorter, column])

  return (
    <>
      {
        isRendered && (
          <div className="SortIndicatorComponent">
            <ul className={Sorter.getClass(column)}>
              <li 
                onClick={() => {
                  Sorter.onChange(column, 'ASC');
                }} 
                className="up"
              >
                <MDBIcon icon="angle-up" />
              </li>
              <li onClick={() => Sorter.onChange(column, 'DESC')} className="down">
                <MDBIcon icon="angle-down" />
              </li>
            </ul>
          </div>
        )
      }
    </>
  );
};

const ColumnTimespan = ({ Sorter, index, isLast, timespan }) => {
  return (
    <li
      className={`${index === 0 ? 'lgr-cell-dark' : ''} lgr-cell text--center ${
        isNaN(index) ? '' : 'cell-idx-' + index
      } ${isLast === true ? 'is-last' : ''} ${
        Sorter?.Sorter && Sorter?.column ? 'has-sorter ' : ''
      }  ${index === 0 ? 'sticky-element-first' : ''} ${index === 1 ? 'sticky-element-second' : ''}`}
    >
      {timespan ? (
        <div className="fs--075rem fw--500">
          {timespan.replace(/\/20/g, '/')}
        </div>
      ) : null}
      <SortIndicator Sorter={Sorter?.Sorter} column={Sorter?.column} />
    </li>
  );
};

class ColumnKeyIndicator extends React.Component {
  render() {
    return (
      <li className="lgr-cell lgr-key-indicator">
        {this.props?.tKey ? (
          <div>
            <div>{this.props?.tKey}</div>
          </div>
        ) : (
          <></>
        )}
      </li>
    )
  }
}

class ColumnCell extends React.Component {
  render() {
    return (
      <li
        className={`lgr-cell text--center ${
          isNaN(this.props?.index) ? '' : 'cell-idx-' + this.props.index
        } ${this.props?.isLast === true ? 'is-last' : ''}`}
      >
        {this.props?.data ? (
          <div className="fs--1rem fw--400">{this.props.data}</div>
        ) : (
          <></>
        )}
      </li>
    )
  }
}

class StandardCell extends React.Component {
  get isClickable() {
    return typeof this.props?.onClick === 'function'
  }
  render() {
    return (
      <li
        className={`lgr-row-cell lgr-cell ${
          this.isClickable ? 'lgr-cell-clickable' : ''
        } standard-cell text--${this.props.alignment || 'left'} ${
          isNaN(this.props?.index) ? '' : 'cell-idx-' + this.props.index
        } ${this.props?.isLast === true ? 'is-last' : ''
        } ${this.props.index === 0 ? 'sticky-element-first' : ''
        } ${this.props.index === 1 ? 'sticky-element-second bg-white' : ''}`}
        onClick={() => {
          if (typeof this.props?.onClick === 'function')
            this.props.onClick(this.props)
        }}
      >
        {!isNaN(this.props?.data) ? (
          <NumberFormat
            value={
              !isNaN(this.props?.toFixed)
                ? isNaN(this.props?.data)
                  ? this.props?.data
                  : parseFloat(this.props?.data).toFixed(this.props?.toFixed)
                : this.props?.data
            }
            className="lgr-cell-input"
            disabled={true}
            thousandSeparator={true}
            prefix={this.props?.prefix || ''}
            suffix={this.props?.suffix || ''}
          />
        ) : (
          <>{this.props?.data}</>
        )}
      </li>
    )
  }
}

class GradedCell extends React.Component {
  // Exceeding Goals	>= 25%	Green
  // Under-performing Goals	%5 - 25%	Yellow
  // Failing Goals	< 25%	Red
  constructor(props) {
    super(props)
    // Initialize highLimit and lowLimit here
    this.state = {
      highLimit: 0,
      lowLimit: 0,
    }
  }

  componentDidMount() {
    // Initialize highLimit and lowLimit based on props.field
    switch (this.props?.field) {
      case reportCardFields.totalPoints:
      case reportCardFields.enrolledAgents:
      case reportCardFields.signatureAgents:
      case reportCardFields.activeAgents:
      case reportCardFields.writingAgents:
        this.setState({
          highLimit: 15,
          lowLimit: 0,
        })
        break
      case reportCardFields.sigAgentsCompleted:
        this.setState({
          highLimit: 75,
          lowLimit: 65,
        })
        break
      case reportCardFields.sigAgentsConversion:
        this.setState({
          highLimit: 33,
          lowLimit: 25,
        })
        break
      default:
        this.setState({
          highLimit: 15,
          lowLimit: 0,
        })
        break
    }
  }

  render() {
    const exceeds = parseFloat(this.props?.data) === 9999,
      indicationClass =
        isNaN(this.props?.data) || exceeds
          ? false
          : this.props?.data > this.state.highLimit
          ? 'success'
          : this.props?.data >= this.state.lowLimit
          ? 'warning'
          : 'danger'
    return (
      <li
        className={`lgr-row-cell lgr-cell graded-cell text--${
          this.props.alignment || 'left'
        } ${parseInt(this.props?.index) === 0 ? 'is-first' : ''} ${
          isNaN(this.props?.index) ? '' : 'cell-idx-' + this.props.index
        } ${this.props?.isLast === true ? 'is-last' : ''}`}
      >
        <span className="grade-indicator">
          <MDBIcon
            icon="circle"
            className={
              indicationClass === false ? 'hidden' : `icon-${indicationClass}`
            }
          />
        </span>
        {exceeds ? (
          <small>Not Enough Data</small>
        ) : (
          <NumberFormat
            value={
              !isNaN(this.props?.toFixed)
                ? isNaN(this.props?.data)
                  ? this.props?.data
                  : parseFloat(this.props?.data).toFixed(this.props?.toFixed)
                : this.props?.data
            }
            className="lgr-cell-input"
            disabled={true}
            thousandSeparator={true}
            prefix={this.props?.prefix || ''}
            suffix={this.props?.suffix || ''}
          />
        )}
      </li>
    )
  }
}

export {
  TableCellSplitter,
  ColumnSplitter,
  ColumnHeader,
  ColumnDescriptor,
  ColumnTimespan,
  ColumnKeyIndicator,
  ColumnCell,
  StandardCell,
  GradedCell,
  ColumnSorter,
}
