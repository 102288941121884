import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import UserService from './../../../../shared/services/User.service'
import moment from 'moment'
import {
  UIInput,
  UIDatePickerInput,
  UIDropdown,
} from './../../../../components/forms/form-fields'
import ReferralPartnerInput from './../ReferralPartnerInput/ReferralPartnerInput.component'
import ReferralAdmin from '../ReferralAdmin/ReferralAdmin.component'
import SalesTrackerStore from './../../store'
import LoadingSpinner from './../../../../components/shared/LoadingSpinner.component'
import { MDBCollapse, MDBCol } from 'mdbreact'
import appConstants from './../../../../constants/appConstants'
import { toast } from 'react-toastify'
import preventInputUnicodeCharacters from '../../../../shared/utilities/preventInputUnicodeCharacters.function'

import './ClientForm.component.scss'

const MAX_DOB = moment().toDate()

const StatesList = appConstants.States.map((t) => ({
  text: t.name,
  value: t.id,
}))

const toDate = (dateStr) => {
  if (dateStr) {
    if (typeof dateStr === 'string')
      return moment(`${dateStr}`.split('T').shift()).toDate()
    if (typeof dateStr === 'object') return dateStr
  }
}

const ClientForm = ({ AOR }) => {
  const policyAvDate = SalesTrackerStore.Policy?.get('av_date'),
    policyAvEffDate = SalesTrackerStore.Policy?.get('av_eff_date'),
    // isPreviousPlicy = SalesTrackerStore.isPreviousPolicy,
    // previousPolicyHasEffDate = SalesTrackerStore.previousPolicyHasEffDate,
    [avDate, setAvDate] = useState(null),
    [avEffDate, setAvEffDate] = useState(null),
    [refPayment, setRefPayment] = useState({
      amt_balance: null,
      amt_paid: null,
      amt_total: null,
    })
  const isEditPage = window.location.href?.includes('edit')
  const getLeadSource = (lId) => {
    lId = lId ? lId : SalesTrackerStore.Policy.get('leadsource_id')
    return lId
      ? SalesTrackerStore.LeadSources.filter(
          (LeadSource) => parseInt(LeadSource.id()) === parseInt(lId)
        ).shift()
      : undefined
  }

  const getSellingAgent = async (aId) => {
    let A = Array.isArray(AOR.sellingAgentList)
      ? AOR.sellingAgentList
          .filter((u) => parseInt(u.value) === parseInt(aId))
          .shift()
      : null
    if (A) return A

    let agentDetails = await UserService.getUserDetails(aId)
    if (!agentDetails) return

    return [
      {
        text: [agentDetails.u_fname, agentDetails.u_lname].join(' ').trim(),
        value: aId,
      },
    ]
  }

  const getAvSellingAgent = async (aId) => {
    try {
      return (await getSellingAgent(aId)).shift().text
    } catch (ex) {
      return undefined
    }
  }

  const setSellingAgentId = async (value) => {
    if (!value) {
      SalesTrackerStore.AOR.sellingAgentId = null
      SalesTrackerStore.AOR.avSellingAgent = ''
      return
    }

    SalesTrackerStore.AOR.sellingAgentId = value
    SalesTrackerStore.AOR.avSellingAgent = await getAvSellingAgent(value)
  }

  const setReferralPartner = (id, name) => {
    if (
      id &&
      parseInt(id) !== parseInt(SalesTrackerStore.Policy.get('r_partner_id'))
    )
      SalesTrackerStore.Policy.set('r_partner_id', parseInt(id))
    else if (!id && SalesTrackerStore.Policy.get('r_partner_id'))
      SalesTrackerStore.Policy.set('r_partner_id', null)
  }

  const onAvDateChange = (value) => {
    if (value) {
      let valueStr = JSON.parse(JSON.stringify(value)).split('T').shift()
      if (valueStr !== SalesTrackerStore.Policy.get('av_date')) {
        SalesTrackerStore.Policy.set('av_date', valueStr)
        setAvDate(value)
      }
    }
  }
  // const onEvEffDateChange = (value) => {
  //   if (value) {
  //     let valueStr = JSON.parse(JSON.stringify(value)).split('T').shift()
  //     if (valueStr !== SalesTrackerStore.Policy.get('av_eff_date')) {
  //       SalesTrackerStore.Policy.set('av_eff_date', valueStr)
  //       setAvEffDate(value)
  //     }
  //   }
  // }

  const leadsource_id = SalesTrackerStore.Policy.get('leadsource_id'),
    leadSourcesList = SalesTrackerStore.LeadSources.map((LeadSource) => ({
      text: LeadSource.get('ls_name'),
      value: `${LeadSource.id()}`,
      checked: !!(
        leadsource_id && parseInt(leadsource_id) === parseInt(LeadSource.id())
      ),
    }))

  const showToastForIllegalCharacters = (field) => {
    toast.warn(
      `You tried to paste invalid text in ${field}, but we’ve attempted to correct it. Please review for accuracy.`, 
      { position: toast.POSITION.TOP_RIGHT, autoClose: 7000 }
    )
  }

  useEffect(() => {
    if (avDate === null && policyAvDate) setAvDate(toDate(policyAvDate))
    else if (avDate && !policyAvDate) setAvDate(null)
    else {
      const a =
          avDate && typeof avDate === 'object'
            ? moment(avDate).format('YYYY-MM-DD')
            : (avDate ? `${avDate}` : '').split('T').shift(),
        p =
          policyAvDate && typeof policyAvDate === 'object'
            ? moment(policyAvDate).format('YYYY-MM-DD')
            : (policyAvDate ? `${policyAvDate}` : '').split('T').shift()
      if (
        a &&
        p &&
        /\d{4}-\d{2}-\d{2}/.test(a) &&
        /\d{4}-\d{2}-\d{2}/.test(p) &&
        a !== p
      )
        setAvDate(toDate(policyAvDate))
    }

    if (avEffDate === null && policyAvEffDate)
      setAvEffDate(toDate(policyAvEffDate))
    else if (avEffDate && !policyAvEffDate) setAvEffDate(null)
    else {
      const a =
          avEffDate && typeof avEffDate === 'object'
            ? moment(avEffDate).format('YYYY-MM-DD')
            : (avEffDate ? `${avEffDate}` : '').split('T').shift(),
        p =
          policyAvEffDate && typeof policyAvEffDate === 'object'
            ? moment(policyAvEffDate).format('YYYY-MM-DD')
            : (policyAvEffDate ? `${policyAvEffDate}` : '').split('T').shift()
      if (
        a &&
        p &&
        /\d{4}-\d{2}-\d{2}/.test(a) &&
        /\d{4}-\d{2}-\d{2}/.test(p) &&
        a !== p
      )
        setAvEffDate(toDate(policyAvEffDate))
    }
  }, [avDate, policyAvDate, policyAvEffDate, avEffDate])
  return (
    <div id="ClientForm">
      <MDBCollapse isOpen={!!SalesTrackerStore.isFetching}>
        <LoadingSpinner size="md" isActive={true} />
      </MDBCollapse>
      <MDBCollapse isOpen={!SalesTrackerStore.isFetching}>
        <div name="avClientForm" className="mb-5" autoComplete="off">
          <div className="form-row">
            <MDBCol size="12">
              <h6 className="font---lato">Client Details</h6>
            </MDBCol>
          </div>
          <div className="form-row">
            <MDBCol size="12" lg="6" className="av-input-wrapper">
              <UIInput
                label="Client Name"
                name="av_client"
                type="text"
                onChange={(evt) => {
                  const filteredResult = preventInputUnicodeCharacters(evt.target.value)
                  if (filteredResult.hasIllegalCharacters) showToastForIllegalCharacters('Client Name')

                  if (
                    filteredResult.fixedString !==
                    SalesTrackerStore.Policy.get('av_client')
                  )
                    SalesTrackerStore.Policy.set('av_client', filteredResult.fixedString)
                }}
                autoComplete="off"
                required={true}
                value={SalesTrackerStore.Policy.get('av_client')}
                rules={{ required: true, minLength: 2, maxLength: 255 }}
                showValidity={SalesTrackerStore.showErrors}
                onValidityChange={(validity) =>
                  SalesTrackerStore.setValidity(
                    0,
                    'av_client',
                    validity.isValid
                  )
                }
              />
            </MDBCol>
            <MDBCol size="12" lg="6" className="av-input-wrapper">
              <UIInput
                label="Client Email"
                name="av_client_email"
                type="text"
                onChange={(evt) => {
                  const filteredResult = preventInputUnicodeCharacters(evt.target.value)
                  if (filteredResult.hasIllegalCharacters) showToastForIllegalCharacters('Client Email')
                  if (
                    filteredResult.fixedString !==
                    SalesTrackerStore.Policy.get('av_client_email')
                  )
                    SalesTrackerStore.Policy.set(
                      'av_client_email',
                      filteredResult.fixedString
                    )
                }}
                autoComplete="off"
                value={SalesTrackerStore.Policy.get('av_client_email')}
                rules={{ minLength: 2, maxLength: 255 }}
                showValidity={SalesTrackerStore.showErrors}
                onValidityChange={(validity) =>
                  SalesTrackerStore.setValidity(
                    0,
                    'av_client_email',
                    validity.isValid
                  )
                }
              />
            </MDBCol>
          </div>
          <div className="form-row">
            <MDBCol size="12" lg="6" className="av-input-wrapper">
              <UIInput
                label="Client Phone"
                name="av_client_phone"
                type="text"
                onChange={(evt) => {
                  const filteredResult = preventInputUnicodeCharacters(evt.target.value)
                  if (filteredResult.hasIllegalCharacters) showToastForIllegalCharacters('Client Phone')
                  if (
                    filteredResult.fixedString !==
                    SalesTrackerStore.Policy.get('av_client_phone')
                  )
                    SalesTrackerStore.Policy.set(
                      'av_client_phone',
                      filteredResult.fixedString
                    )
                }}
                autoComplete="off"
                value={SalesTrackerStore.Policy.get('av_client_phone')}
                rules={{ minLength: 2, maxLength: 255 }}
                showValidity={SalesTrackerStore.showErrors}
                onValidityChange={(validity) =>
                  SalesTrackerStore.setValidity(
                    0,
                    'av_client_phone',
                    validity.isValid
                  )
                }
              />
            </MDBCol>
            <MDBCol size="12" lg="6" className="av-input-wrapper">
              <UIDropdown
                label="Client State"
                name="av_state"
                options={StatesList.map((s) => ({
                  ...s,
                  checked:
                    `${SalesTrackerStore.Policy.get('av_state')}`
                      .trim()
                      .toLowerCase() === `${s.value}`.trim().toLowerCase(),
                }))}
                onChange={(evt) => {
                  if (
                    parseInt(evt.target.value) !==
                    parseInt(SalesTrackerStore.Policy.get('av_state'))
                  )
                    SalesTrackerStore.Policy.set('av_state', evt.target.value)
                }}
                required={true}
                value={SalesTrackerStore.Policy.get('av_state')}
                rules={{ required: true }}
                showValidity={SalesTrackerStore.showErrors}
                onValidityChange={(validity) =>
                  SalesTrackerStore.setValidity(0, 'av_state', validity.isValid)
                }
                search
              />
            </MDBCol>
          </div>
          <div className="form-row">
            <MDBCol size="12">
              <br />
              <h6 className="font---lato">Policy Details</h6>
            </MDBCol>
          </div>
          <div className="form-row">
            <MDBCol size="12" lg="6" className="av-input-wrapper">
              <UIDatePickerInput
                label={
                  isEditPage
                    ? 'Date Submitted To Carrier'
                    : 'Enter Date To Carrier'
                }
                name="av_date"
                id="av_date"
                onChange={(evt) => onAvDateChange(evt.target.value)}
                required={true}
                showYearDropdown
                yearDropdownItemNumber={5}
                scrollableYearDropdown
                dateFormat="MM/dd/yyyy"
                maxDate={MAX_DOB}
                selected={avDate}
                value={
                  avDate && typeof avDate === 'object'
                    ? moment(avDate).format('MM/DD/YYYY')
                    : ''
                }
                rules={{ required: true }}
                showValidity={SalesTrackerStore.showErrors}
                onValidityChange={(validity) =>
                  SalesTrackerStore.setValidity(0, 'av_date', validity.isValid)
                }
              />
            </MDBCol>
            {/* <MDBCol size="12" lg="6" className="av-input-wrapper">
              <UIDatePickerInput
                label={'Effective Date'}
                name="av_eff_date"
                id="av_eff_date"
                onChange={(evt) => onEvEffDateChange(evt.target.value)}
                required={
                  isPreviousPlicy && !previousPolicyHasEffDate ? false : true
                }
                showYearDropdown
                yearDropdownItemNumber={5}
                scrollableYearDropdown
                dateFormat="MM/dd/yyyy"
                selected={avEffDate}
                value={
                  avEffDate && typeof avEffDate === 'object'
                    ? moment(avEffDate).format('MM/DD/YYYY')
                    : null
                }
                rules={{
                  required:
                    isPreviousPlicy && !previousPolicyHasEffDate ? false : true,
                }}
                showValidity={SalesTrackerStore.showErrors}
                onValidityChange={(validity) =>
                  isPreviousPlicy && !previousPolicyHasEffDate
                    ? SalesTrackerStore.setValidity(
                        0,
                        'av_eff_date',
                        validity.isValid
                      )
                    : null
                }
              />
              {(isPreviousPlicy && !previousPolicyHasEffDate
                ? true
                : false) && (
                <MDBIcon
                  className="clear-icon"
                  icon="times"
                  onClick={() => {
                    SalesTrackerStore.Policy.set('av_eff_date', null)
                    setAvEffDate(null)
                  }}
                />
              )}
            </MDBCol> */}
            <MDBCol size="12" lg="6" className="av-input-wrapper">
              <UIDropdown
                label="Lead Source"
                name="leadsource_id"
                options={leadSourcesList}
                onChange={(evt) => {
                  let value = evt.target.value
                  if (
                    value &&
                    value !==
                      parseInt(SalesTrackerStore.Policy.get('leadsource_id'))
                  ) {
                    SalesTrackerStore.Policy.set('leadsource_id', value)
                    const LeadSource = getLeadSource()
                    if (LeadSource)
                      SalesTrackerStore.Policy.set(
                        'av_leadsource',
                        LeadSource.get('ls_name')
                      )
                  }
                }}
                required={true}
                value={SalesTrackerStore.Policy.get('leadsource_id')}
                rules={{ required: true }}
                showValidity={SalesTrackerStore.showErrors}
                onValidityChange={(validity) =>
                  SalesTrackerStore.setValidity(
                    0,
                    'leadsource_id',
                    validity.isValid
                  )
                }
                search
              />
            </MDBCol>
            <MDBCol size="12" lg="6" className="av-input-wrapper">
              <UIDropdown
                label="Agent of Record"
                name="selling_agent_id"
                options={(SalesTrackerStore.AOR.sellingAgentList || []).map(
                  (s) => ({
                    ...s,
                    checked:
                      parseInt(s.value) ===
                      parseInt(SalesTrackerStore.AOR.sellingAgentId),
                  })
                )}
                onChange={(evt) => {
                  let value = evt.target.value
                  if (
                    value &&
                    value !== parseInt(SalesTrackerStore.AOR.sellingAgentId)
                  )
                    setSellingAgentId(value)
                }}
                value={SalesTrackerStore.AOR.selling_agent_id}
                showValidity={SalesTrackerStore.showErrors}
                onValidityChange={(validity) =>
                  SalesTrackerStore.setValidity(
                    0,
                    'selling_agent_id',
                    validity.isValid
                  )
                }
                search
              />
            </MDBCol>
            <MDBCol size="12" lg="6">
              <ReferralPartnerInput
                agent_id={SalesTrackerStore.AOR.sellingAgentId}
                referral_partner_id={SalesTrackerStore.Policy.get(
                  'r_partner_id'
                )}
                set_referral_partner={setReferralPartner}
                disabled={refPayment.amt_paid > 0 || refPayment.amt_total > 0}
              />
              {SalesTrackerStore.Policy.get('r_partner_id') ? (
                <table
                  width="100%"
                  style={{
                    border: '1px solid #eaeaea',
                    borderRadius: '0.3rem',
                    marginBottom: '0.5rem',
                  }}
                  cellPadding="2"
                >
                  <tbody>
                    <tr>
                      <td
                        valign="middle"
                        className="text--left fs--1rem fw--500"
                      >
                        Referral Payment:
                      </td>
                      <td valign="middle">
                        <table>
                          <tbody>
                            <tr>
                              <td className="text--right fs--075rem fw--500">
                                Total:
                              </td>
                              <td>
                                {refPayment.amt_total !== null &&
                                !isNaN(refPayment.amt_total) &&
                                refPayment.amt_total >= 0
                                  ? `$${parseFloat(
                                      refPayment.amt_total
                                    ).toFixed(2)}`
                                  : '--'}
                              </td>
                            </tr>
                            <tr>
                              <td className="text--right fs--075rem fw--500">
                                Paid:
                              </td>
                              <td>
                                {refPayment.amt_paid !== null &&
                                !isNaN(refPayment.amt_paid) &&
                                refPayment.amt_paid >= 0
                                  ? `$${parseFloat(refPayment.amt_paid).toFixed(
                                      2
                                    )}`
                                  : '--'}
                              </td>
                            </tr>
                            <tr>
                              <td className="text--right fs--075rem fw--500">
                                Balance:
                              </td>
                              <td>
                                {refPayment.amt_balance !== null &&
                                !isNaN(refPayment.amt_balance) &&
                                refPayment.amt_balance >= 0
                                  ? `$${parseFloat(
                                      refPayment.amt_balance
                                    ).toFixed(2)}`
                                  : '--'}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <></>
              )}
            </MDBCol>
          </div>
          <div className="form-row">
            <MDBCol size="12">
              <ReferralAdmin
                avId={SalesTrackerStore.Policy.id()}
                referralPartnerId={SalesTrackerStore.Policy.get('r_partner_id')}
                onPaymentChange={(payment) => {
                  if (payment && typeof payment === 'object') {
                    if (JSON.stringify(refPayment) !== JSON.stringify(payment))
                      setRefPayment(payment)
                  }
                }}
              />
            </MDBCol>
          </div>
        </div>
      </MDBCollapse>
    </div>
  )
}

export default observer(ClientForm)
