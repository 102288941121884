import { observer } from 'mobx-react-lite'
import { makeAutoObservable } from 'mobx'
import React from 'react'
import UserProfileService from './../../../../shared/services/UserProfile.service'
import UserService from './../../../../shared/services/User.service'
import {
  UIInput,
  UIInputButton,
} from './../../../../components/forms/form-fields'
import UserMetaService from '../../../../shared/services/UserMeta.service'
import {
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
} from 'mdbreact'

import './BeastModeAccountLink.component.scss'

class AcctStore {
  constructor() {
    makeAutoObservable(this)
  }

  isFetching = false
  isSaving = false
  UserMeta = null
  recruiter = ''
  sales = ''

  fetch = async () => {
    this.isFetching = true

    let UserMeta
    try {
      UserMeta = await UserMetaService.search({
        search: {
          meta_key: '10x---location-id',
          user_id: UserProfileService.getUserId(),
        },
      })
      UserMeta = ((UserMeta && UserMeta?.models) || []).shift()
    } catch (ex) {
      console.log(`Failed to fetch user meta for 10x location ID. ${ex}`)
    }

    let value = {}
    try {
      value = JSON.parse(UserMeta && UserMeta?.meta_value)
    } catch (ex) {}

    this.UserMeta = UserMeta || false
    this.recruiter = value?.recruiter || ''
    this.sales = value?.sales || ''

    this.isFetching = false

    return UserMeta
  }

  save = async () => {
    this.isSaving = true

    if (this.UserMeta) {
      await UserMetaService.update(this.UserMeta.id, {
        meta_value: JSON.stringify({
          recruiter: this.recruiter,
          sales: this.sales,
        }),
      })
      this.isSaving = false
      return
    }

    let UserMeta = await this.fetch()
    if (UserMeta) {
      await UserMetaService.update(UserMeta.id, {
        meta_value: JSON.stringify({
          recruiter: this.recruiter,
          sales: this.sales,
        }),
      })
      this.isSaving = false
      return
    }

    UserMeta = {
      user_id: UserProfileService.getUserId(),
      meta_key: '10x---location-id',
      meta_value: JSON.stringify({
        recruiter: this.recruiter,
        sales: this.sales,
      }),
    }
    let result = await UserMetaService.store(UserMeta)
    if (result && result?.id) {
      UserMeta.id = result?.id
      this.UserMeta = UserMeta
    }

    this.isSaving = false
  }
}

const Store = new AcctStore()

const BeastModeAccountLink = ({ onReset }) => {
  const meetsRequirement =
    UserProfileService.isAssumed() &&
    (UserProfileService.isA('internal-admin', true) ||
      UserProfileService.isA('system-admin', true) ||
      UserProfileService.isA('agency-owner', true))

  if (meetsRequirement) {
    if (Store.UserMeta === null) {
      Store.UserMeta = false
      Store.fetch()
    }
  }

  const renderCard = () => {
    if (!meetsRequirement) return <></>

    const RecruiterIDInput = (
        <UIInput
          name="recruiter_id"
          label="Recruiter CRM"
          value={Store.recruiter}
          onChange={(evt) => (Store.recruiter = evt.target.value)}
        />
      ),
      SalesIDInput = (
        <UIInput
          name="sales_id"
          label="Sales CRM"
          value={Store.sales}
          onChange={(evt) => (Store.sales = evt.target.value)}
        />
      )

    const SaveBtn = (
      <MDBBtn
        disabled={Store.isSaving}
        onClick={() => Store.save()}
        className="save-btn"
      >
        {Store.isSaving ? (
          <i className="fa fa-spin fa-spinner"></i>
        ) : (
          <MDBIcon icon="save" />
        )}
      </MDBBtn>
    )

    return (
      <MDBCard id="BeastModeAccountLinkComponent">
        <MDBCardHeader>BeastMode 10x CRM Account</MDBCardHeader>
        <MDBCardBody>
          <MDBRow>
            <MDBCol size="12" className="mt-1 mb-1">
              <UIInputButton
                input={RecruiterIDInput}
                button={SaveBtn}
                btnPosition={'right'}
              />
              {Store.recruiter ? (
                <p>
                  <a
                    href={`https://10x.bmagencybuilders.com/v2/location/${Store.recruiter}`}
                    target="_BLANK"
                    rel="noopener noreferrer"
                  >
                    Open {UserProfileService.get('u_fname')}{' '}
                    {UserProfileService.get('u_lname')}'s Recruiter BeastMode
                    10x CRM
                  </a>
                </p>
              ) : (
                <></>
              )}
            </MDBCol>
            <MDBCol size="12">
              <hr />
            </MDBCol>
            <MDBCol size="12" className="mt-1 mb-1">
              <UIInputButton
                input={SalesIDInput}
                button={SaveBtn}
                btnPosition={'right'}
              />
              {Store.sales ? (
                <p>
                  <a
                    href={`https://10x.bmagencybuilders.com/v2/location/${Store.sales}`}
                    target="_BLANK"
                    rel="noopener noreferrer"
                  >
                    Open {UserProfileService.get('u_fname')}{' '}
                    {UserProfileService.get('u_lname')}'s BeastMode Sales 10x
                    CRM
                  </a>
                </p>
              ) : (
                <></>
              )}
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    )
  }

  return <>{renderCard()}</>
}

export default observer(BeastModeAccountLink)
