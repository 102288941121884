import React from 'react'
import env from './../../../../environments/environment.prod'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import StateLicenseMap from './../StateLicenseMap/StateLicenseMap.component'
import moment from 'moment'

import './StateLicenses.component.scss'

const StateLicenses = ({ subsiteData, licenses }) => {
  let name = [
    subsiteData?.u_nickname ? subsiteData?.u_nickname : subsiteData?.u_fname,
    subsiteData?.u_lname,
  ]
    .map((n) => (n ? `${n}`.trim() : ''))
    .filter((n) => n)
    .join(' ')
    .trim()
  if (subsiteData?.id && parseInt(subsiteData.id) === 1289)
    name = 'Richard Banville'

  return (
    <MDBContainer id="StateLicensesComponent" fluid>
      <MDBRow>
        <MDBCol size="12">
          <h3 className="text-spacing--25 font--lato">My State Licenses</h3>
        </MDBCol>
      </MDBRow>
      <StateLicenseMap licenses={licenses} legend={true} clickable={true} />
      <MDBRow>
        <MDBCol size="12">
          <table className="license-table">
            <thead className="font--lato">
              <tr>
                <th colSpan="4">{name ? name + "'s " : ''}State Licenses</th>
              </tr>
              <tr>
                <th>State</th>
                <th>License Number</th>
                <th>Start Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {licenses && Array.isArray(licenses) ? (
                licenses.map((license, idx) => (
                  <tr
                    className="fw--500"
                    key={`agent-st-license-${idx}-${license.id}`}
                  >
                    <td>{license.state_abrev}</td>
                    <td>{license.license_num}</td>
                    <td>
                      {license.effective_date
                        ? moment(
                            `${license.effective_date}`.split('T').shift()
                          ).format('MM/DD/YYYY')
                        : null}
                    </td>
                    <td>
                      {license?.license_img ? (
                        <a
                          href={`https://firebasestorage.googleapis.com/v0/b/${
                            env.integrations.firebase.config.storageBucket
                          }/o/${encodeURIComponent(
                            license.license_img
                          )}?alt=media`}
                          rel="noopener noreferrer"
                          target="_BLANK"
                        >
                          View License
                        </a>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default StateLicenses
