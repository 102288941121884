import React from 'react'
import { observer } from 'mobx-react-lite'
import env from './../../../environments/environment'
import ContentHtml from '../../../components/content/ContentHtml/ContentHtml.component'
import { createCummulative } from './../../../shared/utilities/createCummulative.functions'
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact'

import AWARD_LEVELS from './../../../constants/award-levels.constants'

const AwardLevelsShowcase = () => {
  return (
    <MDBCard
      narrow
      id="AwardLevelsShowcaseComponent"
      className="points-component"
    >
      <MDBCardBody>
        <MDBCardTitle>Awards Levels & Info</MDBCardTitle>
        <MDBRow>
          <MDBCol size="12">
            <ContentHtml contentId="27" />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          {AWARD_LEVELS.map((AwardLevel, idx) => {
            return (
              <MDBCol
                className="pip-level"
                size="12"
                sm="6"
                md="4"
                lg="3"
                key={`usabg-awarded-level-${AwardLevel.level}-${idx}`}
              >
                <div>
                  <img
                    className="img-fluid"
                    alt={AwardLevel.name}
                    style={{
                      width: '100px',
                      height: '100px',
                      objectFit: 'contain',
                    }}
                    src={`https://firebasestorage.googleapis.com/v0/b/${env.integrations.firebase.config.storageBucket}/o/static%2Faward-levels%2Flevel.${AwardLevel.level}.png?alt=media`}
                  />
                  <hr style={{ marginBottom: 0 }} />
                  <table className="point-legend ">
                    <tbody>
                      <tr>
                        <td
                          colSpan="2"
                          className="text--center fs--125rem fw--bold"
                        >
                          {AwardLevel.name}
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td className="text--left fw--bold">Points:</td>
                        <td className="text--right fw--bold">
                          {`${AwardLevel.min}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ','
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="text--left fw--bold">BUCK$:</td>
                        <td className="text--right fw--bold">
                          {`${AwardLevel.credits}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ','
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="text--left fw--bold">Cumulative:</td>
                        <td className="text--right fw--bold">
                          {`${createCummulative(AwardLevel)}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ','
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </MDBCol>
            )
          })}
        </MDBRow>
      </MDBCardBody>
    </MDBCard>
  )
}

export default observer(AwardLevelsShowcase)
