import React from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { toast } from 'react-toastify'
import SalesTrackerStore from './../../store'
import { MDBCollapse, MDBBtn, MDBCol, MDBAlert } from 'mdbreact'

import './PolicySubmitter.component.scss'
import SubmitSalesService from '../../shared/SubmitSales.service'

const PolicySubmitter = ({ goTo }) => {
  const onSubmit = async () => {
    let result = null
    try {
      result = await SalesTrackerStore.submit()
      SubmitSalesService.fetchRecentSales()
    } catch (ex) {}

    if (!result) {
      if (SalesTrackerStore.errMsgs?.length) {
        SalesTrackerStore.errMsgs.forEach((errMsg) =>
          toast.error(errMsg, { position: toast.POSITION.TOP_RIGHT })
        )
        SalesTrackerStore.errMsg = toJS(SalesTrackerStore.errMsgs).join(
          `<br />`
        )
      } else if (SalesTrackerStore.errMsg)
        return toast.error(SalesTrackerStore.errMsg, {
          position: toast.POSITION.TOP_RIGHT,
        })
      return
    } else if (result) {
      return toast.success('Great. Policy saved!', {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  return (
    <div id="PolicySubmitterComponent">
      <div className="row">
        <MDBCol size="12">
          <MDBCollapse isOpen={!!SalesTrackerStore.errMsgs?.length}>
            <MDBAlert color={'danger'}>
              <ul className="policy-errors-list">
                {SalesTrackerStore.errMsgs?.length &&
                  SalesTrackerStore.errMsgs.map((err, idx) => (
                    <li key={`sales-tracker-policy-err-${idx}`}>{err}</li>
                  ))}
              </ul>
            </MDBAlert>
          </MDBCollapse>
        </MDBCol>
      </div>
      <div className="form-row">
        <MDBCol size="12" lg={!SalesTrackerStore.Policy.isNew() ? '6' : '12'}>
          <MDBBtn
            disabled={
              SalesTrackerStore.isSubmitting ||
              SalesTrackerStore.isDeleting ||
              SalesTrackerStore.isFetching
            }
            color="indigo"
            type="button"
            className="btn-block mb-1"
            onClick={() => {
              onSubmit()
            }}
          >
            {SalesTrackerStore.isSubmitting ? 'Submitting...' : 'Submit Sale'}
          </MDBBtn>
        </MDBCol>
        <MDBCol size="12" lg="6">
          {!SalesTrackerStore.Policy.isNew() && (
            <MDBBtn
              disabled={
                SalesTrackerStore.isSubmitting ||
                SalesTrackerStore.isDeleting ||
                SalesTrackerStore.isFetching
              }
              color="info"
              type="button"
              className="btn-block mb-1"
              onClick={() => goTo()}
            >
              Reset Sale Form
            </MDBBtn>
          )}
        </MDBCol>
      </div>
    </div>
  )
}

export default observer(PolicySubmitter)
