import env from './../../environments/environment'
import axios from 'axios'
import AuthService from './../../shared/services/Auth.service'
import ActivityStore from './../../shared/stores/activity.store'

const baseUrl = env.api.base_url
const querystring = require('querystring')

async function refreshToken() {
  return new Promise(async (resolve, reject) => {
    try {
      await AuthService.refreshToken()
      resolve()
    } catch (ex) {
      if (ex === 'NO_USER') window.location.href = '/login'
      else reject(`${ex}`)
    }
  })
}

function getInstance() {
  ActivityStore.recordRequest()
  return axios.create({
    accept: ' application/json',
    baseURL: baseUrl,
    timeout: 60000,
    headers: { Authorization: `Bearer ${AuthService.getToken() || ''}` },
  })
}

function getMultipartInstance() {
  ActivityStore.recordRequest()
  return axios.create({
    accept: 'multipart/form-data',
    baseURL: baseUrl,
    timeout: 60000,
    headers: { Authorization: `Bearer ${AuthService.getToken() || ''}` },
  })
}

const getHomepageData = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getPages')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getPages')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const fetchUserDetailsAuth = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/userDetails')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/userDetails')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getQuoteRequest = async (isOpened) => {
  let instance = getInstance()
  try {
    return await instance.get(
      isOpened
        ? `/getRequests/quote_requests/Open`
        : `/getRequests/quote_requests/Closed`
    )
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get(
        isOpened
          ? `/getRequests/quote_requests/Open`
          : `/getRequests/quote_requests/Closed`
      )
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createRecruitLeadCapture = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('createRecruitLeadCapture', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('createRecruitLeadCapture', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createBusinessLeadCapture = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('createBusinessLeadCapture', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('createBusinessLeadCapture', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createQuoteRequest = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('createQuoteRequest', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('createQuoteRequest', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateQuoteRequest = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteQuoteRequest = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getContactRequest = async (isOpened) => {
  let instance = getInstance()
  try {
    return await instance.get(
      isOpened
        ? `/getRequests/contact_requests/Open`
        : `/getRequests/contact_requests/Closed`
    )
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get(
        isOpened
          ? `/getRequests/contact_requests/Open`
          : `/getRequests/contact_requests/Closed`
      )
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createContactRequest = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('createContactRequest', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('createContactRequest', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateContactRequest = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteContactRequest = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getContentRequest = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllDataFromTable/agency_content')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllDataFromTable/agency_content')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getContentById = async (id) => {
  let instance = getInstance()
  if (!id || typeof id === 'undefined')
    throw new Error({
      message:
        'Invalid ID passed to fetch content in AdminApi.getContentById()',
    })

  try {
    return await instance.get('/getContentById/agency_content/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/agency_content/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteContentById = async (id) => {
  let instance = getInstance()
  if (!id || typeof id === 'undefined')
    throw new Error({
      message:
        'Invalid ID passed to fetch content in AdminApi.deleteContentById()',
    })

  try {
    return await instance.delete('/deleteContentById/agency_content/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.delete('/deleteContentById/agency_content/' + id)
    }
    throw new Error({
      message: 'Error occurred while attempting to delete agency content!',
    })
  }
}

const createPage = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const uploadImage = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/uploadImage', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/uploadImage', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updatePageById = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deletePage = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getLeadSources = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllDataFromTable/leadsource')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllDataFromTable/leadsource')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getLeadSourcesSortByName = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllDataFromTable/leadsource/ls_name')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllDataFromTable/leadsource/ls_name')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getLeadSourceById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getContentById/leadsource/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/leadsource/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createLeadSource = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateLeadSource = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteLeadSource = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getCarrierContests = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllDataFromTable/carrier_contests')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllDataFromTable/carrier_contests')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getCarrierContestById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getContentById/carrier_contests/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/carrier_contests/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createCarrierContest = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/createCarrierContest', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/createCarrierContest', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteContest = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getLeadPrograms = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllDataFromTable/lead_programs')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllDataFromTable/lead_programs')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getLeadProgramById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getContentById/lead_programs/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/lead_programs/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createLeadProgram = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/createLeadProgram', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/createLeadProgram', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteProgram = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getEvents = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllDataFromTable/events')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllDataFromTable/events')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getEventById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getContentById/events/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/events/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createEvent = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/createEvent', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/createEvent', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteEvent = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getCommissions = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllDataFromTable/commission_levels')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllDataFromTable/commission_levels')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getCommissionById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getContentById/commission_levels/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/commission_levels/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createCommission = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/createCommissionLevel', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/createCommissionLevel', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateCommission = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/createCommissionLevel', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/createCommissionLevel', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteCommission = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getLibraryResourcesType = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getLibraryResources/resource_types/rt_name')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getLibraryResources/resource_types/rt_name')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getResourcesType = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllDataFromTable/resource_types')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllDataFromTable/resource_types')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getResourcesTypeById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getContentById/resource_types/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/resource_types/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createResourceType = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteResourceType = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getResources = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getResources')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getResources')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getResourceById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getContentById/resources/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/resources/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createResource = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateResource = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteResource = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getTerms = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllDataFromTable/terms')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllDataFromTable/terms')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getTermsById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getContentById/terms/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/terms/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createTerms = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateTerms = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteTerms = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getSignatures = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getTermSignature/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getTermSignature/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteSignature = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteUserTermSignature', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteUserTermSignature', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getTrainings = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllTrainings')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllTrainings')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createTraining = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateTraining = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteTraining = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createTrainingType = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getNotificationEmails = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllDataFromTable/emails')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllDataFromTable/emails')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getNotificationEmailById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getContentById/emails/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/emails/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateNotificationEmail = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const sendTestEmail = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/sendTestEmail', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/sendTestEmail', data)
    }

    if (e && e.response && e.response.status === 500) {
      throw new Error(e.response.data.message);
    }
    throw new Error('Something went wrong!')
  }
}

const getDivisions = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getDivisionsList')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getDivisionsList')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getDivisionById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getDivisionById/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getDivisionById/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createDivision = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/createDivision', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/createDivision', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateDivision = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/createDivision', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/createDivision', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteDivision = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getDivisionOwners = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllDivisionOwners')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllDivisionOwners')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getCarriers = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllDataFromTable/carriers')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllDataFromTable/carriers')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getAgencyInfo = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getContentById/agency/1')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/agency/1')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateAgencyInfo = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/updateAgencyInfo', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/updateAgencyInfo', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getUserTypes = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllDataFromTable/usertypes/name')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllDataFromTable/usertypes/name')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getUserTypeById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getContentById/usertypes/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/usertypes/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createUserType = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

// admin action to update usertype definition.
const updateUserType = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteUserType = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createUser = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/createUser', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/createUser', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getAllUplineUsers = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllUplineUsers')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllUplineUsers')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getCalendars = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllDataFromTable/calendars')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllDataFromTable/calendars')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getCalendarById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getContentById/calendars/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/calendars/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getCalendarByUsertypeId = async (ids) => {
  let instance = getInstance()
  try {
    return await instance.get(
      '/getUsertypeCalendars/' + (Array.isArray(ids) ? ids.join(',') : ids)
    )
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get(
        '/getUsertypeCalendars/' + (Array.isArray(ids) ? ids.join(',') : ids)
      )
    }
    throw new Error({
      message:
        'ERROR!  An exception was thrown while attempting to fetch calendars by usertype ID.',
    })
  }
}

const getCalendarByDivisionId = async (ids) => {
  let instance = getInstance()
  try {
    return await instance.get(
      '/getDivisionCalendars/' + (Array.isArray(ids) ? ids.join(',') : ids)
    )
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get(
        '/getDivisionCalendars/' + (Array.isArray(ids) ? ids.join(',') : ids)
      )
    }
    throw new Error({
      message:
        'ERROR!  An exception was thrown while attempting to fetch calendars by division ID.',
    })
  }
}

const createCalendar = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateCalendar = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteCalendar = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getContactDetails = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAgencyInfo')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAgencyInfo')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getCoverages = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllDataFromTable/coverages')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllDataFromTable/coverages')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getCoveragesSortByName = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllDataFromTable/coverages/coverage_name')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllDataFromTable/coverages/coverage_name')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getCoverageById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getContentById/coverages/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/coverages/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createCoverage = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateCoverage = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteCoverage = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getCarriersList = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getCarriersList')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getCarriersList')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getCarrierById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getCarrierById/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getCarrierById/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createCarrier = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/createCarrier', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/createCarrier', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateCarrier = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/createCarrier', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/createCarrier', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteCarrier = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getProductsList = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getProductsList')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getProductsList')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getProductById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getProductById/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getProductById/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createProduct = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/createProduct', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/createProduct', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateProduct = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/createProduct', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/createProduct', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteProduct = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getPointsList = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getPointsList')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getPointsList')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getPointById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getContentById/points/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/points/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createPoint = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updatePoint = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deletePoint = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getUserDataById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getContentById/users/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/users/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getUserDataByULogin = async (u_login) => {
  let instance = getInstance()
  try {
    return await instance.get('/getUserDataByUlogin/users/' + u_login)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getUserDataByUlogin/users/' + u_login)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getUserWebsiteDetailsByUlogin = async (u_login) => {
  let instance = getInstance()
  try {
    return await instance.get('/getUserWebsiteDetailsByUlogin/' + u_login)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getUserWebsiteDetailsByUlogin/' + u_login)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const enrollUser = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/enrollUser', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/enrollUser', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const checkEmailExist = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/isEmailExist', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/isEmailExist', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getUsersList = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllDataFromTable/users/u_fname')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllDataFromTable/users')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getContractsListById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getUserContracting/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getUserContracting/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateContract = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/updateUserContracting', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/updateUserContracting', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getProfileData = async (id) => {
  let instance = getInstance()
  if (id) {
    try {
      return await instance.get('/getUserProfile/' + id)
    } catch (e) {
      if (e && e.response && e.response.status === 403) {
        await refreshToken()
        instance = getInstance()
        return await instance.get('/getUserProfile/' + id)
      }
      throw new Error({ message: 'Something went wrong!' })
    }
  } else {
    try {
      return await instance.get('/getUserProfile')
    } catch (e) {
      if (e && e.response && e.response.status === 403) {
        await refreshToken()
        instance = getInstance()
        return await instance.get('/getUserProfile')
      }
      throw new Error({ message: 'Something went wrong!' })
    }
  }
}

const updateProfile = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/editUserProfile', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/editUserProfile', data)
    }
    let message = e.response?.data?.msg
      ? e.response.data.msg
      : 'Something went wrong!'
    throw new Error({ message })
  }
}

const getLicensesList = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAgentAllLicenses')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAgentAllLicenses')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getLicenseById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getContentById/licenses/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/licenses/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createLicense = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/createLicense', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/createLicense', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateLicense = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/createLicense', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/createLicense', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteLicense = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getAgentRecentFiveSales = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAgentRecentSales')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAgentRecentSales')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getCarriersByCoverage = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getCarrierByCoverage/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getCarrierByCoverage/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getProductsByCarrier = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getProductsByCarrier/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getProductsByCarrier/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const submitSales = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/submitAv', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/submitAv', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getAgentSalesById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getAgentSaleDetails/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAgentSaleDetails/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getAllAvSales = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllAvSales')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllAvSales')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const addContract = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/addUserCarrier', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/addUserCarrier', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getAgentContract = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getUserCarrierDetail/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getUserCarrierDetail/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateAgentContract = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/updateUserCarrierDetail', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/updateUserCarrierDetail', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getProspects = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAgentsProspectsList')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAgentsProspectsList')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getProspectById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getContentById/prospects/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/prospects/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createProspect = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateProspect = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteProspects = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getProspectByStatus = async (status) => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllProspectsListByStatus/' + status)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllProspectsListByStatus/' + status)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getProspectsNotes = async (data) => {
  let instance = getInstance()
  try {
    return await instance.get('/getProspectsNotesWithEmail', { params: data })
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getProspectsNotesWithEmail', { params: data })
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getManagerResources = async (status) => {
  let instance = getInstance()
  try {
    return await instance.get('/getManagerResources')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getManagerResources')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getAdminResources = async (status) => {
  let instance = getInstance()
  try {
    return await instance.get('/getAdminResources')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAdminResources')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const uploadContentImage = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/uploadContentImage', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/uploadContentImage', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getUploadedContentImages = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getAllDataFromTable/content_images')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getAllDataFromTable/content_images')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const isValidProspects = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/isValidProspects', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/isValidProspects', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const isProspectExist = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/isProspectExist', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/isProspectExist', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getManagersUserTypesList = async (data) => {
  let instance = getInstance()
  const url = `/agentListUsertypeIdName`,
    query = [
      (data && typeof data === 'object' ? data.pending_days : null)
        ? `pending_days=${data.pending_days}`
        : '',
    ]
      .filter((n) => !!n)
      .join('&')

  try {
    return await instance.get(`${url}?${query}`)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get(`${url}?${query}`)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getManagerAgentsListAccUserType = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/agentListAccUsertype', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/agentListAccUsertype', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

// in use
const updateAgentsUsertype = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/updateAgentsUsertype', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/updateAgentsUsertype', data)
    }
    throw new Error({
      message: e?.response?.data?.message || 'Failed to update usertype!',
    })
  }
}

const getAgentDownlineProductionDetails = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/agentDownlineProductionDetails', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/agentDownlineProductionDetails', data)
    }
    throw new Error({
      message:
        'ERROR: An exception was thrown while attempting to fetch agent downline production details.',
    })
  }
}

const getLimitedLeadersByUplineId = async (userId) => {
  let instance = getInstance()
  try {
    return await instance.get(`/getLimitedLeadersByUplineId/${userId}`)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get(`/getLimitedLeadersByUplineId/${userId}`)
    }
    throw new Error({
      message:
        'ERROR: An exception was thrown while attempting to fetch agent downline leaders.',
    })
  }
}

const getReportData = async (
  category,
  report,
  start,
  stop,
  coverages,
  data
) => {
  if (
    (start && typeof start === 'object') ||
    (stop && typeof start === 'object')
  )
    throw new Error({
      message:
        'Start & stop dates can not be date object.  Please update date parameters and try again. ',
    })

  coverages =
    coverages && typeof coverages === 'string'
      ? coverages.split(/(-|,)/g)
      : coverages
  coverages =
    coverages && Array.isArray(coverages)
      ? coverages.filter((c) => c && `${c}`.trim().length > 2)
      : null

  if (
    data?.order_by &&
    typeof data.order_by === 'object' &&
    Object.keys(data.order_by).length === 1
  ) {
    data.order_by_col = Object.keys(data.order_by).shift()
    data.order_by_dir = Object.values(data.order_by).shift()
    delete data.order_by
  }

  data = data ? querystring.stringify(data) : null

  const url = `/reporting/${category}/${report}/${start}/${stop}`,
    query = [
      coverages
        ? coverages
            .map((c, i) => `coverages[${i}]=${encodeURIComponent(c)}`)
            .join('&')
        : null,
    ]
      .concat([data])
      .filter((n) => !!n)
      .join('&')

  let instance = getInstance()
  try {
    return await instance.get(`${url}?${query}`)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get(`${url}?${query}`)
    }
    throw new Error({
      message: 'Failed to fetch report data data from API. ',
      data: { ex: e },
    })
  }
}

const getLeaderboardData = async (
  type,
  start,
  stop,
  coverages,
  agentType,
  outputMode
) => {
  if (
    (start && typeof start === 'object') ||
    (stop && typeof start === 'object')
  )
    throw new Error({
      message:
        'Start & stop dates can not be date object.  Please update date parameters and try again. ',
    })

  coverages =
    coverages && typeof coverages === 'string'
      ? coverages.split(',')
      : coverages
  coverages =
    coverages && Array.isArray(coverages)
      ? coverages.filter((n) => n && `${coverages}`.trim().length)
      : null

  const url = `/leaderboards/${type}/${start}/${stop}`,
    query = [
      coverages
        ? coverages
            .map((c, i) => `coverages[${i}]=${encodeURIComponent(c)}`)
            .join('&')
        : null,
      `agent_type=${agentType}`,
      `output_mode=${outputMode}`,
    ]
      .filter((n) => !!n)
      .join('&')

  let instance = getInstance()
  try {
    return await instance.get(`${url}?${query}`)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get(`${url}?${query}`)
    }
    throw new Error({
      message: 'Failed to fetch leader board data from API. ',
      data: { ex: e },
    })
  }
}

const deleteUser = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteUser', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteUser', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getDivisionPoints = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/divisionPoints', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/divisionPoints', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getRegionPoints = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/regionPoints', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/regionPoints', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getQuoteContactRequest = async (table_name) => {
  let instance = getInstance()
  try {
    return await instance.get('/quoteContactRequest/' + table_name)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/quoteContactRequest/' + table_name)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteQuoteContactRequest = async (table_name, id) => {
  let instance = getInstance()
  try {
    return await instance.get(`/deleteQuoteContactRequest/${table_name}/${id}`)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get(
        `/deleteQuoteContactRequest/${table_name}/${id}`
      )
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const createUpdateMenuLinks = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/createUpdateLink', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/createUpdateLink', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateWebsiteInfo = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/updateWebsiteInfo', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/updateWebsiteInfo', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getUserSiteDetails = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/userSiteDetails')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/userSiteDetails')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getProductLinksList = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/productsList')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/productsList')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateProductLink = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/updateproductLink', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/updateproductLink', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const showProductLinks = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/showProductsLink', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/showProductsLink', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const showProductsByCoverage = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/agentsProductsListByCoverageWebsiteArea/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get(
        '/agentsProductsListByCoverageWebsiteArea/' + id
      )
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const showAvailableProductsByCoverage = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/agentsAvailableProductsListByCoverage/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/agentsAvailableProductsListByCoverage/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const addToMyProductsWebsiteArea = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/agentsAddProductWebsiteArea', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/agentsAddProductWebsiteArea', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getProductLinkDetails = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/productLinkDetailWebsiteArea/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/productLinkDetailWebsiteArea/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateProductLinkDetails = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/agentsUpdateProductLinkWebsiteArea', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/agentsUpdateProductLinkWebsiteArea', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getResourcesByResourceTypeId = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getResourcesByResourceTypeId/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getResourcesByResourceTypeId/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getResourcesByResourceTypeIds = async (ids) => {
  ids = Array.isArray(ids) ? ids : `${ids}`.split(',')
  ids = ids
    .map((i, x) => {
      return `id[${x}]=${i}`
    })
    .join('&')
  let instance = getInstance()
  try {
    return await instance.get('/getResourcesByResourceTypeIds/?' + ids)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getResourcesByResourceTypeIds/?' + ids)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getUsersFromStateLicense = async (str) => {
  let instance = getInstance()
  try {
    return await instance.get('/stateLicenseSearch/' + str)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/stateLicenseSearch/' + str)
    }
    throw new Error({
      message:
        'Something went wrong while attempting to search state licenses!',
    })
  }
}

const getUsersFromSpokenLanguage = async (str) => {
  let instance = getInstance()
  try {
    return await instance.get('/spokenLanguageSearch/' + str)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/spokenLanguageSearch/' + str)
    }
    throw new Error({
      message:
        'Something went wrong while attempting to search spoken language!',
    })
  }
}

const getUsersFromSearchTerm = async (str) => {
  let instance = getInstance()
  try {
    return await instance.get('/userSearch/' + str)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/userSearch/' + str)
    }
    throw new Error({
      message: 'Something went wrong while attempting to search users!',
    })
  }
}

const getUsersFromSearch = async (str) => {}

const createAgentsContactQuoteRequest = async (data, table_name) => {
  let instance = getInstance()
  try {
    return await instance.post('/contactQuoteRequest/' + table_name, data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/contactQuoteRequest/' + table_name, data)
    }
    throw new Error({ message: 'Error! Failed to send email request.' })
  }
}

const downgradeToAffiliate = async ({ userId, why_downgrade, what_better }) => {
  let instance = getInstance()
  try {
    return await instance.get(
      `/downgradeToAffiliate?user_id=${userId}&why_downgrade=${why_downgrade}&what_better=${what_better}`
    )
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get(
        `/downgradeToAffiliate?user_id=${userId}&why_downgrade=${why_downgrade}&what_better=${what_better}`
      )
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getUsertypeWithUsersNumber = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/usertypeWithUsersNumber')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/usertypeWithUsersNumber')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getAgentsListByUsertype = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/agentsListByUsertype/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/agentsListByUsertype/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const updateMessageBoard = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/updateMessageBoard', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/updateMessageBoard', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getMessageBoardAlert = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getMessageBoardAlert')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getMessageBoardAlert')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getMessageBoardAlertDataAdmin = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getMessageBoardAlertDataAdmin')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getMessageBoardAlertDataAdmin')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getCommissionLevelList = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getCommissionLevelList')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getCommissionLevelList')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const fetchFactorMilestonesAdminReport = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/factorsPointsAdminReports', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/factorsPointsAdminReports', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const userTypeCSV = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/exportCsvUserTypes')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/exportCsvUserTypes')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const csvUsersByUserType = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/csvUsersByUserType/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/csvUsersByUserType/' + id)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const csvUsersList = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/csvUsersList')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/csvUsersList')
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteSubmitAv = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteSubmitAv', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteSubmitAv', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const deleteAgentsProductWebsiteArea = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/agentsDeleteProductWebsiteArea', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/agentsDeleteProductWebsiteArea', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const stripeCreateCustomer = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/stripeCreateCustomer', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/stripeCreateCustomer', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const stripeCreateSubscription = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/stripeCreateSubscription', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/stripeCreateSubscription', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const stripeSaveCard = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/stripeSaveCard', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/stripeSaveCard', data)
    }
    throw new Error({ message: 'Could not save card' })
  }
}

const stripeSetInitialDefaultPayMethod = async (paymentMethodId) => {
  let instance = getInstance()
  try {
    return await instance.post(
      '/stripeSetInitialDefaultPayMethod/' + paymentMethodId
    )
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post(
        '/stripeSetInitialDefaultPayMethod/' + paymentMethodId
      )
    }
    throw new Error({ message: 'Could not set the initial payment method.' })
  }
}

const getTechToolsList = async () => {
  let instance = getInstance()
  try {
    return await instance.get('/getTools')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getTools')
    }
    throw new Error({ message: 'Unable to fetch tools list!' })
  }
}

const getTechToolsById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getToolById/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getToolById/' + id)
    }
    throw new Error({ message: 'Unable to get tools data!' })
  }
}

const createUpdateTechTools = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/createUpdateTool', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/createUpdateTool', data)
    }
    throw new Error({ message: "Couldn't create tool" })
  }
}

const deleteTechTools = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteToolById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteToolById', data)
    }
    throw new Error({ message: 'Unable to delete tool' })
  }
}

const fetchTermSignatureHistoryByAgent = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getTermSignatureHistoryByAgent/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getTermSignatureHistoryByAgent/' + id)
    }
    throw new Error({ message: 'Unable to get term history data!' })
  }
}

const exportCsvReport = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/exportCsvReport', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/exportCsvReport', data)
    }
    throw new Error({ message: "Couldn't export CSV" })
  }
}

const getUserMetas = async (userId, metaKeys) => {
  metaKeys = (Array.isArray(metaKeys) ? metaKeys : [metaKeys]).filter((m) => m)
  userId = userId && parseInt(userId) > 0 ? parseInt(userId) : null

  if (!metaKeys && !userId)
    throw new Error({
      message:
        'Invalid user_id and/or meta_keys supplied in getUserMetas request.',
    })

  let instance = getInstance(),
    queryParams = [
      userId ? `user_id=${userId}` : '',
      userId ? `meta_keys=${metaKeys.map((k) => k.trim()).join(',')}` : '',
    ],
    url = '/getUserMetas/?' + queryParams.join('&')

  try {
    return await instance.get(url)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get(url)
    }

    throw new Error({
      message:
        'ERROR!  An exception was thrown while attempting to fetch user meta records by user ID and/or metaKeys.',
    })
  }
}

const upsertUserMeta = async (userId, metaKey, metaValue) => {
  userId = userId && parseInt(userId) > 0 ? parseInt(userId) : null

  if (!metaKey || !userId)
    throw new Error({
      message:
        'Invalid user_id and/or meta_keys supplied in upsertUserMeta request.',
    })

  let instance = getInstance(),
    data = { meta_key: metaKey, meta_value: metaValue },
    url = '/upsertUserMeta/' + userId

  try {
    return await instance.post(url, data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post(url, data)
    }
    throw new Error({
      message:
        'ERROR!  An exception was thrown while attempting to upsert user meta records by user ID and/or metaKeys.',
    })
  }
}

const getPermissions = async (id, source = 'all') => {
  id = id && parseInt(id, 10) > 0 ? parseInt(id, 10) : null
  source =
    source &&
    ['all', 'user', 'usertype'].indexOf(`${source}`.trim().toLowerCase()) > -1
      ? `${source}`.trim().toLowerCase()
      : 'all'

  if (!source || !id)
    throw new Error({
      message:
        'Invalid id and/or source arguments supplied in getPermissions request.',
    })

  let instance = getInstance(),
    url =
      '/getPermissions/' +
      [source === 'all' ? null : source, id].filter((a) => !!a).join('/')
  try {
    return await instance.get(url)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get(url)
    }
    throw new Error({
      message:
        'ERROR!  An exception was thrown while attempting to fetch permissions records by user or usertype ID.',
    })
  }
}

const searchReferrers = async (query, userId) => {
  userId = userId && parseInt(userId, 10) > 0 ? parseInt(userId, 10) : null

  if (!userId)
    throw new Error({
      message: 'Invalid user_id supplied in searchReferrers request.',
    })

  query = query ? encodeURIComponent(query) : null

  let instance = getInstance(),
    url = '/referrers/?agent_id=' + userId + (query ? '&query=' + query : '')
  try {
    return await instance.get(url)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get(url)
    }
    throw new Error({
      message:
        'ERROR!  An exception was thrown while attempting to search referrer records with query & user_id.',
    })
  }
}

const createReferrer = async (referralData) => {
  let agentId = null,
    rpName = null,
    rpCompany = null,
    rpEmail = null,
    rpPhone = null,
    rpIsLicensed = null,
    rpProductsSold = null

  if (!referralData || typeof referralData !== 'object')
    throw new Error({
      message: 'Invalid referral data object passed into createReferrer().',
    })

  Object.keys(referralData)
    .filter((key) => referralData.hasOwnProperty(key) && referralData[key])
    .forEach((key) => {
      switch (key) {
        case 'agent_id':
          agentId = parseInt(referralData[key])
          break
        case 'name':
          rpName = `${referralData[key]}`.trim()
          break
        case 'company':
          rpCompany = `${referralData[key]}`.trim()
          break
        case 'email':
          rpEmail = `${referralData[key]}`.trim()
          break
        case 'phone':
          rpPhone = `${referralData[key]}`.trim()
          break
        case 'is_licensed':
          rpIsLicensed = `${referralData[key]}`.trim()
          break
        case 'products_sold':
          rpProductsSold = `${referralData[key]}`.trim()
          break
        default:
          break
      }
    })
  agentId = agentId && parseInt(agentId, 10) > 0 ? parseInt(agentId, 10) : null

  if (!agentId || !rpName)
    throw new Error({
      message:
        'Invalid referral arguments supplied to create new referral.  Missing: ' +
        [agentId ? null : 'agent_id', rpName ? null : 'name'].filter((f) => f),
    })

  let instance = getInstance(),
    url = '/referrers'
  referralData = {
    agent_id: agentId,
    name: rpName,
    company: rpCompany,
    phone: rpPhone,
    email: rpEmail,
    is_licensed: rpIsLicensed,
    products_sold: rpProductsSold,
  }

  try {
    return await instance.post(url, referralData)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post(url, referralData)
    }
    throw new Error({
      message:
        'ERROR!  An exception was thrown while attempting to create a new referrer record.',
    })
  }
}

const updateReferrer = async (referralId, referralData, agentId) => {
  let rpName = null,
    rpCompany = null,
    rpEmail = null,
    rpPhone = null,
    rpIsLicensed = null,
    rpProductsSold = null

  if (!referralData || typeof referralData !== 'object')
    throw new Error({
      message: 'Invalid referral data object passed into createReferrer().',
    })

  Object.keys(referralData)
    .filter((key) => referralData.hasOwnProperty(key) && referralData[key])
    .forEach((key) => {
      switch (key) {
        case 'name':
          rpName = `${referralData[key]}`.trim()
          break
        case 'company':
          rpCompany = `${referralData[key]}`.trim()
          break
        case 'email':
          rpEmail = `${referralData[key]}`.trim()
          break
        case 'phone':
          rpPhone = `${referralData[key]}`.trim()
          break
        case 'is_licensed':
          rpIsLicensed = `${referralData[key]}`.trim()
          break
        case 'products_sold':
          rpProductsSold = `${referralData[key]}`.trim()
          break
        default:
          break
      }
    })
  agentId = agentId && parseInt(agentId, 10) > 0 ? parseInt(agentId, 10) : null

  if (!referralId || !agentId || !rpName)
    throw new Error({
      message:
        'Invalid referral arguments supplied to update existing referral.  Missing: ' +
        [
          agentId ? null : 'agent_id',
          rpName ? null : 'name',
          referralId ? null : 'referral_id',
        ].filter((f) => f),
    })

  let instance = getInstance(),
    url = '/referrers/' + referralId
  referralData = {
    agent_id: agentId,
    name: rpName,
    company: rpCompany,
    phone: rpPhone,
    email: rpEmail,
    is_licensed: rpIsLicensed,
    products_sold: rpProductsSold,
  }

  try {
    return await instance.put(url, referralData)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.put(url, referralData)
    }

    throw new Error({
      message:
        'ERROR!  An exception was thrown while attempting to update an existing referrer record.',
    })
  }
}

const getReferrer = async (referralId, userId) => {
  referralId =
    referralId && parseInt(referralId, 10) > 0 ? parseInt(referralId, 10) : null
  userId = userId && parseInt(userId, 10) > 0 ? parseInt(userId, 10) : null

  if (!userId || !referralId)
    throw new Error({
      message: 'Invalid user_id supplied in getReferrer request.',
    })

  let instance = getInstance(),
    url = '/referrers/' + referralId + '?agent_id=' + userId
  try {
    return await instance.get(url)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get(url)
    }

    throw new Error({
      message:
        'ERROR!  An exception was thrown while attempting to get referrer record by ID.',
    })
  }
}

const generateCsvMailingList = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/generateCsvMailingList', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/generateCsvMailingList', data)
    }
    throw new Error({
      message: 'ERROR: Failed to generate CSV mailing list data.',
    })
  }
}

const deleteCsvMailingList = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteCsvMailingList', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteCsvMailingList', data)
    }
    throw new Error({
      message: 'ERROR: Failed to delete CSV mailing list data.',
      ex: e,
    })
  }
}

const getMessages = async (data) => {
  let instance = getInstance(),
    page = data && !isNaN(parseInt(data.page)) ? parseInt(data.page) : null,
    perPage =
      data && !isNaN(parseInt(data.per_page)) ? parseInt(data.per_page) : null,
    divisionId =
      data && !isNaN(parseInt(data.division_id))
        ? parseInt(data.division_id)
        : null,
    search = data && data.search ? data.search : null,
    queryStr = [
      page > 0 || page === 0 ? 'page=' + page : null,
      perPage ? 'per_page=' + perPage : null,
      search ? 'search=' + search : null,
      divisionId > 0 ? 'division_id=' + divisionId : null,
    ]
      .filter((n) => n)
      .join('&')

  try {
    return await instance.get('/messages?' + queryStr, data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/messages?' + queryStr, data)
    }

    throw new Error({
      message: 'Unexpected Error: Unable to fetch dashboard messages.',
    })
  }
}

const getMessageById = async (id) => {
  let instance = getInstance()
  id = id && parseInt(id)

  if (!id)
    throw new Error({
      message: 'Invalid message ID supplied to fetch message.',
    })

  try {
    return await instance.get('/messages/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/messages/' + id)
    }

    throw new Error({
      message: 'Unexpected Error: Unable to fetch dashboard message by id.',
    })
  }
}

const getMessageCategories = async (data) => {
  let instance = getInstance(),
    divisionId = data && data?.division_id ? data.division_id : null

  try {
    return await instance.get(
      '/message-categories?' + (divisionId ? `division_id=${divisionId}` : '')
    )
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get(
        '/message-categories?' + (divisionId ? `division_id=${divisionId}` : '')
      )
    }

    throw new Error({
      message: 'ERROR: Failed to fetch message categories from back-end.',
    })
  }
}

const getPageContentById = async (id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getContentById/agency_content/' + id)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.get('/getContentById/agency_content/' + id)
    }

    throw new Error({
      message: 'ERROR: Failed to fetch page content by the selected ID.',
    })
  }
}

const insertDataAccTable = async (data) => {
  let instance = getMultipartInstance()
  try {
    return await instance.post('/insertUpdateData', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getMultipartInstance()
      return await instance.post('/insertUpdateData', data)
    }

    throw new Error({
      message:
        'ERROR: Failed to complete request to store or update data table records.',
    })
  }
}

const deleteOrderLeads = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/deleteDocumentById', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/deleteDocumentById', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getAgencyContentBySlug = async (data) => {
  let instance = getInstance()
  try {
    return await instance.post('/getAgencyContentBySlug/', data)
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/getAgencyContentBySlug/', data)
    }
    throw new Error({ message: 'Something went wrong!' })
  }
}

const getAgentPackages = async (user_id) => {
  let instance = getInstance()
  try {
    return await instance.get('/getAgentPackages')
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      return await instance.get('/getAgentPackages')
    }
    throw new Error({
      message: 'Failed to fetch current Agent Packages from server.',
    })
  }
}

const resyncCorpEmail = async (userId) => {
  let instance = getInstance()
  try {
    return await instance.post('/resyncCorpEmail/' + parseInt(userId, 10), {})
  } catch (e) {
    if (e && e.response && e.response.status === 403) {
      await refreshToken()
      instance = getInstance()
      return await instance.post('/resyncCorpEmail/' + parseInt(userId, 10), {})
    }
    throw new Error({
      message:
        "An error occurred while attempting to re-sync the agent's corporate email. " +
        e,
    })
  }
}

/*

REMOVED EXPORTS FOR THE FOLLOWING FUNCTIONS.
THESE FUNCTIONS ARE BEING STAGED FOR REMOVAL IN
AN UPCOMING RELEASE.

AdminApi.getOrderLeads()
AdminApi.getOrderLeadsById()
AdminApi.createUpdateOrderLeads()
AdminApi.getCarrierContactsInfo()
AdminApi.updateResourceType()
AdminApi.getTrainingsByName()
AdminApi.getTrainingById()
AdminApi.getTrainingsType()
AdminApi.getGoogleCalendarData()
AdminApi.getCoveragesByCarrier()
AdminApi.getDivisionsByCarrier()
AdminApi.getContractingUsersList()
AdminApi.deleteContract()
AdminApi.getAccountTermsData()
AdminApi.getUpdatedTermsList()
AdminApi.resignUpdatedTerms()
AdminApi.getAgentLeadsList()
AdminApi.getAvailableCarriers()
AdminApi.getAVLeaderboardData()
AdminApi.getPointsLeaderboardData()
AdminApi.getLivesLeaderboardData()
AdminApi.getDivisionProductionDetails()
AdminApi.getDivisionProductionByDay()
AdminApi.getDivisionProductionByCarrier()
AdminApi.getDivisionProductionByAgencyLeadSource()
AdminApi.getDivisionProductionByAgencyLeadSourceCarrier()
AdminApi.getIndividualProductionDetails()
AdminApi.getIndividualProductionByDay()
AdminApi.getIndividualProductionByCarrier()
AdminApi.getIndividualProductionByAgencyLeadSource()
AdminApi.getIndividualProductionByAgencyLeadSourceCarrier()
AdminApi.getDownlineProductionDetails()
AdminApi.getDownlineProductionByDay()
AdminApi.getDownlineProductionByCarrier()
AdminApi.getDownlineProductionByAgencyLeadSource()
AdminApi.getDownlineProductionByAgencyLeadSourceCarrier()
AdminApi.getAgencyProductionByAgencyLeadSource()
AdminApi.getAgencyProductionByAgencyLeadSourceCarrier()
AdminApi.getAgencyProductionByCarrier()
AdminApi.getDivisionPointAgents()
AdminApi.getRegionPointAgents()
AdminApi.getProductLinkById()
AdminApi.getAgentDataById()
AdminApi.getDivisionAgentStatistics()
AdminApi.getDownlineAgentStatistics()
AdminApi.getDivisionManagerStatistics()
AdminApi.getDownlineManagerStatistics()
AdminApi.addTraningAttended()
AdminApi.checkAgentCodeByCarrierId()

*/

export default {
  stripeSaveCard,
  stripeSetInitialDefaultPayMethod,
  deleteOrderLeads,
  getAgencyContentBySlug,
  getAgentPackages,
  generateCsvMailingList,
  deleteCsvMailingList,
  exportCsvReport,
  fetchTermSignatureHistoryByAgent,
  getTechToolsList,
  getTechToolsById,
  createUpdateTechTools,
  deleteTechTools,
  getHomepageData,
  uploadImage,
  fetchUserDetailsAuth,

  getQuoteRequest,
  createQuoteRequest,
  createRecruitLeadCapture,
  createBusinessLeadCapture,
  updateQuoteRequest,
  deleteQuoteRequest,

  getContactRequest,
  createContactRequest,
  updateContactRequest,
  deleteContactRequest,

  getContentRequest,
  getContentById,
  deleteContentById,
  createPage,
  updatePageById,
  deletePage,

  getLeadSources,
  getLeadSourcesSortByName,
  getLeadSourceById,
  createLeadSource,
  updateLeadSource,
  deleteLeadSource,

  getCarrierContests,
  getCarrierContestById,
  createCarrierContest,
  deleteContest,
  getLeadPrograms,
  getLeadProgramById,
  createLeadProgram,
  deleteProgram,

  getEvents,
  getEventById,
  createEvent,
  deleteEvent,

  getCommissions,
  getCommissionById,
  createCommission,
  updateCommission,
  deleteCommission,

  getResources,
  getResourceById,
  createResource,
  updateResource,
  deleteResource,

  getResourcesType,
  getResourcesTypeById,
  getLibraryResourcesType,
  createResourceType,
  deleteResourceType,

  getTerms,
  getTermsById,
  createTerms,
  updateTerms,
  deleteTerms,

  getSignatures,
  deleteSignature,

  getTrainings,
  createTraining,
  updateTraining,
  deleteTraining,

  createTrainingType,

  getNotificationEmails,
  getNotificationEmailById,
  updateNotificationEmail,
  sendTestEmail,

  getDivisions,
  getDivisionById,
  createDivision,
  updateDivision,
  deleteDivision,

  getDivisionOwners,
  getCarriers,

  getAgencyInfo,
  updateAgencyInfo,

  getUserTypes,
  getUserTypeById,
  createUserType,
  updateUserType,
  deleteUserType,

  createUser,

  getLimitedLeadersByUplineId,
  getAllUplineUsers,

  getCalendars,
  getCalendarById,
  getCalendarByUsertypeId,
  getCalendarByDivisionId,
  createCalendar,
  updateCalendar,
  deleteCalendar,

  getContactDetails,

  getCoverages,
  getCoveragesSortByName,
  getCoverageById,
  createCoverage,
  updateCoverage,
  deleteCoverage,

  getCarriersList,
  getCarrierById,
  createCarrier,
  updateCarrier,
  deleteCarrier,

  getProductsList,
  getProductById,
  createProduct,
  updateProduct,
  deleteProduct,

  getPointsList,
  getPointById,
  createPoint,
  updatePoint,
  deletePoint,

  getUserDataById,
  getUserDataByULogin,
  getUserWebsiteDetailsByUlogin,
  enrollUser,
  checkEmailExist,

  getUsersList,
  getContractsListById,
  updateContract,

  getProfileData,
  updateProfile,

  getLicensesList,
  getLicenseById,
  createLicense,
  updateLicense,
  deleteLicense,

  getAgentRecentFiveSales,
  getCarriersByCoverage,
  getProductsByCarrier,
  submitSales,
  getAgentSalesById,

  getAllAvSales,

  addContract,
  getAgentContract,
  updateAgentContract,

  getProspects,
  getProspectById,
  createProspect,
  updateProspect,
  deleteProspects,

  getProspectByStatus,

  getProspectsNotes,

  getManagerResources,

  getAdminResources,

  uploadContentImage,
  getUploadedContentImages,

  isValidProspects,
  isProspectExist,

  getManagersUserTypesList,
  getManagerAgentsListAccUserType,
  updateAgentsUsertype,
  getAgentDownlineProductionDetails,

  getLeaderboardData,
  getReportData,

  deleteUser,

  getDivisionPoints,
  getRegionPoints,

  getQuoteContactRequest,
  deleteQuoteContactRequest,

  createUpdateMenuLinks,

  updateWebsiteInfo,
  getUserSiteDetails,
  getProductLinksList,
  updateProductLink,
  showProductLinks,
  showProductsByCoverage,
  showAvailableProductsByCoverage,
  addToMyProductsWebsiteArea,
  getProductLinkDetails,
  updateProductLinkDetails,

  getResourcesByResourceTypeId,
  getResourcesByResourceTypeIds,
  getUsersFromSearch,
  getUsersFromStateLicense,
  getUsersFromSpokenLanguage,
  getUsersFromSearchTerm,

  createAgentsContactQuoteRequest,

  downgradeToAffiliate,
  getUsertypeWithUsersNumber,
  getAgentsListByUsertype,
  updateMessageBoard,
  getMessageBoardAlert,
  getMessageBoardAlertDataAdmin,

  getCommissionLevelList,

  fetchFactorMilestonesAdminReport,

  userTypeCSV,
  csvUsersByUserType,
  csvUsersList,

  deleteSubmitAv,

  deleteAgentsProductWebsiteArea,

  stripeCreateCustomer,
  stripeCreateSubscription,

  getUserMetas,
  upsertUserMeta,

  getPermissions,

  searchReferrers,
  createReferrer,
  getReferrer,
  updateReferrer,

  getMessages,
  getMessageById,
  getMessageCategories,

  getPageContentById,

  insertDataAccTable,

  resyncCorpEmail,
}
