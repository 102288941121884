import React from 'react'
import { observer } from 'mobx-react-lite'
import { MDBIcon, MDBSideNavCat, MDBSideNavNav, MDBCollapse } from 'mdbreact'
import NavigationStore from './Navigation.store'
import UserProfileService from './../../shared/services/UserProfile.service'
import {
  canAccessLink,
  canAccessChildrenLinks,
  canReadonlyLink,
  canReadonlyChildrenLinks,
  isLinkDisabled,
  isLinkLocked,
} from './../../shared/utilities/linkAccessFilter.function'

import './AgentNavLinks.scss'

const COLORS = ['orange', 'yellow', 'green', 'blue', 'indigo', 'purple', 'red']

const getColor = (idx) =>
  COLORS[
    idx > COLORS.length - 1
      ? idx - COLORS.length * Math.floor(idx / COLORS.length)
      : idx
  ]

export const getUrl = (link) => {
  return link?.url
    ? /^(http:|https:)/.test(link.url)
      ? link.url
      : link.url.replace(':userId', UserProfileService.get('u_login'))
    : ''
}

const hasChildren = (link) =>
  (Array.isArray(link?.children) ? link.children : []).length > 0

const MinorNavLink = ({ link, goTo }) => {
  const url = getUrl(link),
    isReadonly = canReadonlyLink(link),
    canAccess = isReadonly ? isReadonly : canAccessLink(link),
    canOverride = !canAccess ? canAccessLink(link, true) : false,
    isLocked = isLinkLocked(link)

  return (
    <li
      className={
        'minorNavLink ' +
        (isReadonly || canAccess ? '' : 'is-hidden-link ') +
        (canOverride ? 'is-overriden-link ' : '') +
        (isLocked ? 'is-locked-link ' : '')
      }
    >
      <a
        target="_BLANK"
        rel="noopener noreferrer"
        href={!isLocked && url}
        onClick={async (event) => {
          event.preventDefault()
          if (!isLinkLocked(link)) {
            if (goTo) goTo(url)
          } else {
            const lockedLink = await NavigationStore.onLockedClick(link)
            if (lockedLink && goTo && typeof lockedLink === 'string') {
              goTo(lockedLink)
            }
          }

          return false
        }}
        style={{ color: '#FFF !important' }}
      >
        <MDBIcon icon="lock" />
        {link.label}
      </a>
    </li>
  )
}

const MinorNavDropdown = ({ link, goTo }) => {
  const isReadonly = canReadonlyChildrenLinks(link),
    canAccess = isReadonly ? isReadonly : canAccessChildrenLinks(link)

  return (
    <MDBSideNavNav
      className={
        'minorNavDropdown ' +
        (isReadonly || canAccess ? '' : 'is-hidden-dropdown')
      }
    >
      <MDBSideNavCat
        id={`nav-sub-opt---${
          link?.label
            ? link.label.replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()
            : 'void'
        }`}
        name={link.label}
        icon="lock"
      >
        {(Array.isArray(link?.children) ? link.children : []).map(
          (child, idx) => (
            <MinorNavLinks key={`m-c-${idx}`} link={child} goTo={goTo} />
          )
        )}
      </MDBSideNavCat>
    </MDBSideNavNav>
  )
}

const MinorNavLinks = ({ link, goTo }) => {
  // Show Minor nav link w/ dropdown for further-nested children links.
  if (hasChildren(link)) return <MinorNavDropdown link={link} goTo={goTo} />

  // Show Minor nav link.
  if (`${getUrl(link)}`.length > 0)
    return <MinorNavLink link={link} goTo={goTo} />
}

const MajorNavDropdown = ({ link, goTo, color }) => {
  const isReadonly = canReadonlyChildrenLinks(link),
    canAccess = isReadonly ? isReadonly : canAccessChildrenLinks(link),
    canOverride = !canAccess ? canAccessChildrenLinks(link, true) : false

  return (
    <MDBSideNavNav
      className={
        (isReadonly || canAccess ? '' : 'is-hidden-dropdown ') +
        (canOverride ? 'is-overriden-dropdown ' : '') +
        'majorNavDropdown '
      }
    >
      <MDBSideNavCat
        id={`nav-opt---${
          link?.label
            ? link.label.replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()
            : 'void'
        }`}
        name={link?.label}
        icon="lock"
        key={`${link?.label}`}
        className={`bg--${color} theme--btn text--uppercase`}
      >
        {(Array.isArray(link?.children) ? link.children : [])
          .filter((link) => !isLinkDisabled(link))
          .map((child, idx) => (
            <MinorNavLinks key={`m-b-${idx}`} link={child} goTo={goTo} />
          ))}
      </MDBSideNavCat>
    </MDBSideNavNav>
  )
}

const MajorNavButton = ({ link, url, goTo, color }) => {
  const isReadonly = canReadonlyLink(link),
    canAccess = isReadonly ? isReadonly : canAccessLink(link),
    canOverride = !canAccess ? canAccessLink(link, true) : false

  return (
    <li
      className={
        (isReadonly || canAccess ? '' : 'is-hidden-link ') +
        (canOverride ? 'is-overriden-link ' : '') +
        ' majorNavButton '
      }
    >
      <a
        href={url}
        onClick={(event) => {
          event.preventDefault()
          goTo(url)
          return false
        }}
        className={`bg--${color} theme--btn text--uppercase`}
      >
        <MDBIcon icon="lock" />
        {link.label}
      </a>
    </li>
  )
}

const AgentNavLinks = ({ goTo }) => {
  const renderMajorLink = (link, idx) => {
    // Show Major Nav Button w/ Dropdown for nested children links.
    if (hasChildren(link))
      return (
        <MajorNavDropdown
          key={`i-a-${idx}`}
          link={link}
          goTo={goTo}
          color={getColor(idx)}
        />
      )

    // Show Major Nav Link as Button (no Dropdown).
    if (`${getUrl(link)}`.length > 0)
      return (
        <MajorNavButton
          key={`m-a-${idx}`}
          link={link}
          goTo={goTo}
          url={getUrl(link)}
          color={getColor(idx)}
        />
      )
  }

  NavigationStore.build()

  return (
    <ul id="AgentNavLinksComponent">
      {NavigationStore.major
        .filter((link) => !isLinkDisabled(link))
        .map(renderMajorLink)}
    </ul>
  )
}

export default observer(AgentNavLinks)
