import { makeAutoObservable, toJS } from 'mobx'
import { LeaderGoalCardFactory } from './../../../shared/factories'
import UserProfileService from './../../../shared/services/UserProfile.service'
import moment from 'moment'

const EMPTY_GOALS = {
  current_total_points: 0,
  current_total_enrolled: 0,
  current_total_completed: 0,
  current_total_converted: 0,
  current_total_sig_agents: 0,
  previous_total_points: 0,
  previous_monthly_goal_points: 0,
  previous_yearly_goal_points: 0,
  previous_total_enrolled: 0,
  previous_monthly_goal_enrolled: 0,
  previous_yearly_goal_enrolled: 0,
  previous_total_completed: 0,
  previous_monthly_goal_completed: 0,
  previous_yearly_goal_completed: 0,
  previous_total_converted: 0,
  previous_monthly_goal_converted: 0,
  previous_yearly_goal_converted: 0,
  previous_total_sig_agents: 0,
  previous_monthly_goal_sig_agents: 0,
  previous_yearly_goal_sig_agents: 0,
}

async function createMeta(MetaSrvc, metaKey) {
  let Meta = await MetaSrvc.create({ meta_key: metaKey })
  await Meta.save()
  return Meta
}

async function getInvestmentMetas(User) {
  let [SlotInvestMeta, UsabgInvestMeta] = await Promise.all([
    Promise.resolve(
      User ? User.meta(true).key('marketing---slot-investments') : []
    ),
    Promise.resolve(
      User ? User.meta(true).key('marketing---usabg-investments') : []
    ),
  ])

  if (
    !(SlotInvestMeta = Array.isArray(SlotInvestMeta)
      ? SlotInvestMeta.shift()
      : SlotInvestMeta)
  )
    SlotInvestMeta = await createMeta(
      User.meta(true),
      'marketing---slot-investments'
    )

  if (
    !(UsabgInvestMeta = Array.isArray(UsabgInvestMeta)
      ? UsabgInvestMeta.shift()
      : SlotInvestMeta)
  )
    UsabgInvestMeta = await createMeta(
      User.meta(true),
      'marketing---usabg-investments'
    )

  return {
    SlotInvestMeta,
    UsabgInvestMeta,
  }
}

function getGoalYear(goalDate) {
  goalDate = goalDate ? moment(goalDate, 'YYYY-MM-DD') : moment()
  return goalDate.subtract(1, 'years')
}

const determineSourceType = () => {
  let sourceType = null
  if (
    UserProfileService.isA([
      'divisional-leader',
      'agency-owner',
      'division-admin',
    ])
  )
    sourceType = 'division'
  else if (UserProfileService.isA(['regional-leader'])) sourceType = 'region'
  else if (UserProfileService.isA(['district-leader'])) sourceType = 'district'
  else if (UserProfileService.isA(['system-admin'])) sourceType = 'division'
  else sourceType = 'UNKNOWN'
  return sourceType
}

const fetchTeam = async (goalDate) => {
  goalDate = goalDate ? moment(goalDate, 'YYYY-MM-DD') : moment()

  const source_type = determineSourceType(),
    source_id = UserProfileService.getUserId(),
    curr_end_at = goalDate.format('YYYY-MM-DD'),
    prev_end_at = goalDate
      .subtract(1, 'years')
      .endOf('year')
      .format('YYYY-MM-DD'),
    ReportModels = await LeaderGoalCardFactory.search({
      search: { source_type, source_id, curr_end_at, prev_end_at },
      pagination: false,
    })

  return ReportModels.shift()
}

const fetchPersonal = async (goalDate) => {
  goalDate = goalDate ? moment(goalDate, 'YYYY-MM-DD') : moment()

  const source_type = 'recruiter',
    source_id = UserProfileService.getUserId(),
    curr_end_at = goalDate.format('YYYY-MM-DD'),
    prev_end_at = goalDate
      .subtract(1, 'years')
      .endOf('year')
      .format('YYYY-MM-DD'),
    ReportModels = await LeaderGoalCardFactory.search({
      search: { source_type, source_id, curr_end_at, prev_end_at },
      pagination: false,
    })

  return ReportModels.shift()
}

class LeaderGoalCardsPage {
  constructor() {
    makeAutoObservable(this)
  }

  sourceType = ''
  teamGoals = {}
  personalGoals = {}
  slotInvest = {}
  usabgInvest = {}
  agencyContent = {}

  goalDate = null
  isLoading = false

  SlotInvestMeta = null
  UsabgInvestMeta = null

  UserMeta = {
    SlotInvest: null,
    UsabgInvest: null,
  }

  setViewData = ({ Team, Personal, Invest }) => {
    const teamGoals = Team ? Team.all() : EMPTY_GOALS,
      personalGoals = Personal ? Personal.all() : EMPTY_GOALS,
      { slotInvest, usabgInvest } = Invest || {}

    this.teamGoals = teamGoals
    this.personalGoals = personalGoals
    this.slotInvest = slotInvest
    this.usabgInvest = usabgInvest
  }

  getGoalYear = (goalDate) => {
    if (
      (goalDate = goalDate
        ? moment(`${goalDate}`, 'YYYY-MM-DD')
        : this.goalDate
        ? moment(`${this.goalDate}`, 'YYYY-MM-DD')
        : null)
    )
      return goalDate.format('YYYY')
    return
  }

  setGoalDate = async (goalDate) => {
    if (this.goalDate !== goalDate) {
      this.goalDate =
        goalDate && typeof goalDate === 'object'
          ? moment(goalDate).format('YYYY-MM-DD')
          : goalDate
      this.updateReport()
    }
  }

  setInvestment = (changes, UserMeta) => {
    Object.keys(changes).forEach(
      (key) =>
        (changes[key] = !isNaN(changes[key])
          ? parseFloat(changes[key])
          : changes[key])
    )

    if (UserMeta) {
      if (UserMeta?.id() === this.SlotInvestMeta?.id()) {
        let year = changes.year,
          metaValue = this.getMetaValue(this.SlotInvestMeta)

        delete changes.year

        if (!metaValue.hasOwnProperty(year)) metaValue[year] = {}

        metaValue[year] = { ...metaValue[year], ...changes }

        this.SlotInvestMeta.set('meta_value', JSON.stringify(metaValue))
        this.slotInvest = this.getMetaValue(this.SlotInvestMeta)
      }

      if (UserMeta?.id() === this.UsabgInvestMeta?.id()) {
        let year = changes.year,
          metaValue = this.getMetaValue(this.UsabgInvestMeta)

        delete changes.year

        if (!metaValue.hasOwnProperty(year)) metaValue[year] = {}

        metaValue[year] = { ...metaValue[year], ...changes }

        this.UsabgInvestMeta.set('meta_value', JSON.stringify(metaValue))
        this.usabgInvest = this.getMetaValue(this.UsabgInvestMeta)
      }
    }
  }

  setUser = (User) => {
    this.User = User
    this.sourceType = determineSourceType()
  }

  saveMeta = async (Meta, investData) => {
    if (Meta && Meta.id() === this.SlotInvestMeta?.id())
      this.SlotInvestMeta.save()
    else if (Meta && Meta.id() === this.UsabgInvestMeta?.id())
      this.UsabgInvestMeta.save()
  }

  getAgencyContent = (key, prop) => {
    try {
      if (prop) {
        let ac =
          this.sourceType && toJS(this.agencyContent[this.sourceType][key])
        return ac && ac?.[prop]
      }
      return this.sourceType && toJS(this.agencyContent[this.sourceType][key])
    } catch (ex) {
      return ''
    }
  }

  getMetaValue = (Meta) => {
    try {
      Meta =
        Meta && Meta.get('meta_value') ? `${Meta.get('meta_value')}`.trim() : ''
      return Meta ? (typeof Meta === 'string' ? JSON.parse(Meta) : Meta) : {}
    } catch (ex) {
      return {}
    }
  }

  async initStore(goalParams) {
    const { goalDate } = goalParams || {}

    this.goalDate = goalDate || this.goalDate || moment().format('YYYY-MM-DD')

    this.setViewData(await this.fetch())
  }

  async fetch() {
    if (!this.isLoading) this.isLoading = true

    let User = await UserProfileService.instance()
    if (!this.User || this.User.id() !== User.id()) this.setUser(User)

    const [TeamGoal, PersonalGoal, InvestMeta] = await Promise.all([
      fetchTeam(this.goalDate),
      fetchPersonal(this.goalDate),
      getInvestmentMetas(this.User),
    ])

    const { SlotInvestMeta, UsabgInvestMeta } = InvestMeta || {}

    let slotInvest = this.getMetaValue(SlotInvestMeta),
      usabgInvest = this.getMetaValue(UsabgInvestMeta)

    this.SlotInvestMeta = SlotInvestMeta
    this.UsabgInvestMeta = UsabgInvestMeta

    this.isLoading = false
    return {
      Team: TeamGoal || null,
      Personal: PersonalGoal || null,
      Invest: { slotInvest, usabgInvest },
    }
  }

  updateReport = async () => {
    this.setViewData(await this.fetch())
  }
}

export default new LeaderGoalCardsPage()
