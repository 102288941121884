import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import Store from './../store'
import ToolTip from './../../shared/ToolTip/ToolTip.component'
import {
  MDBAlert,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBContainer,
} from 'mdbreact'
import UserProfileService from './../../../shared/services/UserProfile.service'
import moment from 'moment'

import './Transaction.component.scss'

const renderIcon = (Transaction) => {
  if (Transaction?.get('forwarding_id')) {
    if (Transaction.get('credit') > 0)
      return (
        <>
          <ToolTip
            icon="fast-forward"
            iconColor="#FFF"
            content={
              'This transaction was automaticallly forwarded to you from another agent wallet.'
            }
          />
          &nbsp;
        </>
      )
    if (Transaction.get('debit') > 0)
      return (
        <>
          <ToolTip
            icon="forward"
            iconColor="#FFF"
            content={
              'This transaction was automaticallly forwarded to another agent wallet.'
            }
          />
          &nbsp;
        </>
      )
  }
}

const Transaction = ({ Transaction, layout, onSelect, toast }) => {
  onSelect = onSelect && typeof onSelect === 'function' ? onSelect : () => {}
  const [transShadow, setTransShadow] = useState()

  const rollupTransaction = async (Transaction) => {
    try {
      await Store.rollupTransaction(Transaction)
    } catch (ex) {
      toast.error(`${ex}`.replace(/Error: ?/g, ''), {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }

  const rollupButton = () => {
    if (Transaction?.get('trans_state') !== 'PENDING') return <></>

    if (Transaction?.get('debit') > 0) return <></>

    if (!Transaction?.get('type')) return <></>

    if (['Expiration', 'Withholding'].includes(Transaction.get('type')))
      return <></>

    return (
      <MDBBtn
        disabled={Store.isRollingUp > 0}
        onClick={() => rollupTransaction(Transaction)}
        className="rollup-btn"
      >
        {Store.isRollingUp == Transaction.id() ? (
          <i className="fa fa-spin fa-spinner"></i>
        ) : (
          <>
            Roll-up&nbsp;
            <MDBIcon fas icon="level-up-alt" />
          </>
        )}
      </MDBBtn>
    )
  }

  const saveButton = () => (
    <MDBBtn
      onClick={() => {
        onSelect(Transaction?.id() || null)
        Store.editTransaction(Transaction)
      }}
    >
      <MDBIcon icon="save" />
    </MDBBtn>
  )

  const renderActionButtons = () => {
    if (Transaction.get('trans_state') === 'PENDING') {
      return (
        <>
          {rollupButton()}
          {saveButton()}
        </>
      )
    }

    return (
      <MDBAlert
        color={
          Transaction.get('trans_state') === 'CLOSED' ? 'success' : 'danger'
        }
      >
        {Transaction.get('trans_state')}
      </MDBAlert>
    )
  }

  const getDate = (data) => {
    if (data && typeof data === 'object')
      return moment(data).format('MM/DD/YYYY')

    if (data && typeof data === 'string') {
      if (/\d{4}-\d{2}-\d{2}/.test(data))
        return moment(data, 'YYYY-MM-DD').format('MM/DD/YYYY')
    }

    return data
  }

  const getSource = () => {
    let source = null

    if (
      Transaction?.get('source') &&
      typeof toJS(Transaction?.get('source')) === 'object'
    )
      source =
        toJS(Transaction?.get('source'))?.user ||
        toJS(Transaction?.get('source'))?.name

    if (source) return source

    // came from another agent.
    if (Transaction.get('doner_id')) {
      source = Transaction.get('doner_id')
    }
    // came from a carrier.
    else if (Transaction.get('carrier_id')) {
      source =
        (Transaction.get('carrier_id') &&
          (Store.TransferStore.Carriers || []).find(
            (carrier) => carrier.id + '' === Transaction.get('carrier_id') + ''
          )?.name) ||
        ''
    }
    // came from a fmo.
    else if (Transaction.get('fmo_id')) {
      source =
        (Transaction.get('fmo_id') &&
          (Store.TransferStore.Fmos || []).find(
            (carrier) => carrier.id + '' === Transaction.get('fmo_id') + ''
          )?.name) ||
        ''
    }

    if (!source && Transaction?.get('type') === 'AgentAchievement')
      source = 'USABG'

    if (!source && Transaction?.get('type') === 'Purchase') {
      if ((source = Transaction.get('type'))) {
        if (!isNaN(`${Transaction.get('doner_id')}`))
          source =
            `${UserProfileService.getUserId()}` ===
            `${Transaction.get('doner_id')}`
              ? 'Self-Funded'
              : 'Upline-Funded'
      }
    } else if (!source && Transaction?.get('type') === 'AgentTransfer') {
      source = Store.Doners[Transaction.get('doner_id')]
        ? [
            Store.Doners[Transaction.get('doner_id')]?.get('u_nickname') ||
              Store.Doners[Transaction.get('doner_id')]?.get('u_fname'),
            Store.Doners[Transaction.get('doner_id')]?.get('u_lname'),
          ]
            .join(' ')
            .trim()
        : source
    }

    return source
  }

  const getPurpose = () => {
    if (
      Transaction?.get('purpose') &&
      /(.+)\ USABG BUCK\$/.test(`${Transaction.get('purpose')}`)
    ) {
      let i = `${Transaction.get('purpose')}`.indexOf('USABG BUCK$')
      return (
        <>
          {`${Transaction.get('purpose')}`.substring(0, i)}
          <br />
          {`${Transaction.get('purpose')}`.substring(i)}
        </>
      )
    }

    return Transaction?.get('purpose')
  }

  const isExpired = `${Transaction?.get('is_expire')}` === '1'
  return (
    <li className={`${isExpired ? 'alert-danger trans-expire' : ''}`}>
      <div className="al-id">{Transaction.id()}</div>
      <div className="al-category">
        {renderIcon(Transaction)}
        {Store.TransferStore.category(Transaction.get('category'))}
      </div>
      <div className="al-type">
        {(Transaction.get('type') || '').replace(/([a-z0–9])([A-Z])/g, '$1 $2')}
      </div>
      <div className="al-source">{getSource()}</div>
      <div className="al-purpose">{getPurpose()}</div>
      {Transaction.get('credit') ? (
        <div className="al-amount is-credit">{`$${Transaction.get(
          'credit'
        )}.00`}</div>
      ) : (
        <div className="al-amount is-debit">{`($${Transaction.get(
          'debit'
        )}.00)`}</div>
      )}
      {Transaction.get('balance') ||
      parseInt(Transaction.get('balance')) === 0 ? (
        <div className="al-balance">{`$${Transaction.get('balance')}.00`}</div>
      ) : (
        <div className="al-balance is-debit">{`($${
          Transaction.get('balance') === null ||
          isNaN(Transaction.get('balance'))
            ? 0
            : Transaction.get('balance')
        }.00)`}</div>
      )}
      <div className="al-date">{getDate(Transaction.get('transact_at'))}</div>
      {layout === 'admin' ? (
        <div className="al-btns">{renderActionButtons()}</div>
      ) : (
        <></>
      )}
      {layout === 'agent' &&
      UserProfileService.isA(
        ['system-admin', 'internal-admin', 'internal-staff', 'agency-owner'],
        true
      ) &&
      Transaction.get('trans_state') === 'PENDING' ? (
        <div className="al-btns">{rollupButton()}</div>
      ) : (
        <></>
      )}
    </li>
  )
}

export default observer(Transaction)
