import React from 'react'
import { observer } from 'mobx-react-lite'
import { UIInputToggleSwitch } from './../../../../components/forms/form-fields'
import LeaderReportStore from './../../store/LeaderReportCard.store'

import './ViewModeSelector.scss'

const ViewModeSelector = ({ viewMode, onViewModeChange }) => {
  return (
    <div id="ViewModeSelectorComponent">
      <label>Switch {viewMode === 'goals' ? 'back ' : ''}to:</label>
      <UIInputToggleSwitch
        width="100"
        disabled={false}
        labelOn={false ? <i className="fa fa-spin fa-spinner"></i> : 'Reports'}
        labelOff={false ? <i className="fa fa-spin fa-spinner"></i> : 'Goals'}
        value={viewMode === 'goals'}
        onChange={(evt) => onViewModeChange(evt === true ? 'goals' : 'reports')}
      />
    </div>
  )
}

export default observer(ViewModeSelector)
