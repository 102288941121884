import BaseModelInstance from './base.model'
import { LedgerTransaction as ModelDefinition } from './model-definitions'
import LedgerTransactionService from '../services/LedgerTransaction.service'

class LedgerTransaction extends BaseModelInstance {
  afterSet = (key, value) => {}

  rollup = async () => {
    try {
      await LedgerTransactionService.rollup(this.id())
    } catch (ex) {
      throw new Error(`${ex}`)
    }
  }

  isValid = () => {
    let { credit, debit, purpose, transact_at } = this.all()

    purpose = (purpose ? `${purpose}` : '').trim()
    credit = parseFloat(credit)
    debit = parseFloat(debit)

    if (isNaN(credit) && isNaN(debit)) {
      // both values cant be non-numeric.
      return 'Debit & Credit are invalid.'
    } else if (credit && debit) {
      // both numbers can be positive.
      return "Debit & Credit can't both be greater than 0."
    } else if ((credit === 0 && debit > 0) || (credit > 0 && debit === 0)) {
      // all good.
    } else {
      // invalid numbers.
      return 'Debit & Credit are invalid.'
    }

    if (!purpose || !purpose.length) {
      // invalid notes.
      return false
    }

    if (transact_at) {
      if (typeof transact_at === 'object' && transact_at instanceof Date)
        return true
      if (
        typeof transact_at === 'string' &&
        /\d{4}-\d{2}-\d{2}/.test(transact_at)
      )
        return true
    }

    // invalid date format.
    return false
  }

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }
}

export default LedgerTransaction
