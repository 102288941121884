import { makeObservable, observable } from 'mobx'

const Services = {
    AgentPackageService: async () =>
      await import('./../services/AgentPackage.service.js'),
    AgentPackageFeatureService: async () =>
      await import('./../services/AgentPackageFeature.service.js'),
    AgentPackagesFeaturesService: async () =>
      await import('./../services/AgentPackagesFeatures.service.js'),
    AvService: async () => await import('./../services/Av.service.js'),
    BATeamService: async () => await import('./../services/BATeam.service.js'),
    CarrierContractLinkService: async () =>
      await import('./../services/CarrierContractLink.service.js'),
    CarrierService: async () =>
      await import('./../services/Carrier.service.js'),
    CarrierFmoService: async () =>
      await import('./../services/CarrierFmo.service.js'),
    CommissionLevelService: async () =>
      await import('./../services/CommissionLevel.service.js'),
    CoverageService: async () =>
      await import('./../services/Coverage.service.js'),
    KnowledgebaseArticleService: async () =>
      await import('./../services/KnowledgebaseArticle.service.js'),
    KnowledgebaseCategoryService: async () =>
      await import('./../services/KnowledgebaseCategory.service.js'),
    LeadCreditPackageService: async () =>
      await import('./../services/LeadCreditPackage.service.js'),
    LeadCreditQuantityPriceService: async () =>
      await import('./../services/LeadCreditQuantityPrice.service.js'),
    LeadSourceService: async () =>
      await import('./../services/LeadSource.service.js'),
    LeadVendorService: async () =>
      await import('./../services/LeadVendor.service.js'),
    LedgerService: async () => await import('./../services/Ledger.service.js'),
    LedgerTransactionService: async () =>
      await import('./../services/LedgerTransaction.service.js'),
    LedgerTransactionRuleService: async () =>
      await import('./../services/LedgerTransactionRule.service.js'),
    PointOverrideService: async () =>
      await import('./../services/PointOverride.service.js'),
    ReferralPartnerService: async () =>
      await import('./../services/ReferralPartner.service.js'),
    ReferralPartnersW9Service: async () =>
      await import('./../services/ReferralPartnersW9.service.js'),
    SubsiteService: async () =>
      await import('./../services/Subsite.service.js'),
    SubsiteLinkService: async () =>
      await import('./../services/SubsiteLink.service.js'),
    TermService: async () => await import('./../services/Term.service.js'),
    TermSetService: async () =>
      await import('./../services/TermSet.service.js'),
    TrainingService: async () =>
      await import('./../services/Training.service.js'),
    TrainingQuizService: async () =>
      await import('./../services/TrainingQuiz.service.js'),
    TrainingQuestionService: async () =>
      await import('./../services/TrainingQuestion.service.js'),
    TrainingAnswerService: async () =>
      await import('./../services/TrainingAnswer.service.js'),
    UserService: async () => await import('./../services/User.service.js'),
    UserGoalService: async () =>
      await import('./../services/UserGoal.service.js'),
    UserCertificationService: async () =>
      await import('./../services/UserCertification.service.js'),
    UserContractCartService: async () =>
      await import('./../services/UserContractCart.service.js'),
    UserContractCartItemService: async () =>
      await import('./../services/UserContractCartItem.service.js'),
    UserContractCartMetaService: async () =>
      await import('./../services/UserContractCartMeta.service.js'),
    UserDocumentService: async () =>
      await import('./../services/UserDocument.service.js'),
    UserEventLogService: async () =>
      await import('./../services/UserEventLog.service.js'),
    UserExternalCarrierService: async () =>
      await import('./../services/UserExternalCarrier.service.js'),
    UserMessageService: async () =>
      await import('./../services/UserMessage.service.js'),
    UserMetaService: async () =>
      await import('./../services/UserMeta.service.js'),
    UsersCarrierContractService: async () =>
      await import('./../services/UsersCarrierContract.service.js'),
    UserTermService: async () =>
      await import('./../services/UserTerm.service.js'),
    UsersTermSetsService: async () =>
      await import('./../services/UsersTermSets.service.js'),
    UserTrainingQuizService: async () =>
      await import('./../services/UserTrainingQuiz.service.js'),
  },
  ServicesLibrary = {}

const importService = async (Service) => {
  if (!Service) return false

  if (ServicesLibrary.hasOwnProperty(Service)) return ServicesLibrary[Service]

  if (Services.hasOwnProperty(Service))
    ServicesLibrary[Service] = await Services[Service]()

  return ServicesLibrary[Service] || undefined
}

const cloneObj = (obj) => {
  let clone = {}
  if (obj && typeof obj === 'object')
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object')
        clone[key] =
          obj[key] instanceof Date ? new Date(obj[key]) : cloneObj(obj)
      else if (Array.isArray(obj[key]))
        clone[key] = obj[key].map((val) =>
          val ? (typeof val === 'object' ? cloneObj(val) : val) : cloneObj
        )
      else clone[key] = obj[key]
    })
  return clone
}

const areValuesDifferent = (a, b, w) => {
  if (
    typeof a !== typeof b &&
    !(
      ['string', 'number'].indexOf(typeof a) > -1 &&
      ['string', 'number'].indexOf(typeof b) > -1
    )
  )
    return true

  if ((a && !b) || (!a && b)) return true

  if (typeof a === 'object') return JSON.stringify(a) !== JSON.stringify(b)

  if (`${a}` !== `${b}`) {
    if (!isNaN(a) && !isNaN(b)) return parseFloat(a) !== parseFloat(b)
    return true
  }

  return false
}

class BaseModelInstance {
  // The unique id for the instance.
  #id = null

  // Boolean flag indicating if the initial instantiation has completed (includes an async method call to import service dynamically)
  #defined = false

  // Key value object with all properties at the time the instance attribs were filled.
  #orig_props = {}

  // Duplicate of orig_props, except with out 'permitted' checking.
  #raw_props = {}

  // Key value object with all current properties.
  curr_props = {}

  change_props = {}

  // Array of field names that can be modified.
  #permitted = []

  #required = []
  #fillable = []

  // Name of model 'id' field.  Defaults to 'id'.
  #id_field = 'id'

  // REST API Consumption Service.
  #service = {}

  // Boolean State Flags.
  #is_saving = false

  // ------------------------------------------------------------------------------------------------
  // PRIVATE METHODS
  // ------------------------------------------------------------------------------------------------

  #create = async () => {
    this.#is_saving = true

    const service = async (data) => {
      if (this.#service?.store) return await this.#service.store(data)

      return new Promise((resolve, reject) => {
        let iHandle = setInterval(async () => {
          if (this.#service?.store) {
            clearInterval(iHandle)
            this.#service.store(data).then(resolve, reject)
          }
        }, 50)
      })
    }

    let result
    try {
      result = await service(this.#all())
    } catch (ex) {
      this.#is_saving = false
      throw new Error(ex)
    }

    this.#is_saving = false

    if (
      result &&
      typeof result === 'object' &&
      result.hasOwnProperty(this.#id_field || 'id')
    ) {
      this.change_props = {}
      this.#id = result[this.#id_field || 'id']
      return !this.isNew()
    }

    return false
  }

  #update = async () => {
    if (!this.hasChanged) return false

    const service = async (id, data) => {
      if (this.#service?.update) return await this.#service.update(id, data)

      return new Promise((resolve, reject) => {
        let iHandle = setInterval(async () => {
          if (this.#service?.update) {
            clearInterval(iHandle)
            this.#service.update(id, data).then(resolve, reject)
          }
        }, 50)
      })
    }

    this.#is_saving = true

    let result
    try {
      result = await service(this.id(), this.#all())
    } catch (ex) {
      console.error(
        `Exception thrown while attempting to complete update request.  ex: ${ex}`
      )
    }
    this.#is_saving = false

    if (result && result?.id) {
      this.change_props = {}
      this.#orig_props = cloneObj(this.curr_props)
      return true
    }

    return false
  }

  #delete = async () => {
    this.#is_saving = true

    const service = async (id) => {
      if (this.#service?.delete) return await this.#service.delete(id)

      return new Promise((resolve, reject) => {
        let iHandle = setInterval(async () => {
          if (this.#service?.delete) {
            clearInterval(iHandle)
            this.#service.delete(id).then(resolve, reject)
          }
        }, 50)
      })
    }

    let result
    try {
      result = await service(this.id())
    } catch (ex) {
      console.error(
        `Exception thrown while attempting to complete delete request.  ex: ${ex}`
      )
    }
    this.#is_saving = false

    if (result && result?.id) return true

    return false
  }

  #reload = async () => {
    if (this.isNew() || !this.id()) return false

    let Self
    if (this.#service?.get) Self = await this.#service.get(this.id())

    if (Self && typeof Self === 'object' && Object.values(Self).length > 0)
      return this.fill(Self)
  }

  #define = async (definition) => {
    this.#permitted = Array.isArray(definition?.permitted)
      ? definition.permitted
      : []

    this.#required = Array.isArray(definition?.required)
      ? definition.required
      : []

    this.#fillable = Array.isArray(definition?.fillable)
      ? definition.fillable
      : []

    if (definition?.id) this.#id_field = definition.id

    if (definition?.service)
      this.#service = (await importService(definition.service))?.default

    // if (definition?.related)
    // 	console.log("Related? ",definition.related);

    this.#defined = true

    return true
  }

  #isValid = () => Object.values(this.curr_props).length > 0

  #get = (key, useOrig) => {
    if (['created_at', 'updated_at'].includes(key)) {
      if (this.#raw_props.hasOwnProperty(key)) return this.#raw_props[key]
    }

    return useOrig
      ? this.#orig_props.hasOwnProperty(key)
        ? this.#orig_props[key]
        : undefined
      : this.curr_props.hasOwnProperty(key)
      ? this.curr_props[key]
      : undefined
  }

  #set = (key, value, useOrig) => {
    if (this.#setSilent(key, value, useOrig) !== undefined && this.afterSet);
    this.afterSet(key, value)
  }

  #setSilent = (key, value, useOrig) => {
    if (this.#permitted.indexOf(key) > -1) {
      value = useOrig
        ? (this.#orig_props[key] = value)
        : (this.curr_props[key] = value)

      if (areValuesDifferent(this.curr_props[key], this.#orig_props[key]))
        this.change_props[key] = {
          to: this.curr_props[key],
          from: this.#orig_props[key],
        }
      else if (this.change_props.hasOwnProperty(key))
        delete this.change_props[key]

      return value
    }
  }

  #keys = () => Object.keys(this.curr_props)
  #values = () => Object.values(this.curr_props)
  #raw = () => ({ ...this.#raw_props, ...this.curr_props })
  #all = () => ({ ...this.curr_props })
  #children = {
    meta: [],
    history: [],
  }

  // ------------------------------------------------------------------------------------------------
  // PUBLIC METHODS
  // ------------------------------------------------------------------------------------------------
  get hasChanged() {
    return Object.values(this.changed).length > 0
  }
  get changed() {
    return this.change_props
  }

  create = async function () {
    return await this.#create()
  } // this.#create
  update = async function () {
    return await this.#update()
  } // this.#update
  delete = async function () {
    return await this.#delete()
  } // this.#delete
  isValid = this.#isValid
  get = this.#get
  set = this.#set
  setSilent = this.#setSilent
  permitted = () => [...this.#permitted]
  keys = this.#keys
  values = this.#values
  raw = this.#raw
  all = this.#all

  isNew = () => !this.#id
  id = () => (this.#id ? this.#id : false)
  export = () => JSON.parse(JSON.stringify(this.curr_props))
  save = async () => await (this.isNew() ? this.create() : this.update())
  reload = async () => await this.#reload()
  api = () => this.#service
  children = (relation, models, opts) => {
    opts = opts || {}
    const shouldAppend =
      models && typeof models === 'object' && typeof models?.id === 'function'

    if (!this.#children.hasOwnProperty(relation)) {
      console.error(`Invalid child relation used '${relation}'`)
      return false
    } else if (
      typeof models !== 'undefined' &&
      !(shouldAppend || Array.isArray(models) || null)
    ) {
      console.error(
        `Invalid child model type passed '${typeof models}'`,
        models
      )
      return false
    }

    if (models)
      this.#children[relation] = [
        ...this.#children[relation],
        ...(shouldAppend ? [models] : models),
      ]

    return this.#children[relation]
  }

  fill = async (attribs) => {
    attribs = attribs && typeof attribs === 'object' ? attribs : {}

    if (attribs[this.#id_field]) this.#id = attribs[this.#id_field]

    let applied = {}
    Object.keys(attribs).forEach((k) => {
      this.#raw_props[k] = attribs[k]
      if (this.#permitted.indexOf(k) > -1) {
        this.#orig_props[k] = attribs[k]
        this.curr_props[k] = attribs[k]
        applied[k] = attribs[k]
      }
    })
    return applied
  }

  constructor(definition, attribs) {
    if (definition && typeof definition === 'object') this.#define(definition)
    this.fill(attribs)

    makeObservable(this, {
      curr_props: observable,
      change_props: observable,
    })
  }
}

export default BaseModelInstance
