class BaseModelFactory {
  static _search = async (
    { search, pagination, order_by, method },
    { Service, Model }
  ) => {
    // method is an override.  it will permit a Factory to use a custom method on an API service.
    // ie: getWithPriorities on CarrierService.

    let payload,
      request = {
        search: search && typeof search === 'object' ? search : undefined,
        pagination:
          (pagination && typeof pagination === 'object') || pagination === false
            ? pagination
            : undefined,
        order_by:
          order_by && typeof order_by === 'object' ? order_by : { id: 'DESC' },
      }

    Object.keys(request)
      .filter((k) => typeof request[k] === 'undefined')
      .forEach((k) => delete request[k])

    if (method && !Service.hasOwnProperty(method))
      throw new Error(
        `Invalid method argument supplied for use in Factory. (method: ${method})`
      )

    try {
      payload = !method
        ? await Service.search(request)
        : await Service[method](request)
    } catch (ex) {
    	throw new Error(`${ex}`.replace(/^(Error(:?)( )?)+/i,'').trim());
      return false
    }

    payload.models = (
      payload && Array.isArray(payload?.models) ? payload.models : []
    ).map((data) => new Model(data))

    return pagination === false ? payload.models : payload

    // payload.models = (payload && Array.isArray(payload?.models) ? payload.models : []).map((data) => new Model(data))
    // return (payload && payload?.models ? payload?.models : []).map(
    //   (data) => new Model(data)
    // )
  }

  static _findAllBy = async (search, { Service, Model }) => {
    // Should be able to re-implement the contents of this method w/ the follow search invocation.
    // return await this._search({search, order_by: {id: 'DESC'}, pagination: false})
    let payload
    try {
      payload = await Service.search({
        search,
        pagination: false,
        order_by: { id: 'DESC' },
      })
    } catch (ex) {
      return false
    }

    return (payload && payload?.models ? payload?.models : []).map(
      (data) => new Model(data)
    )
  }

  static _findBy = async (search, { Service, Model }) => {
    // Should be able to re-implement the contents of this method w/ the follow search invocation.
    // return (await this._search({search, order_by: {id: 'DESC'}, pagination: false})).shift()
    let Models = await this._findAllBy(search, { Service, Model })
    return (Models && Array.isArray(Models) ? Models : []).shift()
  }

  static _create = (payload, { Model }) => {
    return payload && Object.values(payload).length > 0
      ? new Model(payload)
      : undefined
  }
}

export default BaseModelFactory
