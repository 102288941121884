import BaseModelFactory from './base.factory'
import LeaderReportCardService from './../services/LeaderReportCard.service'
import LeaderReportCard from './../models/leader-report-card.model'

class LeaderReportCardFactory extends BaseModelFactory {
  static search = async ({ search, pagination, order_by, method }) =>
    await this._search(
      { search, pagination, order_by, method },
      { Service: LeaderReportCardService, Model: LeaderReportCard }
    )
  static report = async ({ start_at, end_at, source_type }) =>
    await this._search(
      {
        search: { source_type, start_at, end_at },
        pagination: false,
        order_by: {},
      },
      { Service: LeaderReportCardService, Model: LeaderReportCard }
    )
  static create = (payload) =>
    this._create(payload, {
      Service: LeaderReportCardService,
      Model: LeaderReportCard,
    })
}

export default LeaderReportCardFactory
