import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBAlert,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from 'mdbreact'
import UserProfileService from './../../../shared/services/UserProfile.service'
import ACMStore from './../AgentContractManager.store'

import './ReplacementCarrierSelector.scss'

const getMetaValue = (Meta) => {
  let metaValue = Meta && Meta?.get('meta_value')

  if (metaValue) {
    try {
      metaValue =
        metaValue && typeof metaValue === 'string'
          ? JSON.parse(metaValue)
          : metaValue
    } catch (ex) {}

    return metaValue && Array.isArray(metaValue) ? metaValue : []
  }

  return []
}

const ReplacementCarrierSelector = ({ CartItem, onConfirm, activeSpecs }) => {
  const [showScrollIndicator, setShowScrollIndicator] = useState(true),
    ExtCarMeta = (ACMStore?.CartMetas || [])
      .filter(
        (CartMeta) => CartMeta.get('meta_key') === 'cart-items---ext-carriers'
      )
      .shift(),
    ExternalCarrier = ACMStore?.Carriers.filter(
      (Carrier) =>
        parseInt(Carrier.id()) === parseInt(CartItem?.get('carrier_id'))
    ).shift(),
    ReplacementCarrier = (function (replacementId) {
      replacementId = getMetaValue(ExtCarMeta)
        .filter(
          (e) => parseInt(e.external_id) === parseInt(ExternalCarrier?.id())
        )
        .shift()?.replacement_id
      return replacementId
        ? ACMStore.Carriers.filter(
            (Carrier) => parseInt(Carrier.id()) === parseInt(replacementId)
          ).shift()
        : undefined
    })()

  const isCarrierSelectable = (Carrier) => {
    if (parseInt(Carrier.get('c_active')) !== 1) return false

    // remove carriers in the cart (excluding abandonded).
    // return false if carrier is already in shopping cart.
    if (
      ACMStore.CartItems.filter(
        (Item) =>
          `${Item.get('carrier_id')}` === `${Carrier.id()}` &&
          Item.get('item_status') !== 'ABANDONED'
      ).length > 0
    )
      return false

    // remove carriers already contracted (excluding expired).
    // return false if carrier is already contracted.
    if (
      ACMStore.Contracts.filter(
        (Contract) =>
          `${Contract.get('carrier_id')}` === `${Carrier.id()}` &&
          Contract.get('disposition') !== 'expired'
      ).length > 0
    )
      return false

    // remove carriers that are external.
    // return false if carrier is already contracted through another fmo.
    if (
      ACMStore.ExternalCarriers.filter(
        (ExtCar) => `${ExtCar.get('carrier_id')}` === `${Carrier.id()}`
      ).length > 0
    )
      return false

    // remove carriers that are other replacement carriers.
    // return false if carrier is already a replacement carrier for another external carrier.
    if (
      getMetaValue(ExtCarMeta)
        .map((e) => parseInt(e.replacement_id))
        .indexOf(parseInt(Carrier.id())) > -1
    )
      return false

    return true
  }

  const loadSelectableCarriers = () => {
    const CarrierGroups = { core: [], preferred: [], additional: [] }
    ;(ACMStore?.Carriers || [])
      .filter(isCarrierSelectable)
      .forEach((Carrier) =>
        CarrierGroups[
          Carrier.getPriority(
            activeSpecs,
            UserProfileService.get('usertype_id')
          )
        ].push(Carrier)
      )
    return CarrierGroups
  }

  const onCarrierConfirmed = async (Carrier) => {
    let CartMeta = ExtCarMeta,
      metaValue = getMetaValue(CartMeta)

    CartMeta = CartMeta
      ? CartMeta
      : await ACMStore.Cart.meta(true).create({
          meta_key: 'cart-items---ext-carriers',
        })

    metaValue = metaValue.filter(
      (e) => parseInt(e.external_id) === parseInt(ExternalCarrier.id())
    ).length
      ? metaValue.map((e) =>
          parseInt(e.external_id) === parseInt(ExternalCarrier.id())
            ? { ...e, replacement_id: parseInt(Carrier.id()) }
            : e
        )
      : metaValue.concat([
          {
            external_id: parseInt(ExternalCarrier.id()),
            replacement_id: parseInt(Carrier.id()),
          },
        ])
    CartMeta.set('meta_value', JSON.stringify(metaValue))

    await CartMeta.save()
    ACMStore.set('CartMetas', CartMeta)
  }

  const setReplacementCarrier = async (Carrier) => {
    let hasConfirmed = false

    try {
      if (typeof onConfirm === 'function')
        hasConfirmed = await onConfirm(ExternalCarrier, Carrier)
    } catch (ex) {
      return false
    }

    if (hasConfirmed) await onCarrierConfirmed(Carrier)
  }

  const removeReplacementCarrier = async () => {
    // 1. remove the external_id from CartMeta.
    // This does not delete the External Carrier record, just
    // deletes the reference to the replacement carrier.
    let CartMeta = ExtCarMeta,
      metaValue = getMetaValue(CartMeta)
    if (CartMeta) {
      CartMeta.set(
        'meta_value',
        JSON.stringify(
          metaValue.filter(
            (e) => parseInt(e.external_id) !== parseInt(ExternalCarrier?.id())
          )
        )
      )
      CartMeta.save()
    }

    // 2. Make sure to set the carrier_id back
    // to the original carrier_id on CartItem & Contract
    // records.
    // im not sure i need this.
    // if (CartItem)
    // 	CartItem.set('carrier_id', )
    // if (ReplacementCarrier) {}
  }

  const onDropdownToggle = (isOpen) => {
    if (isOpen && !showScrollIndicator) setShowScrollIndicator(true)
  }

  let scrollIntTimer
  const onScroll = (evt) => {
    if (!scrollIntTimer && showScrollIndicator)
      scrollIntTimer = setTimeout(() => {
        clearTimeout(scrollIntTimer)
        setShowScrollIndicator(false)
      }, [1000])
  }

  const renderToggle = () => {
    const useReplacement = !!ReplacementCarrier?.get('c_name'),
      warning = (
        <MDBAlert color="danger" className="replacement-required">
          <MDBIcon icon="exclamation-triangle" />
          Required
        </MDBAlert>
      )

    if (ReplacementCarrier) {
      return (
        <>
          <MDBDropdownToggle
            onClick={($event) =>
              onDropdownToggle(
                !$event.target.parentElement.parentElement.classList.contains(
                  'show'
                )
              )
            }
            caret
          >
            <div>
              {useReplacement
                ? ReplacementCarrier.get('c_name')
                : 'Select A Replacement Carrier Contract'}
            </div>
            {ReplacementCarrier?.get('c_description')}
          </MDBDropdownToggle>
          <MDBBtn
            onClick={() => removeReplacementCarrier()}
            color="danger"
            className="remove-replace-btn"
          >
            <MDBIcon icon="times" />
          </MDBBtn>
        </>
      )
    }

    return (
      <MDBDropdownToggle
        onClick={($event) =>
          onDropdownToggle(
            !$event.target.parentElement.parentElement.classList.contains(
              'show'
            )
          )
        }
        caret
      >
        <label>
          {useReplacement ? (
            ReplacementCarrier.get('c_name')
          ) : (
            <>{warning}Select A Replacement Carrier Contract</>
          )}
        </label>
      </MDBDropdownToggle>
    )
  }

  const renderDropDown = () => {
    const CarrierGroups = loadSelectableCarriers()

    const renderTable = (priority) => {
      let isLocked = false
      if (priority === 'preferred')
        isLocked = (CarrierGroups.core.length || 0) > 0
      if (priority === 'additional')
        isLocked =
          parseInt(CarrierGroups.core.length || 0) +
            parseInt(CarrierGroups.preferred.length || 0) >
          0

      if (CarrierGroups[priority].length > 0) {
        return (
          <ul
            className={
              'replace-selector-list list-' +
              priority +
              ' ' +
              (isLocked ? 'list-locked' : '')
            }
          >
            {CarrierGroups[priority].map((Carrier, idx) => (
              <MDBDropdownItem
                disabled={isLocked}
                tag="li"
                onClick={() => setReplacementCarrier(Carrier)}
                key={`replace-carrier-opt-${Carrier.id()}-${idx}`}
              >
                <div>
                  {priority === 'core'
                    ? '$$$'
                    : priority === 'preferred'
                    ? '$$'
                    : '$'}
                </div>
                <div>
                  <div>{Carrier.get('c_name')}</div>
                  <div>{Carrier.get('c_description')}</div>
                </div>
              </MDBDropdownItem>
            ))}
          </ul>
        )
      }
    }

    return (
      <MDBDropdownMenu basic>
        <div onScroll={onScroll} className="dropdown-menu-scroller">
          {renderTable('core')}
          {renderTable('preferred')}
          {renderTable('additional')}
        </div>
        <MDBAlert
          className={
            'dropdown-scroll-indicator ' + (showScrollIndicator ? 'show' : '')
          }
          color="info"
        >
          Scroll Down For More Carriers&nbsp;
          <MDBIcon icon="arrow-down" />
        </MDBAlert>
      </MDBDropdownMenu>
    )
  }

  return (
    <MDBContainer className="ReplacementCarrierSelectorComponent" fluid>
      <MDBRow>
        <MDBCol size="12">
          <MDBDropdown className={ReplacementCarrier ? 'is-replaced' : ''}>
            {renderToggle()}
            {renderDropDown()}
          </MDBDropdown>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default observer(ReplacementCarrierSelector)
