import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { observer } from 'mobx-react-lite'
import { MDBInput } from 'mdbreact'
import {
  checkValidity,
  getErrorMessage,
  getSuccessMessage,
  isDirty,
} from './../shared/validator.function'

/*
 *  DatePicker Placement Issues
 *  https://github.com/Hacker0x01/react-datepicker/issues/1246
 *  'auto', 'auto-left', 'auto-right', 'bottom', 'bottom-end', 'bottom-start', 'left', 'left-end', 'left-start', 'right', 'right-end', 'right-start', 'top', 'top-end', 'top-start'
 */
const DEFAULT_FORMAT = 'MM/dd/yyyy'
const CustomInput = React.forwardRef((props, ref) => (
  <MDBInput
    label={
      <>
        {props?.label ? props.label : ''}
        {props?.required ? <small>&nbsp;*</small> : ''}
      </>
    }
    ref={ref}
    {...props}
  />
))

const UIDatePickerInput = ({
  label,
  className,
  disabled,
  id,
  showYearDropdown,
  yearDropdownItemNumber,
  scrollableYearDropdown,
  name,
  value,
  dateFormat,
  minDate,
  maxDate,
  selected,
  onChange,
  required,
  onKeyPress,
  onValidityChange,
  rules,
  onFocus,
  showValidity,
  popperClassName,
  popperPlacement,
  todayButton,
  readOnly,
  autoComplete,
}) => {
  rules = rules && typeof rules === 'object' ? rules : {}
  if (required) rules.required = true

  const [uiState, setUiState] = useState({
    isDirty: false,
    isPristine: true,
    isTouched: false,
    validity: { isValid: false, errors: [] },
  })

  const _onFocus = onFocus
  onFocus = (evt) => {
    if (!uiState.isTouched)
      setUiState((prevState) => ({ ...prevState, isTouched: true }))
    ;(typeof _onFocus === 'function' ? _onFocus : (evt) => {})(evt)
  }

  const _onChange = onChange
  onChange = async (evt) => {
    evt = { target: { name: evt?.target?.name, value: evt?.target?.value } }

    setUiState((prevState) =>
      !uiState.isDirty && isDirty(value, evt.target.value)
        ? { ...prevState, isDirty: true, isPristine: false }
        : { ...prevState, isDirty: false }
    )
    ;(typeof _onChange === 'function' ? _onChange : (evt) => {})(evt)

    checkValidity(evt.target.value, rules).then((validity) => {
      setUiState((prevState) => ({ ...setUiState, validity }))
      ;(typeof onValidityChange === 'function'
        ? onValidityChange
        : (validity) => {})(validity)
    })
  }

  const _onDateKeyPress = (evt) => {
    try {
      if (
        [
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          '0',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '/',
        ].indexOf(evt.key) > -1
      )
        return true
      evt.preventDefault()
      return false
    } catch (ex) {}
  }

  if (!id)
    console.warn(
      'Use of UIDatePickerInput component with the `id` attribute can lead to strange behavior.'
    )

  onKeyPress = typeof onKeyPress === 'function' ? onKeyPress : _onDateKeyPress
  required = (required && parseInt(required) === 1) || !!required
  dateFormat = dateFormat ? dateFormat : DEFAULT_FORMAT

  if (label && required) {
    label = (
      <>
        {label}
        {<small>&nbsp;*</small>}
      </>
    )
  }

  useEffect(() => {
    checkValidity(value, rules).then((validity) => {
      setUiState((prevState) => ({ ...setUiState, validity }))
      ;(typeof onValidityChange === 'function'
        ? onValidityChange
        : (validity) => {})(validity)
    })
  }, [value])

  return (
    <div
      className={[
        'ui-input',
        'ui-date-picker-input',
        uiState.validity.isValid ? 'is-valid' : 'is-invalid',
        uiState.isDirty ? 'is-dirty' : '',
        uiState.isPristine ? 'is-pristine' : '',
        uiState.isTouched ? 'is-touched' : '',
        disabled ? 'is-disabled' : '',
        readOnly ? 'read-only' : '',
        showValidity ? 'show-validity' : '',
      ].join(' ')}
    >
      <DatePicker
        className={`${className || ''}`}
        id={`${id}`}
        name={name}
        value={value}
        dateFormat={dateFormat || 'MM/dd/yyyy'}
        minDate={minDate}
        maxDate={maxDate}
        selected={selected}
        todayButton={todayButton}
        disabled={disabled}
        readOnly={readOnly}
        onChange={(value) =>
          onChange(name ? { target: { name, value } } : value)
        }
        showYearDropdown={!!showYearDropdown}
        yearDropdownItemNumber={yearDropdownItemNumber}
        scrollableYearDropdown={!!scrollableYearDropdown}
        popperPlacement={popperPlacement}
        /*
         * This line throws an error in console, requesting use of `autoComplete` over `autocomplete`
         * However, the `autoComplete` counterpart does actually work as intended. The MDBInput component wrapper
         * does not translate the property to the final UI element.
         */
        customInput={
          <CustomInput
            label={label}
            onKeyPress={onKeyPress}
            autoComplete={autoComplete || 'on'}
          />
        }
      />
      <div className="ui-input-validation">
        <span className="success-msg">
          {showValidity ? getSuccessMessage() : ''}
        </span>
        <span className="error-msg">
          {showValidity ? getErrorMessage(uiState.validity.errors) : ''}
        </span>
      </div>
    </div>
  )
}

export default observer(UIDatePickerInput)
