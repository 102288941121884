import AccountRoutes from './../../pages/Account/routes'
import AdminReferralsPageRoutes from './../../pages/AdminReferrals/routes'
import AgentLedgerPageRoutes from './../../pages/AgentLedgers/routes'
import AgentContractingPageRoutes from './../../pages/AgentContractingPage/routes'
import BATeamPageRoutes from './../../pages/BATeams/routes'
import CreateUserRoutes from './../../pages/CreateUser/routes'
import MilestonesPageRoutes from './../../pages/MilestonesPage/routes'
import PrivacyPolicyPageRoutes from './../../pages/PrivacyPolicyPage/routes'
import PromotionCodesPageRoutes from './../../pages/PromotionCodesPage/routes'
import ReferralProgramPageRoutes from './../../pages/ReferralProgramPage/routes'
import ReportRoutes from './../../pages/Reports/routes'
import SalesTrackerPageRoutes from './../../pages/SubmitSales/routes'
import SupportPageRoutes from './../../pages/Support/routes'
import CarrierPrioritiesRoutes from './../../pages/CarrierPriorities/routes'
import TrainingQuizesRoutes from './../../pages/TrainingQuizesPage/routes'

class RouteRegistry {
  _routes = []

  get routes() {
    return this._routes || []
  }

  constructor(...routes) {
    this._routes = Array.isArray(routes) ? routes.flat() : []
  }
}

export default new RouteRegistry(
  AccountRoutes,
  AdminReferralsPageRoutes,
  AgentLedgerPageRoutes,
  AgentContractingPageRoutes,
  BATeamPageRoutes,
  CreateUserRoutes,
  MilestonesPageRoutes,
  PrivacyPolicyPageRoutes,
  PromotionCodesPageRoutes,
  ReferralProgramPageRoutes,
  ReportRoutes,
  SalesTrackerPageRoutes,
  SupportPageRoutes,
  CarrierPrioritiesRoutes,
  TrainingQuizesRoutes
)
