import React from 'react';
import {observer} from 'mobx-react-lite'
import AuthStore from './../Auth.store';
import {MDBIcon} from "mdbreact";
import AuthMessages from './../AuthMessages/AuthMessages.component';

import './ForgotPassword.scss';

const ForgotPassword = ({onSuccess}) =>
{
	const onForgot = async event => 
	{
		event.preventDefault();

		try { await AuthStore.handleSendResetLink(); }
		catch (ex) { }
	}

	return (
		<div id="ForgotPasswordComponent">
      <div className="block-shadow text-center">
        <div className="block-inner">
          <p className="h7 text-abbey text-bold">Reset Your Password</p>
          <div className="offset-top-40 offset-sm-top-60">
            <span className="icon icon-xl icon-gray-base material-icons-lock_open"></span>
          </div>
        </div>
        <form method="POST" className="rd-mailform form-modern form-darker offset-top-40"
          onSubmit={onForgot} noValidate>
          <div className="block-inner">
            <div className="form-group">
              <input  type="email"
			                name="email"
			                className="form-control"
			                placeholder="Login Email"
			                value={AuthStore.email}
			                onKeyPress={() => AuthStore.error = null}
			                onChange={e => AuthStore[e.target.name] = e.target.value} />
            </div>
            <div className="form-group offset-top-22">
              &nbsp;
            </div>
          </div>
          <AuthMessages />
          <div className="submit-wrapper">
            <button type="submit" className="btn btn-primary">
            	{AuthStore.loading ? 'Sending...' : 'Send Reset Email'}
            </button>
      		</div>
      	</form>
    	</div>
    	<span className="help-link"><a href="/login" onClick={e => {e.preventDefault(); AuthStore.goToState('login');}}><MDBIcon icon="left-arrow" />&nbsp;Go Back to Login</a></span>
    </div>
	)
}

export default observer(ForgotPassword);
