import {
  getWeek,
  format,
  getDay,
  add,
  parse,
  setWeek,
  nextMonday,
} from 'date-fns'

const isDateLeapYear = (reportDate) =>
  reportDate.getFullYear() % 100 === 0
    ? reportDate.getFullYear() % 400 === 0
    : reportDate.getFullYear() % 4 === 0

const isDateLeapDay = (reportDate) =>
  reportDate && isDateLeapYear(reportDate)
    ? !!(reportDate.getMonth() === 1 && reportDate.getDate() === 29)
    : false

const subtractDay = (reportDate) => {
  let rDate = new Date(reportDate)
  rDate.setDate(rDate.getDate() - 1)
  return rDate
}

const getFirstDayOfYear = (reportDate) =>
  parse('1', 'I', reportDate ? reportDate : new Date())

const doesYearStartOnMonday = (reportDate) =>
  getFirstDayOfYear(reportDate).getDay() === 1

const INITIAL_DATE = new Date()
INITIAL_DATE.setHours(0)
INITIAL_DATE.setMinutes(0)
INITIAL_DATE.setSeconds(0)

export default class ComparativeDateCalculator {
  currReportDate = INITIAL_DATE
  prevReportYear = new Date().getFullYear() - 1
  diffPrevYear = 1

  setCurrReportDate(reportDate) {
    let stateUpd = {},
      yearDiff = isNaN(this.diffPrevYear) ? 1 : this.diffPrevYear

    if (this.currReportDate && reportDate) {
      if (
        format(this.currReportDate, 'yyyy-MM-dd') !==
        format(reportDate, 'yyyy-MM-dd')
      ) {
        stateUpd.reportDate = reportDate
        stateUpd.prevYear = format(reportDate, 'yyyy') - yearDiff
      }
    } else if (reportDate) {
      stateUpd.reportDate = reportDate
      stateUpd.prevYear = format(reportDate, 'yyyy') - yearDiff
    }

    if (Object.keys(stateUpd).length) {
      stateUpd.reportDate.setHours(0)
      stateUpd.reportDate.setMinutes(0)
      stateUpd.reportDate.setSeconds(0)

      this.currReportDate = stateUpd.reportDate
      this.prevReportYear = stateUpd.prevYear

      return this.currReportDate && this.prevReportYear
    }

    return false
  }

  setPrevReportYear(prevYear) {
    const diff = this.getCurrYear() - prevYear
    if (diff > 0 && parseInt(diff) !== this.diffPrevYear) {
      this.diffPrevYear = parseInt(diff)
      this.prevReportYear = parseInt(prevYear)
      return true
    }

    return false
  }

  getCurrDate() {
    return this.currReportDate
  }

  getCurrYear() {
    return format(this.getCurrDate(), 'yyyy')
  }

  getCurrSearch() {
    return { report_date: format(this.getCurrDate(), 'yyyy-MM-dd') }
  }

  getPrevYear() {
    return this.prevReportYear
  }

  getPrevYearDiff() {
    return this.diffPrevYear
  }

  getPrevSearch() {
    let currDate = this.getCurrDate(),
      reportDate = isDateLeapDay(currDate) ? subtractDay(currDate) : currDate,
      dayIdx = getDay(reportDate),
      weekIdx =
        getWeek(reportDate, { weekStartsOn: 1 }) +
        (doesYearStartOnMonday(reportDate) ? 1 : 0),
      weekDate = setWeek(
        nextMonday(new Date(this.getPrevYear(), 0, 4)),
        weekIdx,
        { weekStartsOn: 1 }
      )
    while (getDay(weekDate) !== dayIdx) weekDate = add(weekDate, { days: 1 })

    reportDate = [this.getPrevYear(), format(reportDate, 'MM-dd')].join('-')

    return {
      report_date: reportDate,
      week: format(weekDate, 'yyyy-MM-dd'),
      month: reportDate,
      quarter: reportDate,
      year: reportDate,
    }
  }

  getStateObj() {
    return {
      reportDate: this.getCurrDate(),
      prevYear: this.getPrevYear(),
      diffPrevYear: this.getPrevYearDiff(),
    }
  }
}
