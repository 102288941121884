import React from 'react'
import DashboardLayout from '../../components/Admin/DashboardLayout'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import PageHeading from './../../components/shared/PageHeading.component'
import LeaderReportTable from './components/LeaderReportTable/LeaderReportTable.component'
// import { toast } from 'react-toastify'
import moment from 'moment'
// import { getGrowth } from './helpers'
import getQueryParam from './../../shared/utilities/getQueryParam.function'
// import { LeaderReportCardFactory } from './../../shared/factories'
// import UsertypeService from './../../shared/services/Usertype.service'
import LeaderCardStore from './store/LeaderReportCard.store'
import LeaderGoalStore from './store/LeaderGoalCard.store'
import AgencyContentStore from './../../shared/stores/agencyContent.store'
import './LeaderReportCard.page.scss'
import ContentHtml from '../../components/content/ContentHtml/ContentHtml.component'

const DEFAULT_VIEW_MODE = 'reports',
  LEADER_REPORTS = ['division', 'region', 'district', 'career', 'recruiter'],
  FormattedReports = {},
  StoredReports = {}

function updateQueryString(reportDate) {
  if (reportDate && window.history.pushState) {
    var newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?reportDate=${reportDate}`

    window.history.pushState({ path: newurl }, '', newurl)
  }
}

// function storeTableData(tableData) {
//   StoredReports[tableData.sourceType] = tableData
//   if (
//     StoredReports[tableData.sourceType] &&
//     typeof StoredReports[tableData.sourceType] === 'object' &&
//     Object.keys(StoredReports[tableData.sourceType])
//   )
//     return StoredReports[tableData.sourceType]
//   return false
// }

// function buildReportData(LRCs) {
//   let ReportResults = {}

//   ;(LRCs && Array.isArray(LRCs) ? LRCs : []).forEach((LeaderReportCards) => {
//     ;(LeaderReportCards || []).forEach((LGReport) => {
//       if (LGReport?.get('source_type')) {
//         if (!ReportResults.hasOwnProperty(LGReport.get('source_type')))
//           ReportResults[LGReport.get('source_type')] = {
//             sourceType: LGReport.get('source_type'),
//             tableData: [],
//           }

//         // Remove agents that have had their usertype changed.
//         // These agents may still exist in the result set, but will
//         // be hidden from the report view.
//         switch (LGReport.get('source_type')) {
//           case 'division':
//             if (
//               UsertypeService.isA(
//                 'divisional-leader',
//                 LGReport.get('usertype_id')
//               )
//             )
//               ReportResults[LGReport.get('source_type')].tableData.push(
//                 LGReport
//               )
//             break
//           case 'region':
//             if (
//               UsertypeService.isA(
//                 'regional-leader',
//                 LGReport.get('usertype_id')
//               )
//             )
//               ReportResults[LGReport.get('source_type')].tableData.push(
//                 LGReport
//               )
//             break
//           case 'district':
//             if (
//               UsertypeService.isA(
//                 'district-leader',
//                 LGReport.get('usertype_id')
//               )
//             )
//               ReportResults[LGReport.get('source_type')].tableData.push(
//                 LGReport
//               )
//             break
//           case 'recruiter':
//             if (
//               UsertypeService.isA(
//                 'recruiter-group',
//                 LGReport.get('usertype_id')
//               )
//             )
//               ReportResults[LGReport.get('source_type')].tableData.push(
//                 LGReport
//               )
//             break
//         }
//       }
//     })
//   })

//   if (ReportResults && typeof ReportResults === 'object')
//     ReportResults = Object.values(ReportResults)

//   return ReportResults
// }

// function isEmptyReportBySourceType(ReportResults, sourceType) {
//   return (
//     ReportResults.filter(
//       (tableData) =>
//         tableData?.sourceType === sourceType &&
//         Array.isArray(tableData?.tableData) &&
//         tableData.tableData.length > 0
//     ).length === 0
//   )
// }

// function isEmptyResultSet(LeaderReportCards) {
//   return (
//     LeaderReportCards.filter((LRC) => !!LRC?.get('source_type')).length === 0
//   )
// }

// function formatModelData(ReportModels) {
//   const entries = { mtd: {}, ytd: {} }
//   let sourceType

//   ReportModels.forEach((LGReport) => {
//     if (!sourceType) sourceType = LGReport.get('source_type')
//     let tKey = LGReport.get('timespan_ind')
//     if (!entries[tKey].hasOwnProperty(LGReport.get('source_id')))
//       entries[tKey][LGReport.get('source_id')] = []
//     entries[tKey][LGReport.get('source_id')].push(LGReport)
//   })

//   FormattedReports[sourceType] = Object.values(entries)
//     .map((rows) =>
//       Object.values(rows).map((LGReport) => {
//         LGReport = (Array.isArray(LGReport) ? LGReport : [LGReport]).shift()
//         const tKey = LGReport.get('timespan_ind'),
//           startDate = moment(LGReport.get('start_at').split(' ')[0]),
//           endDate = moment(LGReport.get('end_at').split(' ')[0])

//         return {
//           id: LGReport.id(),
//           source_name: LGReport.get('source_name'),
//           source_is_10x: parseInt(LGReport.get('source_is_10x')) === 1,
//           timespan: LGReport.get('timespan_ind'),
//           start_at: moment(LGReport.get('start_at').split('T')[0]).format(
//             'YYYY-MM-DD'
//           ),
//           end_at: moment(LGReport.get('end_at').split('T')[0]).format(
//             'YYYY-MM-DD'
//           ),
//           prev_start_at: moment(LGReport.get('start_at').split('T')[0])
//             .subtract(1, 'years')
//             .format('YYYY-MM-DD'),
//           prev_end_at: moment(LGReport.get('end_at').split('T')[0])
//             .subtract(1, 'years')
//             .format('YYYY-MM-DD'),
//           report_period: (
//             <>
//               {tKey === 'mtd' ? (
//                 <>MTD&nbsp;{endDate.format('MMM YYYY')}</>
//               ) : (
//                 <>YTD&nbsp;{endDate.format('YYYY')}</>
//               )}
//               <br />
//               <small>
//                 {[
//                   startDate.format('MMM DD, YY'),
//                   endDate.format('MMM DD, YY'),
//                 ].join(' - ')}
//               </small>
//             </>
//           ),
//           curr_points: LGReport.get('curr_points'),
//           prev_points: LGReport.get('prev_points'),
//           growth_points: LGReport.get('growth_points'),
//           curr_enrolled: LGReport.get('curr_enrolled'),
//           prev_enrolled: LGReport.get('prev_enrolled'),
//           growth_enrolled: LGReport.get('growth_enrolled'),
//           curr_completed: LGReport.get('curr_completed'),
//           prev_completed: LGReport.get('prev_completed'),
//           growth_completed: LGReport.get('growth_completed'),
//           curr_converted: LGReport.get('curr_converted'),
//           prev_converted: LGReport.get('prev_converted'),
//           growth_converted: LGReport.get('growth_converted'),
//           enrolled_converted: getGrowth(
//             LGReport.get('curr_enrolled'),
//             LGReport.get('curr_completed')
//           ),
//           curr_conversion: LGReport.get('curr_conversion'),
//           prev_conversion: LGReport.get('prev_conversion'),
//           growth_conversion: LGReport.get('growth_conversion'),
//           completed_converted: getGrowth(
//             LGReport.get('curr_completed'),
//             LGReport.get('curr_converted')
//           ),
//           curr_pending: LGReport.get('curr_pending'),
//           prev_pending: LGReport.get('prev_pending'),
//           growth_pending: LGReport.get('growth_pending'),
//           curr_signature:
//             LGReport.get('curr_active') - LGReport.get('curr_pending'),
//           prev_signature:
//             LGReport.get('prev_active') - LGReport.get('prev_pending'),
//           growth_signature:
//             LGReport.get('prev_active') - LGReport.get('prev_pending') > 0
//               ? ((LGReport.get('curr_active') -
//                   LGReport.get('curr_pending') -
//                   (LGReport.get('prev_active') -
//                     LGReport.get('prev_pending'))) /
//                   (LGReport.get('prev_active') -
//                     LGReport.get('prev_pending'))) *
//                 100
//               : 0,
//           curr_active: LGReport.get('curr_active'),
//           prev_active: LGReport.get('prev_active'),
//           growth_active: LGReport.get('growth_active'),
//         }
//       })
//     )
//     .flat()

//   return true
// }

// function formatTableData(tableData) {
//   const { sourceType } = tableData,
//     entries = { mtd: {}, ytd: {} }
//   tableData = tableData.tableData

//   tableData.forEach((LGReport) => {
//     let tKey = LGReport.get('timespan_ind')
//     if (!entries[tKey].hasOwnProperty(LGReport.get('source_id')))
//       entries[tKey][LGReport.get('source_id')] = []
//     entries[tKey][LGReport.get('source_id')].push(LGReport)
//   })

//   FormattedReports[sourceType] = Object.values(entries)
//     .map((rows) =>
//       Object.values(rows).map((LGReport) => {
//         LGReport = (Array.isArray(LGReport) ? LGReport : [LGReport]).shift()
//         const tKey = LGReport.get('timespan_ind'),
//           startDate = moment(LGReport.get('start_at').split(' ')[0]),
//           endDate = moment(LGReport.get('end_at').split(' ')[0])

//         return {
//           id: LGReport.id(),
//           source_name: LGReport.get('source_name'),
//           source_is_10x: parseInt(LGReport.get('source_is_10x')) === 1,
//           timespan: LGReport.get('timespan_ind'),
//           start_at: moment(LGReport.get('start_at').split('T')[0]).format(
//             'YYYY-MM-DD'
//           ),
//           end_at: moment(LGReport.get('end_at').split('T')[0]).format(
//             'YYYY-MM-DD'
//           ),
//           prev_start_at: moment(LGReport.get('start_at').split('T')[0])
//             .subtract(1, 'years')
//             .format('YYYY-MM-DD'),
//           prev_end_at: moment(LGReport.get('end_at').split('T')[0])
//             .subtract(1, 'years')
//             .format('YYYY-MM-DD'),
//           report_period: (
//             <>
//               {tKey === 'mtd' ? (
//                 <>MTD&nbsp;{endDate.format('MMM YYYY')}</>
//               ) : (
//                 <>YTD&nbsp;{endDate.format('YYYY')}</>
//               )}
//               <br />
//               <small>
//                 {[
//                   startDate.format('MMM DD, YY'),
//                   endDate.format('MMM DD, YY'),
//                 ].join(' - ')}
//               </small>
//             </>
//           ),
//           curr_points: LGReport.get('curr_points'),
//           prev_points: LGReport.get('prev_points'),
//           growth_points: LGReport.get('growth_points'),
//           curr_enrolled: LGReport.get('curr_enrolled'),
//           prev_enrolled: LGReport.get('prev_enrolled'),
//           growth_enrolled: LGReport.get('growth_enrolled'),
//           curr_completed: LGReport.get('curr_completed'),
//           prev_completed: LGReport.get('prev_completed'),
//           growth_completed: LGReport.get('growth_completed'),
//           curr_converted: LGReport.get('curr_converted'),
//           prev_converted: LGReport.get('prev_converted'),
//           growth_converted: LGReport.get('growth_converted'),
//           enrolled_converted: getGrowth(
//             LGReport.get('curr_enrolled'),
//             LGReport.get('curr_completed')
//           ),
//           curr_conversion: LGReport.get('curr_conversion'),
//           prev_conversion: LGReport.get('prev_conversion'),
//           growth_conversion: LGReport.get('growth_conversion'),
//           completed_converted: getGrowth(
//             LGReport.get('curr_completed'),
//             LGReport.get('curr_converted')
//           ),
//           curr_pending: LGReport.get('curr_pending'),
//           prev_pending: LGReport.get('prev_pending'),
//           growth_pending: LGReport.get('growth_pending'),
//           curr_signature:
//             LGReport.get('curr_active') - LGReport.get('curr_pending'),
//           prev_signature:
//             LGReport.get('prev_active') - LGReport.get('prev_pending'),
//           growth_signature:
//             LGReport.get('prev_active') - LGReport.get('prev_pending') > 0
//               ? ((LGReport.get('curr_active') -
//                   LGReport.get('curr_pending') -
//                   (LGReport.get('prev_active') -
//                     LGReport.get('prev_pending'))) /
//                   (LGReport.get('prev_active') -
//                     LGReport.get('prev_pending'))) *
//                 100
//               : 0,
//           curr_active: LGReport.get('curr_active'),
//           prev_active: LGReport.get('prev_active'),
//           growth_active: LGReport.get('growth_active'),
//         }
//       })
//     )
//     .flat()

//   return true
// }

class LeaderReportCardPage extends React.Component {
  getQueryParams = (params) => {
    const reportDate = /^\d{4}-\d{2}-\d{2}$/.test(
      `${getQueryParam('reportDate', this.props.location.search)}`.trim()
    )
      ? `${getQueryParam('reportDate', this.props.location.search)}`.trim()
      : moment().format('YYYY-MM-DD')

    const sourceType = /^(division|region|district|career|recruiter)$/i.test(
      `${getQueryParam('sourceType', this.props.location.search)}`.trim()
    )
      ? `${getQueryParam('sourceType', this.props.location.search)}`.trim()
      : null

    const goalDate = /^\d{4}-\d{2}-\d{2}$/.test(
      `${getQueryParam('goalDate', this.props.location.search)}`.trim()
    )
      ? `${getQueryParam('goalDate', this.props.location.search)}`.trim()
      : moment().format('YYYY-MM-DD')

    return { reportDate, sourceType, goalDate }
  }

  // togglePast = () => {
  //   if (StoredReports.hasOwnProperty(this.state.sourceType)) {
  //     this.setState(
  //       (prevState) => ({ ...prevState, showPrev: !prevState?.showPrev }),
  //       () => {
  //         if (formatTableData(StoredReports[this.state.sourceType]))
  //           this.setState((prevState) => ({
  //             ...prevState,
  //             tableData: {
  //               ...this.state.tableData,
  //               [this.state.sourceType]:
  //                 FormattedReports[this.state.sourceType],
  //             },
  //           }))
  //       }
  //     )
  //   }
  // }

  hasViewInit(viewMode) {
    return this.state.viewMode && this.state?.hasInit?.[viewMode]
  }

  async initView(viewMode) {
    let agencyContent = {},
      agencyContentBySlug = null

    if (viewMode === 'goals') {
      agencyContentBySlug = (
        await Promise.all([
          AgencyContentStore.fetchByContentSlugs({
            contentSlugs: [
              'leader-goals-career-points-notes',
              'leader-goals-district-points-notes',
              'leader-goals-region-points-notes',
              'leader-goals-division-points-notes',
              'leader-goals-career-enrolled-notes',
              'leader-goals-district-enrolled-notes',
              'leader-goals-region-enrolled-notes',
              'leader-goals-division-enrolled-notes',
              'leader-goals-career-completed-notes',
              'leader-goals-district-completed-notes',
              'leader-goals-region-completed-notes',
              'leader-goals-division-completed-notes',
              'leader-goals-career-converted-notes',
              'leader-goals-district-converted-notes',
              'leader-goals-region-converted-notes',
              'leader-goals-division-converted-notes',
              'leader-goals-career-slot-investment-notes',
              'leader-goals-district-slot-investment-notes',
              'leader-goals-region-slot-investment-notes',
              'leader-goals-division-slot-investment-notes',
              'leader-goals-career-usabg-investment-notes',
              'leader-goals-district-usabg-investment-notes',
              'leader-goals-region-usabg-investment-notes',
              'leader-goals-division-usabg-investment-notes',
              'leader-goals-career-sig-agents-notes',
              'leader-goals-district-sig-agents-notes',
              'leader-goals-region-sig-agents-notes',
              'leader-goals-division-sig-agents-notes',
              'leader-goals-career-points-notes',
              'leader-goals-district-points-notes',
              'leader-goals-region-points-notes',
              'leader-goals-division-points-notes',
            ],
          }),
          LeaderGoalStore.initStore(this.getQueryParams()),
        ])
      ).shift()
    }

    if (viewMode === 'reports') {
      await LeaderCardStore.initStore(this.getQueryParams())
    }

    if (agencyContentBySlug) {
      ;['career', 'district', 'region', 'division'].forEach((sourceType) => {
        Object.keys(agencyContentBySlug)
          .filter((contentSlug) => contentSlug.includes(sourceType))
          .forEach((contentSlug) => {
            if (!agencyContent.hasOwnProperty(sourceType))
              agencyContent[sourceType] = {}
            agencyContent[sourceType][
              contentSlug.replace(`${sourceType}-`, '')
            ] = agencyContentBySlug[contentSlug]
          })
      })
    }

    if (
      (agencyContent =
        !agencyContent || !Object.keys(agencyContent).length
          ? null
          : agencyContent)
    )
      LeaderGoalStore.agencyContent = agencyContent

    this.setState((prevState) => ({
      ...prevState,
      viewMode,
      hasInit: { ...this.state.hasInit, [viewMode]: true },
    }))
  }

  async changeView(viewMode) {
    this.setState((prevState) => ({ ...prevState, viewMode }))
  }

  setViewMode(viewMode) {
    if (!this.hasViewInit(viewMode)) this.initView(viewMode)
    else this.changeView(viewMode)
  }

  init() {
    if (!this.state.viewMode) this.setViewMode(DEFAULT_VIEW_MODE)
  }

  constructor(props) {
    super(props)
    this.state = {
      showPrev: false,
      viewMode: null,
      hasInit: {
        goals: false,
        reports: false,
      },
    }
  }

  componentDidMount() {
    this.init()
  }

  render() {
    return (
      <DashboardLayout>
        <main id="LeaderReportCardPage" className="mainSection pb-5">
          <MDBContainer fluid className="mt-4">
            <PageHeading
              label="Leader Report Card"
              url={this.props?.match?.url}
              from={this.props?.location?.state?.from}
              goTo={(url) =>
                this.props.history.push(url, { from: '/reports/leaders' })
              }
            />
            <MDBRow>
              <MDBCol>
                <ContentHtml contentSlug={'lrc-corporate-goals'} />
              </MDBCol>
              <MDBCol>
                <ContentHtml contentSlug={'lrc-team-goals'} />
              </MDBCol>
              <MDBCol size="12">
                <LeaderReportTable
                  onDateChange={(reportDate) => updateQueryString(reportDate)}
                  viewMode={this.state.viewMode}
                  onViewModeChange={(viewMode) => this.setViewMode(viewMode)}
                  goTo={(goTo) => this.props.history.push(goTo)}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </main>
      </DashboardLayout>
    )
  }
}

export default LeaderReportCardPage
