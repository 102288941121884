import BaseModelInstance from './base.model'
import { Av as ModelDefinition } from './model-definitions'
import { email as EmailPattern } from './../patterns/email.pattern'

class Av extends BaseModelInstance {
  afterSet = (key, value) => {}

  constructor(attribs) {
    super(ModelDefinition, attribs)
  }

  getPolicyErrors = () => {
    let errors = {}

    const msgs = {
      av_client: 'Client Name Required',
      av_client_email: 'Client Email',
      av_state: 'State Required',
      coverage_id: 'Coverage Required',
      lives: 'Lives Required',
      carrier_id: 'Carrier Required',
      av_product: 'Product Required',
      av_term_length: 'Term Length Required',
      av_appid: 'Policy Number Required',
      av_premium: 'Monthly Premium Required',
      leadsource_id: 'Lead Source Required',
      av_date: 'Date Required',
    }

    Object.keys(msgs).forEach((key) => {
      switch (key) {
        case 'av_client_email':
          if (this.get(key) && !EmailPattern.test(this.get(key)))
            errors[key] = msgs[key] + ' Invalid'
          break

        case 'av_date':
          if (
            !this.get(key) ||
            typeof this.get(key) !== 'object' ||
            !(this.get(key) instanceof Date)
          )
            errors[key] = msgs[key]
          break

        // case 'lives':
        //   if (this._shouldTrackLives()) {
        //     if (!this.state.lives) errors[key] = msgs[key]
        //   }
        //   break

        case 'av_client':
        case 'av_state':
        case 'av_appid':
        case 'av_premium':
          if (!this.get(key) || !`${this.get(key)}`.trim())
            errors[key] = msgs[key]
          break
        case 'av_product':
          if (!this.get(key)) errors[key] = msgs[key]
          break
        case 'coverage_id':
        case 'carrier_id':
        case 'av_term_length':
        case 'leadsource_id':
          if (
            this.get(key) === null ||
            typeof this.get(key) === 'undefined' ||
            isNaN(parseInt(this.get(key)))
          )
            errors[key] = msgs[key]
          break

        default:
          break
      }
    })

    // No errors exist ----------------------------
    if (Object.keys(errors).length === 0) {
      // this._errors = {}
      // if (this.state.errorsExist) this.setState({ errorsExist: false })
      return true
    }

    // Errors exist -------------------------------
    // this._errors = errors
    // this.setState({ errorsExist: true })
    // toast.error(
    //   'Oh no!  Errors exist.  Please check your inputs for details.',
    //   { position: toast.POSITION.TOP_RIGHT }
    // )
    // return false
    return errors
  }
}

export default Av
