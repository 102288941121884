import React from 'react'
import { getGrowth } from './../helpers'
import { makeAutoObservable } from 'mobx'
import { LeaderReportCardFactory } from './../../../shared/factories'
import moment from 'moment'
import UsertypeService from './../../../shared/services/Usertype.service'

const LEADER_REPORTS = ['division', 'region', 'district', 'recruiter'],
  FormattedReports = {},
  StoredReports = {}

function formatModelData(ReportModels) {
  const entries = { mtd: {}, ytd: {} }
  let sourceType

  ReportModels.forEach((LGReport) => {
    if (!sourceType) sourceType = LGReport.get('source_type')
    let tKey = LGReport.get('timespan_ind')
    if (!entries[tKey].hasOwnProperty(LGReport.get('source_id')))
      entries[tKey][LGReport.get('source_id')] = []
    entries[tKey][LGReport.get('source_id')].push(LGReport)
  })

  return Object.values(entries)
    .map((rows) =>
      Object.values(rows).map((LGReport) => {
        LGReport = (Array.isArray(LGReport) ? LGReport : [LGReport]).shift()
        const tKey = LGReport.get('timespan_ind'),
          startDate = moment(LGReport.get('start_at').split(' ')[0]),
          endDate = moment(LGReport.get('end_at').split(' ')[0])

        return {
          id: LGReport.id(),
          source_name: LGReport.get('source_name'),
          source_is_10x: parseInt(LGReport.get('source_is_10x')) === 1,
          timespan: LGReport.get('timespan_ind'),
          start_at: moment(LGReport.get('start_at').split('T')[0]).format(
            'YYYY-MM-DD'
          ),
          end_at: moment(LGReport.get('end_at').split('T')[0]).format(
            'YYYY-MM-DD'
          ),
          prev_start_at: moment(LGReport.get('start_at').split('T')[0])
            .subtract(1, 'years')
            .format('YYYY-MM-DD'),
          prev_end_at: moment(LGReport.get('end_at').split('T')[0])
            .subtract(1, 'years')
            .format('YYYY-MM-DD'),
          report_period: (
            <>
              {tKey === 'mtd' ? (
                <>MTD&nbsp;{endDate.format('MMM YYYY')}</>
              ) : (
                <>YTD&nbsp;{endDate.format('YYYY')}</>
              )}
              <br />
              <small>
                {[
                  startDate.format('MMM DD, YY'),
                  endDate.format('MMM DD, YY'),
                ].join(' - ')}
              </small>
            </>
          ),
          curr_points: LGReport.get('curr_points'),
          prev_points: LGReport.get('prev_points'),
          growth_points: LGReport.get('growth_points'),
          curr_enrolled: LGReport.get('curr_enrolled'),
          prev_enrolled: LGReport.get('prev_enrolled'),
          growth_enrolled: LGReport.get('growth_enrolled'),
          curr_completed: LGReport.get('curr_completed'),
          prev_completed: LGReport.get('prev_completed'),
          growth_completed: LGReport.get('growth_completed'),
          curr_converted: LGReport.get('curr_converted'),
          prev_converted: LGReport.get('prev_converted'),
          growth_converted: LGReport.get('growth_converted'),
          enrolled_converted: getGrowth(
            LGReport.get('curr_enrolled'),
            LGReport.get('curr_completed')
          ),
          curr_conversion: LGReport.get('curr_conversion'),
          prev_conversion: LGReport.get('prev_conversion'),
          growth_conversion: LGReport.get('growth_conversion'),
          completed_converted: getGrowth(
            LGReport.get('curr_completed'),
            LGReport.get('curr_converted')
          ),
          curr_pending: LGReport.get('curr_pending'),
          prev_pending: LGReport.get('prev_pending'),
          growth_pending: LGReport.get('growth_pending'),
          curr_signature:
            LGReport.get('curr_active') - LGReport.get('curr_pending'),
          prev_signature:
            LGReport.get('prev_active') - LGReport.get('prev_pending'),
          growth_signature:
            LGReport.get('prev_active') - LGReport.get('prev_pending') > 0
              ? ((LGReport.get('curr_active') -
                  LGReport.get('curr_pending') -
                  (LGReport.get('prev_active') -
                    LGReport.get('prev_pending'))) /
                  (LGReport.get('prev_active') -
                    LGReport.get('prev_pending'))) *
                100
              : 0,
          curr_active: LGReport.get('curr_active'),
          prev_active: LGReport.get('prev_active'),
          growth_active: LGReport.get('growth_active'),
          curr_writing: LGReport.get('curr_writing'),
          prev_writing: LGReport.get('prev_writing'),
          growth_writing: LGReport.get('growth_writing'),
        }
      })
    )
    .flat()

  return true
}

function isEmptyResultSet(LeaderReportCards) {
  return (
    LeaderReportCards.filter((LRC) => !!LRC?.get('source_type')).length === 0
  )
}

class LeaderReportStore {
  constructor() {
    makeAutoObservable(this)
  }

  isLoading = false
  sourceType = 'division'
  reportDate = null
  showPrev = false
  sourceFilter = {
    is_10x: false,
  }

  reportData = ''
  viewMode

  tableData = {
    division: null,
    region: null,
    district: null,
    recruiter: null,
  }

  rawReportData = {
    division: false,
    region: false,
    district: false,
    recruiter: false,
  }

  isEmpty = {
    division: false,
    region: false,
    district: false,
    recruiter: false,
  }

  isEmpty = () => this.reportData === 'EMPTY'

  onTogglePrev = () => (this.showPrev = this.showPrev !== true)

  setSourceType = async (sourceType) => {
    if (sourceType !== this.sourceType && sourceType) {
      this.sourceType = sourceType
      this.updateReport()
    }
  }

  setReportDate = async (reportDate) => {
    if (this.reportDate !== reportDate) {
      this.reportDate =
        reportDate && typeof reportDate === 'object'
          ? moment(reportDate).format('YYYY-MM-DD')
          : reportDate
      this.updateReport()
    }
  }

  setViewData(reportData) {
    this.reportData = reportData || ''
  }

  updateReport = async () => {
    const ReportModels = await this.fetchBySourceType()

    this.setViewData(
      ReportModels &&
        this.buildReportViewFromModels(ReportModels, this.sourceType)
    )
  }

  buildReportViewFromModels(ReportModels, sourceType) {
    sourceType = sourceType || this.sourceType
    if (ReportModels && Array.isArray(ReportModels)) {
      this.reportData = ''
      if (isEmptyResultSet(ReportModels)) {
        return 'EMPTY'
      } else {
        FormattedReports[sourceType] = formatModelData(ReportModels)
        return FormattedReports[sourceType]
      }
    }
  }

  async initStore(reportParams) {
    const { reportDate, sourceType } = reportParams || {}

    this.sourceType = sourceType || this.sourceType
    this.reportDate = reportDate || this.reportDate

    const ReportModels = await this.fetchBySourceType()

    this.setViewData(
      ReportModels &&
        this.buildReportViewFromModels(ReportModels, this.sourceType)
    )
  }

  async fetchBySourceType() {
    if (!this.isLoading) this.isLoading = true

    let ReportModels = null
    try {
      ReportModels = await LeaderReportCardFactory.report({
        source_type: this.sourceType,
        start_at: this.reportDate,
        end_at: this.reportDate,
      })
    } catch (ex) {
      console.error(`${ex}`)
    }

    this.rawReportData[this.sourceType] = ReportModels
    this.tableData[this.sourceType] = null
    this.isLoading = false

    return ReportModels
  }
}

export default new LeaderReportStore()
